import validate from 'validate.js';
import * as yup from 'yup';

import { getAuth, getIdToken, onAuthStateChanged } from 'firebase/auth';

export const validateLinkedinURL = async (url) => {
  let schema = yup
    .string()
    .url('Please enter a valid URL')
    .matches(
      /http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/,
      'Please enter a valid linkedin URL',
    );
  // console.log()
  return await schema.validate(url);
};

export const validateEmail = (email) => {
  return validate({ email: email }, { email: { email: true } }) === undefined;
};

export const validateURL = async (url) => {
  let schema = yup.string().url('Please enter a valid url');

  return await schema.validate(url);
};

export const getHighestQualityVideoURL = (files) => {};

export const getPortraitThumbnail = (thumbnails) => {};

export const getHighestQualityThumbnail = (thumbnails) => {
  if (thumbnails && Array.isArray(thumbnails) && thumbnails.length > 0) {
    const maxQualityThumbnail = thumbnails?.reduce((acc, curr) => {
      if (typeof acc['width'] === 'undefined') {
        acc = curr;
      } else if (acc['width'] < curr['width']) {
        acc = curr;
      }
      return acc;
    }, {});
    if (maxQualityThumbnail) {
      return maxQualityThumbnail.link;
    } else {
      return thumbnails[1].link;
    }
  } else {
    return '';
  }
};

export const getFormattedDate = (epochTime) => {};

export const tooltipDelay = 500;

export const checkTimeDiff = (videoUploadedTime) => {};

export const getMediaDeviceInfo = (navigator) => {
  if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
    return 'enumeratedDevices not supported';
  } else {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        devices.forEach((device) => {
          console.log(
            `${device.kind}: ${device.label} id = ${device.deviceId}`,
          );
        });
      })
      .catch((err) => {
        console.log(`${err.name}: ${err.message}`);
      });
  }
};

export const getUserToken = async () => {
  return new Promise((resolve, reject) => {
    const unsub = onAuthStateChanged(getAuth(), async (user) => {
      if (user) {
        const token = await getIdToken(user);
        resolve(token);
      } else {
        console.log('User not logged in');
        resolve(null);
      }
      unsub();
    });
  });
};

export const uuidRegex = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/gi;

export const regex1 = /^(\/teams\/)([a-z\-0-9]+)(\/sales-call-analysis)$/gi;
export const regex2 = /^(\/teams\/)([a-z\-0-9]+)(\/conversation-ai)$/gi;
export const regex3 = /^(\/teams\/)([a-z\-0-9]+)(\/team-settings)$/gi;

export const capitalizeText = (text) => {
  return text
    ?.trim()
    ?.split(' ')
    ?.filter((w) => w.trim())
    ?.map((w) => `${w.split('')[0].toUpperCase()}${w.slice(1)}`)
    .join(' ');
};

export const formatSeconds = (seconds) => {
  const min = Math.floor(seconds / 60);
  const sec = seconds % 60;
  const formattedMinutes = min > 9 ? `${min}` : `0${min}`;
  const formattedSeconds = sec > 9 ? `${sec}` : `0${sec}`;
  return `${formattedMinutes}:${formattedSeconds}`;
};

export const getFileType = async (url) => {
  // (async () => {
  const response = await fetch(url, {
    method: 'GET',
  });
  console.log(response.headers.get('Content-Type'));
  return response.headers.get('Content-Type');
  // })();
};

export const checkIfNumberExistsBetweenRange = (num, min, max) => {
  if (num >= min && num <= max) {
    return true;
  } else {
    return false;
  }
};

export const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  const sortedA = a.sort();
  const sortedB = b.sort();
  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < sortedA.length; ++i) {
    if (sortedA[i] !== sortedB[i]) return false;
  }
  return true;
};

export const getMatches = (string, needle) => {
  var regex = new RegExp(needle.toLowerCase(), 'g'),
    result = [];

  string = string.toLowerCase();
  let match = regex.exec(string);
  while (match != null) {
    result.push(match.index);
    match = regex.exec(string);
  }
  return result;
};

export const isPublicKeyMissingError = ({ vapiError }) => {
  return (
    !!vapiError &&
    vapiError.error.statusCode === 403 &&
    vapiError.error.error === 'Forbidden'
  );
};

export const convertToTimerFormat = (seconds) => {
  return `${
    Math.floor(seconds / 60) > 9
      ? Math.floor(seconds / 60)
      : '0' + Math.floor(seconds / 60)
  }:${
    Math.floor(seconds % 60) > 9
      ? Math.floor(seconds % 60)
      : '0' + Math.floor(seconds % 60)
  }`;
};

export const defaultPlaybookFieldsMapping = {
  productKeyFeatures: {
    order: 3,
    text: 'Product Key Features',
    subText: '(Highlight USPs)',
    type: 'textarea',
  },
  customerQualificationQuestions: {
    order: 5,
    text:
      'Customer Qualification Questions: (1 or more questions that you use to qualify your customers)',
    subText: '',
    type: 'textarea',
  },
  companyName: {
    order: 1,
    text: 'About Your Company',
    subText: '',
    type: 'textarea',
  },
  customerQuestions: {
    order: 6,
    text: 'Typical customer objections and their answers',
    subText: '',
    childrens: [
      {
        question: { text: 'Objection', subText: '' },
        answer: { text: 'Answer', subText: '' },
      },
    ],
    type: 'listofqns',
  },
  idealCustomerProfile: {
    order: 4,
    text: 'Describe your Ideal Customer Profile',
    type: 'textarea',
  },
  competingProducts: {
    order: 9,
    text:
      'Competing Products and Companies[comma separated product or company names]',
    type: 'textarea',
  },
  awardsRatingAndReviews: {
    order: 10,
    text: 'Highlights of Awards, Ratings, and Customer Reviews',
    type: 'textarea',
  },
  challengesForCustomer: {
    order: 7,
    text: 'Top customer challenges and recommended responses',
    subText: '',
    childrens: [
      {
        question: { text: 'Challenge', subText: '' },
        answer: { text: 'Recommended Response', subText: '' },
      },
    ],
    type: 'listofqns',
  },
  expectedCallToAction: {
    order: 8,
    text:
      'Expected Call to Action[e.g. Close the deal, Book a demo, schedule next meeting, etc]',
    type: 'textarea',
  },
  productDescription: {
    order: 2,
    text: 'Product Description',
    subText:
      '(Please describe your product in detail. Please also add URLs to specific webpages and shared documents if any.)',
    type: 'textarea',
  },
};

export const initializePendo = (user) => {
  if (window.pendo) {
    window.pendo.initialize({
      apiKey: 'c7a6fa93-024c-4c43-655c-3a993c8d0dc1',
      visitor: {
        id: user.id,
        email: user.email,
        name: user.fullName,
        orgRole: user?.orgRoles?.[0],
        teamRole: user?.teamRoles?.[0],
      },
      //  account: {id:user.id,  }
    });
  }
};
