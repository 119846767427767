import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useTheme,
  Modal,
  Divider,
  Paper,
  Avatar,
  Tabs,
  Tab,
  useMediaQuery,
  TextField,
  InputAdornment,
  Alert,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  FormControl,
  FormGroup,
  Checkbox,
  Select,
  MenuItem,
  Link,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Drawer,
  FormLabel,
  Chip,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Popover,
  Menu,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { PublicFluid } from 'layouts';
import React, { useEffect, useRef, useState } from 'react';
import Container from 'components/Container';
import { useDispatch, useSelector } from 'react-redux';
import getAllCompetitions from 'redux/actions/Common/getAllCompetitions';
import { CompetitionCard } from 'components';
import {
  Add,
  Article,
  Assignment,
  Check,
  ChevronRight,
  Close,
  Delete,
  DeleteOutline,
  Edit,
  Grading,
  HelpOutline,
  MoreVert,
  Remove,
  SupportAgent,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import saveToCalendar from 'redux/actions/Candidate/saveToCalendar';
import fileDownload from 'js-file-download';
import axios from 'utils/axios';
import showAlert from 'redux/actions/Common/showAlert';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  EmailAuthProvider,
  onAuthStateChanged,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth';
import { auth } from '../../../firebase';
import { detect } from 'detect-browser';
import trackEvent from 'redux/actions/Common/trackEvent';
import logError from 'redux/actions/Common/logError';
import getCandidateDetails from 'redux/actions/Candidate/getCandidateDetails';
import uploadResume from 'redux/actions/Candidate/uploadResume';
import updateProfile from 'redux/actions/Candidate/updateProfile';
import deleteResume from 'redux/actions/Candidate/deleteResume';
import getUserDetails from 'redux/actions/Common/getUserDetails';
import {
  arraysEqual,
  capitalizeText,
  defaultPlaybookFieldsMapping,
} from 'utils';
import getPlaybookHistory from 'redux/actions/Common/getPlaybookHistory';
import RichTextEditor from 'components/RichTextEditor';
import updatePlaybook from 'redux/actions/Common/updatePlaybook';
import getBots from 'redux/actions/Common/getBots';
import requestBot from 'redux/actions/Common/requestBot';
import updateGlobalOrganizationConfig from 'redux/actions/Common/updateGlobalOrganizationConfig';
import getBotRequests from 'redux/actions/Common/getBotRequests';
import updateUserDetails from 'redux/actions/Common/updateUserDetails';
import getDefaultPrompt from 'redux/actions/Common/getDefaultPrompt';
import { useHistory } from 'react-router-dom';
import getOrganizationConfigs from 'redux/actions/Common/getOrganizationConfigs';
import integrateHubspot from 'redux/actions/Common/integrateHubspot';
import getIntegrationKey from 'redux/actions/Common/getIntegrationKey';
import AutoCompleteMUI from 'components/@2024/AutoCompleteMUI';
import getGoogleOAuthUrl from 'redux/actions/Common/getGoogleOAuthUrl';
import updatePlaybookHistory from 'redux/actions/Common/updatePlaybookHistory';
import PlaybookGeneration from '../PlaybookGeneration';
import updatePresetQuestions from 'redux/actions/Common/updatePresetQuestions';
import SkillTagInput from 'components/@2024/SkillTagInput';
import parse from 'html-react-parser';
import getSyncRequestStatus from 'redux/actions/Common/getSyncRequestStatus';
import syncHubspot from 'redux/actions/Common/syncHubspot';
import { driver } from 'driver.js';
import PropTypes from 'prop-types';
import manuallyTriggerBotToJoin from 'redux/actions/Common/manuallyTriggerBotToJoin';
import { v4 as uuidv4 } from 'uuid';
import getPlaybooks from 'redux/actions/Common/getPlaybooks';
import updateTeam from 'redux/actions/Common/updateTeam';
import createPlaybook from 'redux/actions/Common/createPlaybook';
import packageJSON from '../../../../package.json';
import uploadPicture from 'redux/actions/Common/uploadPicture';
import uploadProfilePicture from 'redux/actions/Auth/uploadProfilePicture';
import deletePlaybook from 'redux/actions/Common/deletePlaybook';
import getTeams from 'redux/actions/Common/getTeams';
import getAllHubspotProperties from 'redux/actions/Common/getAllHubspotProperties';
import getAllHubspotCompanies from 'redux/actions/Common/getAllHubspotCompanies';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import getZohoCrmOAuthUrl from 'redux/actions/Common/getZohoCrmOAuthUrl';

const validationSchema = yup.object({
  currentPassword: yup
    .string()
    .trim()
    .required('Please enter your current password'),
  newPassword: yup
    .string()
    .trim()
    .required('Please enter new password')
    .min(5, 'New password should be atleast 5 character long'),
});

const validationSchemaPlaybook = yup.object().nullable();

// const driverObj = driver({
//   showProgress: true,
//   steps: [
//     {
//       element: '.playbook-page',
//       popover: { title: 'Title', description: 'Description Changed' },
//     },
//     {
//       element: '.top-nav',
//       popover: { title: 'Title', description: 'Description' },
//     },
//     {
//       element: '.sidebar',
//       popover: { title: 'Title', description: 'Description' },
//     },
//     {
//       element: '.footer',
//       popover: { title: 'Title', description: 'Description' },
//     },
//   ],
// });

const AccountSettings = ({
  defaultTab,
  onBoardingStep,
  setCompletedStep,
  page,
  currentTeam,
  setCurrentTeam,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: false,
  });
  const params = new URLSearchParams(window.location.search);
  const pathParams = useParams();
  const teamId = localStorage.getItem('teamId') || pathParams?.teamId;
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const { userDetails } = useSelector((state) => state.userDetails);
  const { orgConfigs } = useSelector((state) => state.orgConfigs);
  const {
    dashboardSidebarExpanded,
    showOnboardingDialog,
    loggedInUserTeamDetails,
  } = useSelector((state) => state.uiStates);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const [currentUser, setCurrentUser] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const [info, setInfo] = useState('');
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);

  const [currentTab, setCurrentTab] = useState('prospects');
  const [playbookTab, setPlaybookTab] = useState('current');
  // const [companyName, setCompanyName] = useState(false);
  const [playbook, setPlaybook] = useState('');
  const [editPlaybook, setEditPlaybook] = useState(false);
  const [playbooks, setPlaybooks] = useState([]);
  const [selectedPlaybook, setSelectedPlaybook] = useState(null);

  const [showPlaybook, setShowPlaybook] = useState(null);

  const [publicBots, setPublicBots] = useState([]);
  const [privateBots, setPrivateBots] = useState([]);
  const [botDescription, setBotDescription] = useState('');
  const [vertical, setVertical] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [competitors, setCompetitors] = useState('');

  const [botVertical, setBotVertical] = useState('');
  const [botDesignation, setBotDesignation] = useState('');
  const [botVoice, setBotVoice] = useState('MALE');
  const [botTitle, setBotTitle] = useState('');
  const [botRole, setBotRole] = useState('');
  const [botCompany, setBotCompany] = useState('');
  const [botAboutCompany, setBotAboutCompany] = useState('');
  const [botExperience, setBotExperience] = useState(null);
  const [employeesCount, setEmployeesCount] = useState(null);
  const [topChallenge, setTopChallenge] = useState('');
  const [topCompetitors, setTopCompetitors] = useState('');
  const [otherInfo, setOtherInfo] = useState('');

  const [showBotRequest, setShowBotRequest] = useState(null);
  const [requestedBots, setRequestedBots] = useState([]);

  const [verticalOptions, setVerticalOptions] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);
  const [showRequestBotForm, setShowRequestBotForm] = useState(false);
  const [hubspotIntergration, setHubspotIntegration] = useState(false);
  const [showHubspotKeyDialog, setShowHubspotKeyDialog] = useState(false);
  const [hubspotIntegrationError, setHubspotIntegrationError] = useState('');
  const [requiredScopes, setRequiredScopes] = useState([]);
  const [hubspotKey, setHubspotKey] = useState('');
  const [keyVisible, setKeyVisible] = useState(false);
  const [integratingHubspot, setIntegratingHubspot] = useState(false);
  const [showHubspotKey, setShowHubspotKey] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [zohoCrmIntegration, setZohoCrmIntegration] = useState(false);

  const [googleIntegration, setGoogleIntegration] = useState(false);
  const [googleMeetIntegration, setGoogleMeetIntegration] = useState(false);
  const [calendarAutoSync, setCalendarAutoSync] = useState(false);
  const [calendarAutoAnalysis, setCalendarAutoAnalysis] = useState(false);
  const [integratingGoogle, setIntegratingGoogle] = useState(false);

  const [zoomIntegration, setZoomIntegration] = useState(false);
  const [integratingZoom, setIntegratingZoom] = useState(false);
  const [showEditPlaybookDrawer, setShowEditPlaybookDrawer] = useState(false);
  const [tags, setTags] = useState([]);

  const [prospectMethodsChanged, setProspectMethodsChanged] = useState(false);
  const editPlaybookRef = useRef(null);

  const [autoAnalysis, setAutoAnalysis] = useState(false);
  const [showGeneratePlaybookWithAI, setShowGeneratePlaybookWithAI] = useState(
    false,
  );

  const [generatedPlaybook, setGeneratedPlaybook] = useState(null);

  const [showQuestionDialog, setShowQuestionDialog] = useState(null);
  const [question, setQuestion] = useState('');
  const [newQuestions, setNewQuestions] = useState(['']);
  const [showOptions, setShowOptions] = useState(false);
  const questionBoxRef = useRef(null);
  const [anchorOptionsEl, setAnchorOptionsEl] = useState(null);
  const [currentMenuIndex, setCurrentMenuIndex] = useState(0);

  const [showSuggestions, setShowSuggestions] = useState(true);
  const [playbookError, setPlaybookError] = useState('');

  const [fetchingPlaybooks, setFetchingPlaybooks] = useState(false);
  const [showDisclosure, setShowDisclosure] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [syncStatus, setSyncStatus] = useState('');
  const [lastSyncOn, setLastSyncOn] = useState(0);
  const [showManualPlaybookCreation, setShowManualPlaybookCreation] = useState(
    false,
  );
  const [showGeneratedPlaybook, setShowGeneratedPlaybook] = useState(false);

  const [showControls, setShowControls] = useState(
    localStorage.getItem('showControls') || false,
  );

  const checkSyncStatusTimer = useRef(null);
  const [showManuallyBotJoinTrigger, setShowManuallyBotJoinTrigger] = useState(
    false,
  );
  const [meetingLink, setMeetingLink] = useState('');
  const [sendingBot, setSendingBot] = useState(false);
  const [showBotJoinStatus, setShowBotJoinStatus] = useState('');

  const [accounts, setAccounts] = useState([]);
  const [
    showConfirmDeleteAccountTag,
    setShowConfirmDeleteAccountTag,
  ] = useState(null);

  const [showAddUpdateAccountDialog, setShowAddUpdateAccountDialog] = useState(
    null,
  );
  const [accountName, setAccountName] = useState('');

  const [rolePlayVisibility, setRolePlayVisibility] = useState('self');

  const [
    showConfirmDeleteQuestionDialog,
    setShowConfirmDeleteQuestionDialog,
  ] = useState(null);

  const [defaultPlaybookFields, setDefaultPlaybookFields] = useState([]);
  const [customPlaybookFields, setCustomPlaybookFields] = useState([]);
  const [methodologies, setMethodologies] = useState([]);

  const [methodologiesAvailable, setMethodologiesAvailable] = useState([]);
  const [defaultCallType, setDefaultCallType] = useState('inbound');

  const [availableAnalysisLanguages, setAvailableAnalysisLanguages] = useState([
    'english',
  ]);
  const [availableRoleplayLanguages, setAvailableRoleplayLanguages] = useState([
    'english',
  ]);
  const [
    availableDefaultAnalysisLanguages,
    setAvailableDefaultAnalysisLanguages,
  ] = useState(['english']);
  const [
    availableDefaultRoleplayLanguages,
    setAvailableDefaultRoleplayLanguages,
  ] = useState(['english']);

  const [supportedAnalysisLanguages, setSupportedAnalysisLanguages] = useState([
    'english',
  ]);
  const [supportedRoleplayLanguages, setSupportedRoleplayLanguages] = useState([
    'english',
  ]);
  const [defaultAnalysisLanguages, setDefaultAnalysisLanguages] = useState([
    'english',
  ]);
  const [defaultRoleplayLanguages, setDefaultRoleplayLanguages] = useState([
    'english',
  ]);

  const [fetchingHubspotProperties, setFetchingHubspotProperties] = useState(
    false,
  );
  const [
    showHubspotPropertiesDialog,
    setShowHubspotPropertiesDialog,
  ] = useState(false);
  const [hubspotDealProperties, setHubspotDealProperties] = useState([]);
  const [missingRequiredScopes, setMissingRequiredScopes] = useState([]);
  const [selectedHubspotProperties, setSelectedHubspotProperties] = useState(
    [],
  );
  const [updatingHubspotProperties, setUpdatingHubspotProperties] = useState(
    false,
  );

  const [
    showConfirmDeletePlaybookDialog,
    setShowConfirmDeletePlaybookDialog,
  ] = useState(null);

  const profilePicRef = useRef(null);
  const [userNameEditable, setUserNameEditable] = useState(false);
  const [fullName, setFullName] = useState('');
  const [teams, setTeams] = useState([]);
  const [selectAllProperties, setSelectAllProperties] = useState(false);
  const [showSyncHubspotCompanies, setShowSyncHubspotCompanies] = useState(
    false,
  );
  const [hubspotCompanies, setHubspotCompanies] = useState([]);
  const [fetchingHubspotCompanies, setFetchingHubspotCompanies] = useState(
    false,
  );
  const [selectedHubspotCompanies, setSelectedHubspotCompanies] = useState([]);

  const [alreadyImportedCompanies, setAlreadyImportedCompanies] = useState([]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const browser = detect();

  const resetFields = () => {
    setBotAboutCompany('');
    setBotCompany('');
    setBotDescription('');
    setBotDesignation('');
    setBotExperience(null);
    setBotRole('');
    setBotTitle('');
    setBotVertical('');
    setBotVoice('MALE');
    setEmployeesCount(null);
    setTopChallenge('');
    setOtherInfo('');
    setTopCompetitors('');
  };

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };
  const initialValues = {
    currentPassword: '',
    newPassword: '',
  };

  const initialValuesPlaybook = {
    companyName: '',
    productDescription: '',
    keyFeatures: '',
    idealCustomerProfile: '',
    customerQualificationQuestions: '',
    objectionsAndAnswers: [
      {
        question: '',
        answer: '',
      },
    ],
    challengesForCustomer: [
      {
        question: '',
        answer: '',
      },
    ],
    expectedCallToAction: '',
    competingProducts: '',
  };

  const handlePrefillFromGeneratedPlaybook = (structure, onboarding) => {
    if (onboarding) {
      setShowManualPlaybookCreation('prefill');
      setGeneratedPlaybook(structure);
    } else {
      setShowGeneratePlaybookWithAI(false);
      setShowEditPlaybookDrawer('prefill');
      setGeneratedPlaybook(structure);
    }
  };

  const handleProfilePictureUpdate = (event) => {
    console.log(event.target.files[0]);
    if (event.target.files[0] && event.target.files[0].type.includes('image')) {
      if (Math.round(event.target.files[0].size) / 1024 > 2048) {
        // setImageSizeError(true);
        dispatch(
          showAlert({
            message: 'Please upload an image upto 2 MB.',
            showCross: true,
            title: null,
            type: 'error',
            autoHideDuration: 2000,
            vertical: 'top',
            horizontal: 'center',
          }),
        );
      } else {
        dispatch(
          updateUserDetails(
            {
              profilePicture: {
                contentType: event.target.files[0].type,
                filename: event.target.files[0].name,
              },
            },
            (data) => {
              // console.log(data);
              dispatch(
                uploadProfilePicture(
                  event.target.files[0],
                  data?.data?.url,
                  userDetails?.id,
                  (error) => {
                    if (error) {
                      dispatch(
                        showAlert({
                          message:
                            'Failed to update profile picture. Please try again!',
                          showCross: true,
                          title: null,
                          type: 'error',
                          autoHideDuration: 2000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                    } else {
                      dispatch(getUserDetails(() => {}));
                      dispatch(
                        showAlert({
                          message: 'Profile picture updated successfully.',
                          showCross: true,
                          title: null,
                          type: 'success',
                          autoHideDuration: 2000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                    }
                  },
                ),
              );
            },
          ),
        );
      }
    } else {
      profilePicRef.current.value = null;
      // setInvalidImageType(true);
      dispatch(
        showAlert({
          message:
            'Please upload a valid image file.Supported formats are jpeg, png.',
          showCross: true,
          title: null,
          type: 'error',
          autoHideDuration: 2000,
          vertical: 'top',
          horizontal: 'center',
        }),
      );
    }
  };

  const onSubmit = (values) => {
    setProcessing(true);
    const credential = EmailAuthProvider.credential(
      userInfo?.email,
      values.currentPassword,
    );
    reauthenticateWithCredential(currentUser, credential)
      .then(function() {
        updatePassword(currentUser, values.newPassword)
          .then(function() {
            setProcessing(false);
            setInfo('Password updated successfully!');
            setError('');
            formik.setFieldValue('currentPassword', '');
            formik.setFieldValue('newPassword', '');
            formik.setErrors({});
            formik.setFieldTouched('currentPassword', false);
            formik.setFieldTouched('newPassword', false);
          })
          .catch(function(error) {
            setError(error.message);
            setProcessing(false);
            setInfo('');
          });
      })
      .catch(function(error) {
        dispatch(
          logError({
            message: error?.message,
            data: {
              formatted: {
                errorText: error?.message,
                errorType: error?.code,
                severity: 'error',
              },
              raw: error,
            },
          }),
        );
        setError('Invalid current password!');
        setProcessing(false);
        setInfo('');
      });
  };

  const onSubmitPlaybook = (values) => {
    // console.log(values);
    handleTrackEvent(null, 'save_or_update_playbook');
    if (!tags || (tags && tags?.length === 0)) {
      setPlaybookError('Playbook title is required');
    } else {
      if (
        showEditPlaybookDrawer === 'new' ||
        showEditPlaybookDrawer === 'prefill'
      ) {
        dispatch(
          createPlaybook(
            teamId || userDetails?.organizationId,
            {
              tags: tags,
              playbookStructure: {
                ...defaultPlaybookFields,
                customPlayBookFields: customPlaybookFields,
              },
            },
            (data, error) => {
              console.log(data, error);
              if (error) {
                dispatch(
                  showAlert({
                    message:
                      'Failed to create playbook. Please try again later!',
                    showCross: true,
                    title: null,
                    type: 'error',
                    autoHideDuration: 2000,
                    vertical: 'top',
                    horizontal: 'center',
                  }),
                );
              } else {
                setFetchingPlaybooks(true);
                dispatch(
                  getPlaybooks(teamId || null, (data) => {
                    setFetchingPlaybooks(false);
                    setPlaybooks(data?.history);
                    setSelectedPlaybook(data?.selectedPlaybook);
                  }),
                );
                if (showManualPlaybookCreation) {
                  setShowManualPlaybookCreation(false);
                }
                if (defaultTab) {
                  dispatch({
                    type: 'UPDATE_UI_STATE',
                    payload: {
                      key: 'onBoardingStep',
                      value: onBoardingStep + 1,
                    },
                  });
                  if (
                    setCompletedStep &&
                    typeof setCompletedStep === 'function'
                  ) {
                    setCompletedStep((prev) => {
                      return {
                        ...prev,
                        [0]: true,
                      };
                    });
                  }
                  dispatch({
                    type: 'UPDATE_ONBOARDING_STEP_COMPLETION_PERCENTAGE',
                    payload: {
                      key: 0,
                      value: methodologiesAvailable ? 25 : 50,
                    },
                  });
                }
                setShowEditPlaybookDrawer(null);
                dispatch(
                  showAlert({
                    message: 'Playbook created successfully',
                    showCross: true,
                    title: null,
                    type: 'success',
                    autoHideDuration: 2000,
                    vertical: 'top',
                    horizontal: 'center',
                  }),
                );
              }
            },
          ),
        );
      } else {
        dispatch(
          updatePlaybook(
            teamId || userDetails?.organizationId,
            showEditPlaybookDrawer,
            {
              tags,
              playbookStructure: {
                ...defaultPlaybookFields,
                customPlayBookFields: customPlaybookFields,
              },
              selected:
                [...playbooks, selectedPlaybook]?.find(
                  (pb) => pb?.id === showEditPlaybookDrawer,
                )?.selected || false,
            },
            () => {
              setFetchingPlaybooks(true);
              dispatch(
                getPlaybooks(teamId || null, (data) => {
                  setFetchingPlaybooks(false);
                  setPlaybooks(data?.history);
                  setSelectedPlaybook(data?.selectedPlaybook);
                }),
              );
              setShowEditPlaybookDrawer(null);
              dispatch(
                showAlert({
                  message: 'Playbook updated successfully.',
                  showCross: true,
                  title: null,
                  type: 'success',
                  autoHideDuration: 2000,
                  vertical: 'top',
                  horizontal: 'center',
                }),
              );
            },
          ),
        );
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const formikPlaybook = useFormik({
    initialValues: initialValuesPlaybook,
    validationSchema: null,
    onSubmit: onSubmitPlaybook,
  });

  useEffect(() => {
    if (
      showAddUpdateAccountDialog &&
      typeof showAddUpdateAccountDialog === 'object'
    ) {
      setAccountName(showAddUpdateAccountDialog?.name);
    }
  }, [showAddUpdateAccountDialog]);

  useEffect(() => {
    if (currentTab === 'playbook') {
      setFetchingPlaybooks(true);
      dispatch(
        getPlaybooks(teamId || null, (data) => {
          // console.log(data);
          setFetchingPlaybooks(false);
          setPlaybooks(data?.history);
          setSelectedPlaybook(data?.selectedPlaybook);
        }),
      );
      dispatch(
        getTeams(50, null, (teams) => {
          // console.log(teams);
          setTeams(teams);
        }),
      );
    } else if (currentTab === 'bots') {
      dispatch(
        getBots(null, (data) => {
          // console.log(data);
          setPublicBots(data?.public);
          setPrivateBots(data?.personal);
        }),
      );
      dispatch(
        getBotRequests((data) => {
          // console.log(data);
          setRequestedBots(data?.botRequests);
        }),
      );
    }
    if (currentTab === 'prospects') {
      dispatch(
        getDefaultPrompt((data) => {
          // console.log(data);
          setVerticalOptions(data?.verticals);
          setDesignationOptions(data?.designations);
        }),
      );
    }
  }, [currentTab, teamId]);

  useEffect(() => {
    if (currentTeam || teamId) {
      if (currentTeam) {
        setMethodologies(currentTeam?.selectedMethodologies || []);
        setDesignation(currentTeam?.botDesignations || []);
        setVertical(currentTeam?.botVerticals || []);
        setAutoAnalysis(currentTeam?.integrations?.generalAutoAnalysis);
        setRolePlayVisibility(currentTeam?.rolePlayVisibility || 'self');
        setAccounts(
          Object.keys(currentTeam?.accountTagMap || {})?.map((key) => {
            return {
              id: key,
              name: currentTeam?.accountTagMap?.[key],
            };
          }) || [],
        );
        setDefaultCallType(currentTeam?.salesCallType || 'outbound');
      } else {
        if (teamId) {
          setMethodologies(
            loggedInUserTeamDetails?.selectedMethodologies || [],
          );
          setDesignation(loggedInUserTeamDetails?.botDesignations || []);
          setVertical(loggedInUserTeamDetails?.botVerticals || []);
          setAutoAnalysis(
            loggedInUserTeamDetails?.integrations?.generalAutoAnalysis,
          );
          setRolePlayVisibility(
            loggedInUserTeamDetails?.rolePlayVisibility || 'self',
          );
          setAccounts(
            Object.keys(loggedInUserTeamDetails?.accountTagMap || {})?.map(
              (key) => {
                return {
                  id: key,
                  name: loggedInUserTeamDetails?.accountTagMap?.[key],
                };
              },
            ) || [],
          );
          setDefaultCallType(
            loggedInUserTeamDetails?.salesCallType || 'outbound',
          );
        }
      }
    } else {
      if (orgConfigs) {
        setMethodologies(orgConfigs?.selectedMethodologies || []);
        setDesignation(orgConfigs?.botDesignations || []);
        setVertical(orgConfigs?.botVerticals || []);
        setAutoAnalysis(orgConfigs?.isGeneralAutoAnalysis);
        setRolePlayVisibility(orgConfigs?.rolePlayVisibility || 'self');
        setAccounts(
          Object.keys(orgConfigs?.accountTagMap || {})?.map((key) => {
            return {
              id: key,
              name: orgConfigs?.accountTagMap?.[key],
            };
          }) || [],
        );
      }
    }
    setSupportedAnalysisLanguages(
      currentTeam
        ? currentTeam?.languagesForAnalysis || []
        : teamId
        ? loggedInUserTeamDetails?.languagesForAnalysis || []
        : orgConfigs?.languagesForAnalysis || [],
    );
    setDefaultAnalysisLanguages(
      currentTeam
        ? currentTeam?.defaultLanguagesForAnalysis || []
        : teamId
        ? loggedInUserTeamDetails?.defaultLanguagesForAnalysis || []
        : orgConfigs?.defaultLanguagesForAnalysis || [],
    );
    setSupportedRoleplayLanguages(
      currentTeam
        ? currentTeam?.languagesForRolePlay || []
        : teamId
        ? loggedInUserTeamDetails?.languagesForRolePlay || []
        : orgConfigs?.languagesForRolePlay || [],
    );
    setDefaultRoleplayLanguages(
      currentTeam
        ? currentTeam?.defaultLanguagesForRolePlay || []
        : teamId
        ? loggedInUserTeamDetails?.defaultLanguagesForRolePlay || []
        : orgConfigs?.defaultLanguagesForRolePlay || [],
    );
  }, [orgConfigs, currentTeam, teamId]);

  useEffect(() => {
    setAvailableAnalysisLanguages(orgConfigs?.languagesForAnalysis || []);
    setAvailableRoleplayLanguages(orgConfigs?.languagesForRolePlay || []);
    setAvailableDefaultAnalysisLanguages(
      orgConfigs?.defaultLanguageForAnalysis || [],
    );
    setAvailableDefaultRoleplayLanguages(
      orgConfigs?.defaultLanguageForRolePlay || [],
    );
  }, [orgConfigs]);

  useEffect(() => {
    if (showManualPlaybookCreation === 'prefill' && generatedPlaybook) {
      console.log('coming here');
      setTags(
        generatedPlaybook?.company?.trim()
          ? [generatedPlaybook?.company?.trim()]
          : orgConfigs?.homePageUrl
          ? [`${orgConfigs?.homePageUrl} Playbook`]
          : userInfo?.email?.split('@')?.[1]
          ? [`${userInfo?.email?.split('@')?.[1]} Playbook`]
          : [],
      );
      if (selectedPlaybook) {
        setDefaultPlaybookFields(
          Object?.keys(selectedPlaybook?.playbookStructure || {})
            ?.sort(
              (a, b) =>
                defaultPlaybookFieldsMapping?.[a]?.order -
                defaultPlaybookFieldsMapping?.[b]?.order,
            )
            ?.filter(
              (key) =>
                key !== 'customPlayBookFields' &&
                selectedPlaybook?.playbookStructure?.[key] !== null,
            )
            ?.reduce((acc, curr) => {
              acc[curr] =
                generatedPlaybook?.[curr] ||
                (curr === 'customerQuestions' ||
                curr === 'challengesForCustomer'
                  ? []
                  : '');
              return acc;
            }, {}),
        );
      } else {
        setDefaultPlaybookFields(
          Object.keys(defaultPlaybookFieldsMapping || {})
            ?.sort(
              (a, b) =>
                defaultPlaybookFieldsMapping?.[a]?.order -
                defaultPlaybookFieldsMapping?.[b]?.order,
            )
            ?.reduce((acc, curr) => {
              acc[curr] =
                generatedPlaybook?.[curr] ||
                (curr === 'customerQuestions' ||
                curr === 'challengesForCustomer'
                  ? []
                  : '');
              return acc;
            }, {}),
        );
        setCustomPlaybookFields([]);
      }
    } else if (showManualPlaybookCreation) {
      console.log('coming here 1');
      if (selectedPlaybook) {
        setDefaultPlaybookFields(
          Object?.keys(selectedPlaybook?.playbookStructure || {})
            ?.sort(
              (a, b) =>
                defaultPlaybookFieldsMapping?.[a]?.order -
                defaultPlaybookFieldsMapping?.[b]?.order,
            )
            ?.filter(
              (key) =>
                key !== 'customPlayBookFields' &&
                selectedPlaybook?.playbookStructure?.[key] !== null,
            )
            ?.reduce((acc, curr) => {
              acc[curr] =
                generatedPlaybook?.[curr] ||
                (curr === 'customerQuestions' ||
                curr === 'challengesForCustomer'
                  ? []
                  : '');
              return acc;
            }, {}),
        );
      } else {
        setDefaultPlaybookFields(
          Object.keys(defaultPlaybookFieldsMapping || {})
            ?.sort(
              (a, b) =>
                defaultPlaybookFieldsMapping?.[a]?.order -
                defaultPlaybookFieldsMapping?.[b]?.order,
            )
            ?.reduce((acc, curr) => {
              acc[curr] =
                generatedPlaybook?.[curr] ||
                (curr === 'customerQuestions' ||
                curr === 'challengesForCustomer'
                  ? []
                  : '');
              return acc;
            }, {}),
        );
        setCustomPlaybookFields([]);
      }
    } else {
      console.log('coming here 2');
      if (showEditPlaybookDrawer) {
        editPlaybookRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }
      if (showEditPlaybookDrawer === 'new') {
        console.log('coming here 3');

        if (selectedPlaybook) {
          setDefaultPlaybookFields(
            Object.keys(selectedPlaybook?.playbookStructure || {})
              ?.sort(
                (a, b) =>
                  defaultPlaybookFieldsMapping?.[a]?.order -
                  defaultPlaybookFieldsMapping?.[b]?.order,
              )
              ?.filter(
                (key) =>
                  key !== 'customPlayBookFields' &&
                  selectedPlaybook?.playbookStructure?.[key] !== null,
              )
              ?.reduce((acc, curr) => {
                acc[curr] =
                  curr === 'customerQuestions' ||
                  curr === 'challengesForCustomer'
                    ? []
                    : '';
                return acc;
              }, {}),
          );
        } else {
          setDefaultPlaybookFields(
            Object.keys(defaultPlaybookFieldsMapping || {})
              ?.sort(
                (a, b) =>
                  defaultPlaybookFieldsMapping?.[a]?.order -
                  defaultPlaybookFieldsMapping?.[b]?.order,
              )
              ?.reduce((acc, curr) => {
                acc[curr] =
                  generatedPlaybook?.[curr] ||
                  (curr === 'customerQuestions' ||
                  curr === 'challengesForCustomer'
                    ? []
                    : '');
                return acc;
              }, {}),
          );
        }
        setTags([]);
      } else if (showEditPlaybookDrawer === 'prefill' && generatedPlaybook) {
        console.log('coming here 4');
        setTags(
          generatedPlaybook?.company?.trim()
            ? [generatedPlaybook?.company?.trim()]
            : orgConfigs?.homePageUrl
            ? [`${orgConfigs?.homePageUrl} Playbook`]
            : userInfo?.email?.split('@')?.[1]
            ? [`${userInfo?.email?.split('@')?.[1]} Playbook`]
            : [],
        );
        if (selectedPlaybook) {
          setDefaultPlaybookFields(
            Object?.keys(selectedPlaybook?.playbookStructure || {})
              ?.sort(
                (a, b) =>
                  defaultPlaybookFieldsMapping?.[a]?.order -
                  defaultPlaybookFieldsMapping?.[b]?.order,
              )
              ?.filter(
                (key) =>
                  key !== 'customPlayBookFields' &&
                  selectedPlaybook?.playbookStructure?.[key] !== null,
              )
              ?.reduce((acc, curr) => {
                acc[curr] =
                  generatedPlaybook?.[curr] ||
                  (curr === 'customerQuestions' ||
                  curr === 'challengesForCustomer'
                    ? []
                    : '');
                return acc;
              }, {}),
          );
        } else {
          setDefaultPlaybookFields(
            Object.keys(defaultPlaybookFieldsMapping || {})
              ?.sort(
                (a, b) =>
                  defaultPlaybookFieldsMapping?.[a]?.order -
                  defaultPlaybookFieldsMapping?.[b]?.order,
              )
              ?.reduce((acc, curr) => {
                acc[curr] =
                  generatedPlaybook?.[curr] ||
                  (curr === 'customerQuestions' ||
                  curr === 'challengesForCustomer'
                    ? []
                    : '');
                return acc;
              }, {}),
          );
        }
      } else {
        console.log('coming here 5');
        if (selectedPlaybook?.id === showEditPlaybookDrawer) {
          console.log('coming here 6');
          setDefaultPlaybookFields(
            Object.keys(selectedPlaybook?.playbookStructure || {})
              ?.sort(
                (a, b) =>
                  defaultPlaybookFieldsMapping?.[a]?.order -
                  defaultPlaybookFieldsMapping?.[b]?.order,
              )
              ?.filter(
                (key) =>
                  key !== 'customPlayBookFields' &&
                  selectedPlaybook?.playbookStructure?.[key] !== null,
              )
              ?.reduce((acc, curr) => {
                acc[curr] =
                  selectedPlaybook?.playbookStructure?.[curr] ||
                  (curr === 'customerQuestions' ||
                  curr === 'challengesForCustomer'
                    ? []
                    : '');
                return acc;
              }, {}),
          );
          setTags(selectedPlaybook?.tags || []);
        } else {
          console.log('coming here 7');
          console.log(showEditPlaybookDrawer);
          console.log(playbooks);
          console.log(
            Object.keys(
              playbooks?.find((pb) => pb?.id === showEditPlaybookDrawer)
                ?.playbookStructure || {},
            )
              ?.sort(
                (a, b) =>
                  defaultPlaybookFieldsMapping?.[a]?.order -
                  defaultPlaybookFieldsMapping?.[b]?.order,
              )
              ?.filter(
                (key) =>
                  key !== 'customPlayBookFields' &&
                  playbooks?.find((pb) => pb?.id === showEditPlaybookDrawer)
                    ?.playbookStructure?.[key] !== null,
              )
              ?.reduce((acc, curr) => {
                acc[curr] =
                  playbooks?.find((pb) => pb?.id === showEditPlaybookDrawer)
                    ?.playbookStructure?.[curr] ||
                  (curr === 'customerQuestions' ||
                  curr === 'challengesForCustomer'
                    ? []
                    : '');
                return acc;
              }, {}),
          );
          setDefaultPlaybookFields(
            Object.keys(
              playbooks?.find((pb) => pb?.id === showEditPlaybookDrawer)
                ?.playbookStructure || {},
            )
              ?.sort(
                (a, b) =>
                  defaultPlaybookFieldsMapping?.[a]?.order -
                  defaultPlaybookFieldsMapping?.[b]?.order,
              )
              ?.filter(
                (key) =>
                  key !== 'customPlayBookFields' &&
                  playbooks?.find((pb) => pb?.id === showEditPlaybookDrawer)
                    ?.playbookStructure?.[key] !== null,
              )
              ?.reduce((acc, curr) => {
                acc[curr] =
                  playbooks?.find((pb) => pb?.id === showEditPlaybookDrawer)
                    ?.playbookStructure?.[curr] ||
                  (curr === 'customerQuestions' ||
                  curr === 'challengesForCustomer'
                    ? []
                    : '');
                return acc;
              }, {}),
          );
          setTags(
            playbooks?.find((pb) => pb?.id === showEditPlaybookDrawer)?.tags ||
              [],
          );
        }
      }
    }
  }, [
    showEditPlaybookDrawer,
    playbooks,
    generatedPlaybook,
    showManualPlaybookCreation,
  ]);

  useEffect(() => {
    setFullName(userDetails?.fullName);
    setHubspotIntegration(userDetails?.custom?.integration?.hubSpot);
    setZohoCrmIntegration(userDetails?.custom?.integration?.zohoCrm);
    setGoogleIntegration(
      userDetails?.custom?.userIntegration?.calendarIntegration,
    );
    setGoogleMeetIntegration(
      userDetails?.custom?.userIntegration?.googleMeetingIntegration,
    );
    setCalendarAutoSync(userDetails?.custom?.userIntegration?.calendarAutoSync);
    setCalendarAutoAnalysis(
      userDetails?.custom?.userIntegration?.calendarAutoAnalysis,
    );
    setZoomIntegration(
      userDetails?.custom?.userIntegration?.zoomMeetingIntegration,
    );
  }, [userDetails]);

  useEffect(() => {
    console.log('pathname', pathname);
    if (defaultTab) {
      setCurrentTab(defaultTab);
    } else {
      if (params && params.get('t')) {
        setCurrentTab(params.get('t'));
      } else {
        if (pathname === '/playbook') {
          setCurrentTab('playbook');
        } else if (pathname === '/integrations') {
          setCurrentTab('integrations');
        } else if (pathname === '/settings') {
          setCurrentTab('integrations');
        } else {
          setCurrentTab('profile');
        }
      }
    }
  }, [window.location.href, defaultTab, pathname]);

  useEffect(() => {
    if (
      arraysEqual(orgConfigs?.selectedMethodologies, methodologies) &&
      arraysEqual(orgConfigs?.botVerticals, vertical) &&
      arraysEqual(orgConfigs?.botDesignations, designation) &&
      orgConfigs?.isGeneralAutoAnalysis === autoAnalysis
    ) {
      setProspectMethodsChanged(false);
    } else {
      setProspectMethodsChanged(true);
    }
  }, [orgConfigs, methodologies, vertical, designation, autoAnalysis]);

  useEffect(() => {
    if (showQuestionDialog !== 'create') {
      setQuestion(showQuestionDialog);
    }
  }, [showQuestionDialog]);

  useEffect(() => {
    if (questionBoxRef && questionBoxRef.current) {
      questionBoxRef.current.addEventListener('onmouseover', () => {
        setShowOptions(true);
      });
      questionBoxRef.current.addEventListener('mouseout', () => {
        setShowOptions(false);
      });
    }
    return () => {
      if (questionBoxRef && questionBoxRef.current) {
        questionBoxRef.current.removeEventListener('onmouseover', () => {
          setShowOptions(true);
        });
        questionBoxRef.current.removeEventListener('mouseout', () => {
          setShowOptions(false);
        });
      }
    };
  }, [questionBoxRef]);

  useEffect(() => {
    if (showHubspotPropertiesDialog) {
      setFetchingHubspotProperties(true);
      dispatch(
        getAllHubspotProperties(false, (data, error) => {
          if (error) {
            setFetchingHubspotProperties(false);
            if (
              error?.message?.startsWith('api key is missing required scopes')
            ) {
              setMissingRequiredScopes(error?.data || []);
              dispatch(
                showAlert({
                  message: `Failed to fetch properties from hubspot! Please add required scopes in your hubspot app.`,
                  showCross: true,
                  title: null,
                  type: 'error',
                  autoHideDuration: 5000,
                  vertical: 'top',
                  horizontal: 'center',
                }),
              );
            } else {
              dispatch(
                showAlert({
                  message:
                    'Failed to fetch properties from hubspot! Please try again',
                  showCross: true,
                  title: null,
                  type: 'success',
                  autoHideDuration: 2000,
                  vertical: 'top',
                  horizontal: 'center',
                }),
              );
            }
          } else {
            setFetchingHubspotProperties(false);
            console.log(data);
            setHubspotDealProperties(data || []);
            if (selectAllProperties) {
              setSelectedHubspotProperties(data || []);
            } else {
              setSelectedHubspotProperties(
                orgConfigs?.hubspotDealProperties || [],
              );
            }
          }
        }),
      );
    }
  }, [showHubspotPropertiesDialog, orgConfigs, selectAllProperties]);

  // useEffect(() => {
  //   if (showEditPlaybookDrawer) {
  //     setShowPlaybook(null);
  //     setDefaultPlaybookFields(
  //       Object?.keys(selectedPlaybook?.playbookStructure || {})
  //         ?.filter(
  //           (key) =>
  //             key !== 'customPlayBookFields' &&
  //             selectedPlaybook?.playbookStructure?.[key] !== null,
  //         )
  //         ?.reduce((acc, curr) => {
  //           acc[curr] =
  //             [...playbooks, selectedPlaybook]?.find(
  //               (pb) => pb?.id === showEditPlaybookDrawer,
  //             )?.playbookStructure?.[curr] ||
  //             (curr === 'customerQuestions' || curr === 'challengesForCustomer'
  //               ? null
  //               : '');
  //           return acc;
  //         }, {}),
  //     );
  //     setCustomPlaybookFields(
  //       selectedPlaybook?.playbookStructure?.customPlayBookFields?.map(
  //         (field) => {
  //           return {
  //             ...field,
  //             content:
  //               [...playbooks, selectedPlaybook]
  //                 ?.find((pb) => pb?.id === showEditPlaybookDrawer)
  //                 ?.playbookStructure?.customPlayBookFields?.find(
  //                   (customField) => customField?.title === field?.title,
  //                 )?.content || '',
  //           };
  //         },
  //       ) || [],
  //     );
  //   }
  // }, [showEditPlaybookDrawer]);

  useEffect(() => {
    if (hubspotIntergration) {
      dispatch(
        getSyncRequestStatus(
          userInfo.orgRoles.includes('ORG_ADMIN') ? 'org' : 'user',
          (data) => {
            if (data?.status === 'completed') {
              setLastSyncOn(data?.createdOn);
              setSyncStatus('completed');
            } else if (data?.status === 'pending') {
              setSyncStatus('pending');
            } else {
              setSyncStatus('');
            }
            // console.log(data);
          },
        ),
      );
    }
  }, [hubspotIntergration]);

  useEffect(() => {
    if (
      orgConfigs &&
      ((orgConfigs?.methodologies && orgConfigs?.methodologies?.length > 0) ||
        (orgConfigs?.customMethodologies &&
          orgConfigs?.customMethodologies?.length > 0))
    ) {
      setMethodologiesAvailable(true);
    } else {
      setMethodologiesAvailable(false);
    }
  }, [orgConfigs]);

  useEffect(() => {
    if (syncStatus === 'pending') {
      checkSyncStatusTimer.current = setInterval(() => {
        dispatch(
          getSyncRequestStatus(
            userInfo.orgRoles.includes('ORG_ADMIN') ? 'org' : 'user',
            (data) => {
              if (data?.status === 'completed') {
                setLastSyncOn(data?.createdOn);
                setSyncStatus('completed');
                clearInterval(checkSyncStatusTimer.current);
              } else if (data?.status === 'pending') {
                setSyncStatus('pending');
              } else {
                setSyncStatus('');
              }
            },
          ),
        );
      }, 20000);
    }
    return () => {
      if (checkSyncStatusTimer.current) {
        clearInterval(checkSyncStatusTimer.current);
      }
    };
  }, [syncStatus]);

  useEffect(() => {
    localStorage.setItem('showControls', showControls);
  }, [showControls]);

  useEffect(() => {
    if (showSyncHubspotCompanies) {
      setFetchingHubspotCompanies(true);
      dispatch(
        getAllHubspotCompanies((data) => {
          console.log(data);
          setFetchingHubspotCompanies(false);
          setHubspotCompanies(
            data?.companies?.filter(
              (company) =>
                !accounts?.find((ac) => ac?.name === company?.properties?.name),
            ) || [],
          );
          setAlreadyImportedCompanies(
            data?.companies?.filter((company) =>
              accounts?.find((ac) => ac?.name === company?.properties?.name),
            ) || [],
          );
        }),
      );
    }
  }, [showSyncHubspotCompanies, accounts]);

  useEffect(() => {
    console.log(accounts, hubspotCompanies);
    // setSelectedHubspotCompanies();
  }, [accounts, hubspotCompanies]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      unsubscribe();
    });
    dispatch(getUserDetails((data) => {}));
    dispatch(getOrganizationConfigs((data) => {}));
  }, []);

  // console.log(currentTab);
  // console.log(defaultTab);

  // console.log(currentTeam);
  // console.log('defaultPlaybookFields', defaultPlaybookFields);
  // console.log('currentTeam', currentTeam);
  // console.log(teamId);
  // console.log(loggedInUserTeamDetails);
  // console.log(currentTab);
  // console.log(defaultTab);
  // console.log(hubspotDealProperties);
  // console.log(selectedHubspotProperties);
  // console.log(selectAllProperties);

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: defaultTab ? 1 : isLoggedIn ? 'calc(100% - 80px)' : 1,
          md: defaultTab
            ? 1
            : isLoggedIn
            ? dashboardSidebarExpanded
              ? 'calc(100% - 240px)'
              : 'calc(100% - 80px)'
            : 1,
        },
        marginLeft: {
          xs: '0px',
          sm: defaultTab ? 0 : isLoggedIn ? '80px' : 0,
          md: defaultTab
            ? 0
            : isLoggedIn
            ? dashboardSidebarExpanded
              ? '240px'
              : '80px'
            : 0,
        },
        height: 1,
        minHeight: isLoggedIn ? 'calc(100vh - 60px)' : 'calc(100vh - 146px)',
      }}
    >
      <Container
        paddingX={
          isLoggedIn
            ? page === 'teamsettings'
              ? 0
              : dashboardSidebarExpanded
              ? 2
              : { lg: 16, md: 8, sm: 4, xs: 1 }
            : 8
        }
        paddingY={page === 'teamsettings' ? 0 : { lg: 2, md: 2, sm: 2, xs: 3 }}
        maxWidth={{ xs: 1, sm: 1, md: isLoggedIn ? 1 : 1236 }}
      >
        <Box
          sx={{
            border: '1px solid #d3d3d3',
            borderRadius: 2,
            minHeight: 'calc(100vh - 90px)',
          }}
        >
          {isXs ||
          (pathname === '/settings' &&
            (currentTab === 'bots' ||
              currentTab === 'donna-presets' ||
              currentTab === 'integrations')) ? (
            <Tabs
              value={currentTab}
              onChange={(e, newValue) => setCurrentTab(newValue)}
              aria-label="Settings Tabs"
              orientation={'horizontal'}
              // indicatorColor={theme.palette.background.default}
              variant="scrollable"
              // scrollButtons={!isMd}
              allowScrollButtonsMobile
              sx={{
                borderBottom: '1px solid #d3d3d3',
              }}
            >
              <Tab
                value={'integrations'}
                label={<Typography variant="body1">Integrations</Typography>}
                id={`simple-tab-${5}`}
                aria-controls={`simple-tabpanel-${5}`}
                sx={{}}
              />
              {/* ) : null} */}
              <Tab
                value={'donna-presets'}
                label={<Typography variant="body1">Ask Donna</Typography>}
                id={`simple-tab-${5}`}
                aria-controls={`simple-tabpanel-${5}`}
                sx={{}}
              />
              <Tab
                value={'bots'}
                label={<Typography variant="body1">Bots</Typography>}
                id={`simple-tab-${5}`}
                aria-controls={`simple-tabpanel-${5}`}
                sx={{}}
              />
            </Tabs>
          ) : null}
          {currentTab === 'profile' ? (
            <Box p={2} position={'relative'}>
              <Box display={'flex'} mb={2} justifyContent={'space-between'}>
                <Box display={'flex'} gap={1}>
                  <Box position={'relative'}>
                    <Avatar
                      variant="rounded"
                      alt={userDetails?.firstName?.toUpperCase()}
                      src={userDetails?.profilePicture?.url}
                      sx={{
                        width: 120,
                        height: 120,
                      }}
                    >
                      {userDetails?.firstName?.[0]?.toUpperCase()}
                    </Avatar>
                    <input
                      style={{ display: 'none' }}
                      id="profileImg"
                      ref={profilePicRef}
                      type="file"
                      accept="image/*"
                      onChange={handleProfilePictureUpdate}
                    />
                    <InputLabel
                      htmlFor="profileImg"
                      sx={{
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: `1px solid ${theme.palette.primary.main}`,
                        background: theme.palette.primary.main,
                        borderRadius: 1,
                        padding: '8px 16px',
                        cursor: 'pointer',
                        width: 30,
                        height: 30,
                        position: 'absolute',
                        bottom: 10,
                        left: 0,
                        // width: 1,
                      }}
                    >
                      {userDetails?.profilePicture?.url ? (
                        <Edit sx={{ color: '#fff' }} />
                      ) : (
                        <Add sx={{ color: '#fff' }} />
                      )}
                    </InputLabel>
                    {/* <Edit
                onClick={() => setEditProfile(true)}
                style={{
                  position: 'absolute',
                  top: 5,
                  right: 5,
                  zIndex: 1,
                  fontSize: 20,
                  cursor: 'pointer',
                }}
              /> */}
                  </Box>
                  <Box
                    ml={2}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'space-between'}
                  >
                    <Box>
                      <Box display={'flex'} alignItems={'center'} gap={1}>
                        {userNameEditable ? (
                          <>
                            <TextField
                              placeholder="Enter Full Name"
                              variant="outlined"
                              name={'fullName'}
                              autoFocus
                              size="small"
                              sx={{
                                width: '100%',
                              }}
                              value={fullName}
                              onChange={(e) => setFullName(e.target.value)}
                              type="text"
                            />
                            <Tooltip title="Cancel" arrow placement="right">
                              <IconButton
                                sx={{
                                  borderRadius: 1,
                                  color: '#fff',
                                  width: 30,
                                  height: 30,
                                  background: theme.palette.text.secondary,
                                  ':hover': {
                                    backgroundColor:
                                      theme.palette.text.secondary,
                                  },
                                }}
                                onClick={() => {
                                  setUserNameEditable(false);
                                }}
                              >
                                <Close sx={{ fontSize: 24 }} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Done" arrow placement="right">
                              <IconButton
                                sx={{
                                  borderRadius: 1,
                                  color: '#fff',
                                  width: 30,
                                  height: 30,
                                  background: theme.palette.primary.main,
                                  ':hover': {
                                    backgroundColor: theme.palette.primary.main,
                                  },
                                }}
                                onClick={() => {
                                  dispatch(
                                    updateUserDetails(
                                      {
                                        firstName: fullName?.split(' ')[0],
                                        lastName: fullName
                                          ?.split(' ')
                                          ?.slice(1)
                                          ?.join(' '),
                                      },
                                      () => {
                                        dispatch(getUserDetails((data) => {}));
                                        setUserNameEditable(false);
                                      },
                                    ),
                                  );
                                }}
                              >
                                <Check sx={{ fontSize: 24 }} />
                              </IconButton>
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            <Typography variant="h5">
                              {capitalizeText(userDetails?.fullName)}
                            </Typography>
                            <Tooltip arrow placement="top" title="Update Name">
                              <IconButton
                                sx={{
                                  borderRadius: '4px',
                                  border: '1px solid #d3d3d3',
                                  padding: '4px',
                                }}
                                onClick={() => {
                                  setUserNameEditable(true);
                                }}
                              >
                                <Edit sx={{ fontSize: 16 }} />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                      </Box>
                      <Typography variant="body2" color={'text.secondary'}>
                        {userInfo?.email}
                      </Typography>
                      <Typography variant="body2" color={'text.secondary'}>
                        {userDetails?.custom?.organization || ''}
                      </Typography>
                    </Box>
                    <Typography variant="caption" color={'text.secondary'}>
                      Joined on{' '}
                      {moment(userDetails?.createdOn).format('DD MMMM YYYY')}
                    </Typography>
                    {process.env.REACT_APP_TARGET_ENV === 'local' ||
                    userInfo?.email === 'gaurav+e4@hiring.express' ? (
                      <FormControl component="fieldset" variant="standard">
                        <FormGroup row>
                          <FormControlLabel
                            checked={showControls}
                            control={
                              <Checkbox
                                size="small"
                                value={showControls}
                                name="showControls"
                              />
                            }
                            label={'Show Account Form Field Configurations'}
                            onChange={(e) => {
                              setShowControls((prev) => !prev);
                            }}
                          />
                        </FormGroup>
                      </FormControl>
                    ) : null}
                  </Box>
                </Box>
                <Box
                  display={'flex'}
                  gap={2}
                  flexDirection={'column'}
                  alignItems={'end'}
                >
                  {userDetails?.custom?.orgType === 'n_org' ? (
                    <>
                      <Box
                        sx={{
                          border: '1px solid #d3d3d3',
                          borderRadius: '8px',
                          padding: 1,
                          paddingTop: 2,
                          minWidth: 200,
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                        position={'relative'}
                      >
                        <Typography
                          variant="caption"
                          color={'text.secondary'}
                          sx={{
                            position: 'absolute',
                            top: -12,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            background: '#fff',
                            padding: '2px 5px',
                          }}
                        >
                          Current Plan
                        </Typography>
                        <Typography variant="body1" textAlign={'center'}>
                          {`${capitalizeText(
                            userDetails?.custom?.plan?.title,
                          )} Plan`}
                        </Typography>
                        {!(
                          userDetails?.plan?.trailEndsOn >
                            new Date().getTime() ||
                          userDetails?.plan?.currentPaymentStatus === 'paid'
                        ) ? (
                          <Button
                            sx={{
                              background: '#fff',
                              color: theme.palette.error.light,
                              ':hover': {
                                color: theme.palette.error.light,
                                background: '#fff',
                              },
                            }}
                            size="small"
                            variant="text"
                          >
                            Plan Expired
                          </Button>
                        ) : null}
                        <Button
                          variant="text"
                          color="primary"
                          sx={{ textAlign: 'center' }}
                          onClick={() => history.push('/pricing')}
                        >
                          See Available Plans
                        </Button>
                      </Box>
                    </>
                  ) : null}
                  <Typography variant="body2" color={'text.secondary'}>
                    App Version v{packageJSON.version}
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <Box mb={2} mt={2}>
                <Typography variant="h6">Change your password</Typography>
                <form onSubmit={formik.handleSubmit}>
                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12}>
                      <Typography
                        variant={'subtitle2'}
                        sx={{ marginBottom: 1, fontWeight: 'bold' }}
                      >
                        Current Password *
                      </Typography>
                      <TextField
                        placeholder="Enter your current password *"
                        variant="outlined"
                        name={'currentPassword'}
                        // fullWidth
                        size="small"
                        value={formik.values.currentPassword}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.currentPassword &&
                          Boolean(formik.errors.currentPassword)
                        }
                        helperText={
                          formik.touched.currentPassword &&
                          formik.errors.currentPassword
                        }
                        type={currentPasswordVisible ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  setCurrentPasswordVisible(
                                    !currentPasswordVisible,
                                  )
                                }
                                onMouseDown={(e) => e.preventDefault()}
                              >
                                {currentPasswordVisible ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant={'subtitle2'}
                        sx={{ marginBottom: 1, fontWeight: 'bold' }}
                      >
                        New Password *
                      </Typography>
                      <TextField
                        placeholder="Enter new password *"
                        variant="outlined"
                        name={'newPassword'}
                        // fullWidth
                        size="small"
                        value={formik.values.newPassword}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.newPassword &&
                          Boolean(formik.errors.newPassword)
                        }
                        helperText={
                          formik.touched.newPassword &&
                          formik.errors.newPassword
                        }
                        type={newPasswordVisible ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  setNewPasswordVisible(!newPasswordVisible)
                                }
                                onMouseDown={(e) => e.preventDefault()}
                              >
                                {newPasswordVisible ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item container xs={12}>
                      <Box display="flex">
                        <Button
                          size={'small'}
                          data-trackid={'update_password'}
                          variant={'contained'}
                          type={'submit'}
                          disabled={processing}
                          startIcon={
                            processing ? <CircularProgress size={18} /> : null
                          }
                          onClick={(e) => {
                            handleTrackEvent(e, 'update_password');
                          }}
                        >
                          Update
                        </Button>
                      </Box>
                    </Grid>
                    {error && (
                      <Grid item xs={12}>
                        <Alert severity="error">{error}</Alert>
                      </Grid>
                    )}
                    {info && (
                      <Grid item xs={12}>
                        <Alert severity="success">{info}</Alert>
                      </Grid>
                    )}
                  </Grid>
                </form>
              </Box>
            </Box>
          ) : currentTab === 'bots' ? (
            <Box
              p={2}
              display={'flex'}
              flexDirection={'column'}
              gap={1}
              alignItems={'start'}
            >
              <Typography variant="caption" color={'text.secondary'}>
                Available Bots
              </Typography>
              <Box
                display={'flex'}
                flexDirection={{
                  lg: 'row',
                  md: 'row',
                  sm: 'row',
                  xs: 'column',
                }}
                alignItems={{
                  lg: 'stretch',
                  md: 'center',
                  sm: 'center',
                  xs: 'start',
                }}
                flexWrap={'wrap'}
                gap={2}
              >
                {publicBots && publicBots?.length > 0
                  ? publicBots?.map((pb) => (
                      <Box
                        key={pb?.id}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          border: '1px solid #d3d3d3',
                          borderRadius: 1,
                          padding: 1,
                          minWidth: 180,
                          maxWidth: 180,
                        }}
                      >
                        <SupportAgent />
                        <Box display={'flex'} flexDirection={'column'} p={1}>
                          <Typography variant="body1">{pb?.title}</Typography>
                          {pb?.description ? (
                            <Typography
                              variant="caption"
                              color={'text.secondary'}
                              sx={{}}
                            >
                              {pb?.description}
                            </Typography>
                          ) : null}
                        </Box>
                      </Box>
                    ))
                  : null}
                {privateBots && privateBots?.length > 0
                  ? privateBots?.map((pb) => (
                      <Box
                        key={pb?.id}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          border: '1px solid #d3d3d3',
                          borderRadius: 1,
                          padding: 1,
                          minWidth: 180,
                          maxWidth: 180,
                        }}
                      >
                        <SupportAgent />
                        <Box display={'flex'} flexDirection={'column'} p={1}>
                          <Typography variant="body1">{pb?.title}</Typography>
                          {pb?.description ? (
                            <Typography
                              variant="caption"
                              color={'text.secondary'}
                              sx={{}}
                            >
                              {pb?.description}
                            </Typography>
                          ) : null}
                        </Box>
                      </Box>
                    ))
                  : null}
              </Box>
              {/* {userInfo?.orgRoles?.includes('ORG_ADMIN') ? (
                <>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setShowRequestBotForm(true)}
                  >
                    Request new bot
                  </Button>
                  <Typography variant="caption" color={'text.secondary'}>
                    Requested Bots
                  </Typography>
                  {requestedBots && requestedBots?.length > 0 ? (
                    <TableContainer
                      component={Paper}
                      sx={
                        {
                          // marginTop: 1,
                          //   minHeight: 'calc(100vh - 160px)',
                          //   maxHeight: 'calc(100vh - 160px)',
                          //   overflowY: 'scroll',
                          //   '&::-webkit-scrollbar': {
                          //     width: '5px',
                          //   },
                          //   '&::-webkit-scrollbar-track': {
                          //     boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          //     webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          //   },
                          //   '&::-webkit-scrollbar-thumb': {
                          //     backgroundColor: theme.palette.primary.main,
                          //     borderRadius: 2,
                          //   },
                        }
                      }
                      // onScroll={handleListScroll}
                    >
                      <Table
                        sx={{ minWidth: 750 }}
                        stickyHeader
                        aria-label="simple table"
                      >
                        <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                          <TableRow>
                            <TableCell align="center">
                              <Typography
                                variant={'caption'}
                                fontWeight={700}
                                sx={{ textTransform: 'uppercase' }}
                              >
                                Bot Details
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                variant={'caption'}
                                fontWeight={700}
                                sx={{ textTransform: 'uppercase' }}
                              >
                                Date
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                variant={'caption'}
                                fontWeight={700}
                                sx={{ textTransform: 'uppercase' }}
                              >
                                Status
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {requestedBots.map((pb, i) => (
                            <TableRow
                              key={i}
                              // sx={{
                              //   '&:last-child td, &:last-child th': {
                              //     border: 0,
                              //   },
                              // }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                align="center"
                              >
                                <Tooltip
                                  title="Bot Details"
                                  placement="left"
                                  arrow
                                >
                                  <Article
                                    sx={{
                                      cursor: 'pointer',
                                      color: theme.palette.primary.main,
                                      marginRight: 2,
                                    }}
                                    onClick={(e) => {
                                      handleTrackEvent(e, 'show_bot_request');
                                      e.stopPropagation();
                                      setShowBotRequest(pb);
                                    }}
                                  />
                                </Tooltip>
                              </TableCell>
                              <TableCell align="center">
                                <Typography variant={'caption'}>
                                  {moment(pb?.createdOn).format('DD MMMM YYYY')}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography variant={'caption'}>
                                  {pb?.status}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                    >
                      <Typography variant="subtitle2" color={'text.secondary'}>
                        You have not requested any bots yet.
                      </Typography>
                    </Box>
                  )}
                </>
              ) : null} */}
            </Box>
          ) : currentTab === 'playbook' ? (
            <>
              {userInfo?.orgRoles?.includes('ORG_ADMIN') ||
              userInfo?.teamRoles?.includes('TEAM_ADMIN') ? (
                <Box p={1}>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                    alignItems={'start'}
                    width={1}
                    // marginTop={2}
                  >
                    {pathname !== '/settings' ? (
                      <>
                        {defaultTab === 'playbook' &&
                        !selectedPlaybook &&
                        onBoardingStep === 1 ? (
                          <Typography variant="body1" color={'text.secondary'}>
                            Let&apos;s create your custom sales playbook to get
                            you started.
                          </Typography>
                        ) : null}
                        {defaultTab === 'playbook' && onBoardingStep === 2 ? (
                          <Typography
                            variant="body1"
                            color={theme.palette.primary.main}
                          >
                            For a realistic experience, select at least one
                            vertical your buyers belong to and at least one
                            designation they hold.
                          </Typography>
                        ) : null}
                        {defaultTab === 'playbook' &&
                        methodologiesAvailable &&
                        onBoardingStep === 3 ? (
                          <Typography
                            variant="body1"
                            color={theme.palette.primary.main}
                          >
                            We recommend picking a few sales methodologies, that
                            align with your sales process, for analysis.
                          </Typography>
                        ) : null}
                        {defaultTab === 'playbook' &&
                        ((!methodologiesAvailable && onBoardingStep === 3) ||
                          (methodologiesAvailable && onBoardingStep === 4)) ? (
                          <Typography
                            variant="body1"
                            color={theme.palette.primary.main}
                          >
                            We recommend you to turn on the auto analysis so
                            that every time you upload new sales call it gets
                            auto analysed and report is generated.
                          </Typography>
                        ) : null}
                        <Box
                          width={1}
                          display={'flex'}
                          flexDirection={'column'}
                          alignItems={'start'}
                          gap={1}
                        >
                          {defaultTab === 'playbook' &&
                          page === 'teamsettings' ? (
                            <>
                              <Box sx={{ mb: 2 }}>
                                {availableAnalysisLanguages?.length > 1 ||
                                availableDefaultAnalysisLanguages?.length > 1 ||
                                availableDefaultRoleplayLanguages?.length > 1 ||
                                availableRoleplayLanguages?.length > 1 ? (
                                  <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    gap={2}
                                  >
                                    <DialogContentText
                                      variant="subtitle2"
                                      id="alert-dialog-description"
                                      flex={1}
                                    >
                                      Change these default language and call
                                      settings and hit save
                                    </DialogContentText>
                                  </Box>
                                ) : null}
                                {availableAnalysisLanguages?.length > 1 ||
                                availableDefaultAnalysisLanguages?.length >
                                  1 ? (
                                  <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    position={'relative'}
                                    sx={{
                                      border: '1px solid #d3d3d3',
                                      width: 1,
                                      borderRadius: 1,
                                      padding: 2,
                                      minHeight: 20,
                                      mt: 2,
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        position: 'absolute',
                                        top: -12,
                                        left: 10,
                                        background: '#fff',
                                      }}
                                      variant="subtitle2"
                                      color={'text.secondary'}
                                    >
                                      Multi Language Analysis
                                    </Typography>
                                    {/* {multiLanguageAnalysisSupport ? ( */}
                                    <Box
                                      display={'flex'}
                                      flexDirection={'column'}
                                      gap={1}
                                    >
                                      {/* <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        gap={1}
                                        width={1}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          color={'text.secondary'}
                                          sx={{ flex: 1 }}
                                        >
                                          Supported Languages
                                        </Typography>
                                        <FormControl size="small">
                                          <Select
                                            size="small"
                                            multiple
                                            labelId="analysis-lang-support-select-label"
                                            id="analysis-lang-support-select"
                                            name="supportedAnalysisLanguages"
                                            MenuProps={{
                                              disableScrollLock: true,
                                            }}
                                            onChange={(e) => {
                                              setSupportedAnalysisLanguages(
                                                e.target.value,
                                              );
                                              dispatch(
                                                updateTeam(
                                                  teamId,
                                                  {
                                                    languagesForAnalysis:
                                                      e.target.value || [],
                                                  },
                                                  (teamData) => {
                                                    dispatch({
                                                      type:
                                                        'UPDATE_TEAM_DETAILS',
                                                      payload: {
                                                        id: teamId,
                                                        data: {
                                                          ...teamData,
                                                        },
                                                      },
                                                    });
                                                    setCurrentTeam(teamData);
                                                  },
                                                  () => {},
                                                ),
                                              );
                                            }}
                                            value={supportedAnalysisLanguages}
                                          >
                                            {availableAnalysisLanguages?.map(
                                              (lang) => (
                                                <MenuItem
                                                  key={lang}
                                                  value={lang}
                                                >
                                                  <Typography variant="caption">
                                                    {capitalizeText(lang)}
                                                  </Typography>
                                                </MenuItem>
                                              ),
                                            )}
                                          </Select>
                                        </FormControl>
                                      </Box> */}
                                      <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        gap={1}
                                        width={1}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          color={'text.secondary'}
                                          // sx={{ marginBottom: 1 }}
                                          sx={{ flex: 1 }}
                                        >
                                          Default Languages
                                        </Typography>
                                        <FormControl size="small">
                                          <Select
                                            size="small"
                                            multiple
                                            labelId="analysis-default-language-select-label"
                                            id="analysis-default-language-select"
                                            name="defaultAnalysisLanguages"
                                            MenuProps={{
                                              disableScrollLock: true,
                                            }}
                                            onChange={(e) => {
                                              setDefaultAnalysisLanguages(
                                                e.target.value,
                                              );
                                              dispatch(
                                                updateTeam(
                                                  teamId,
                                                  {
                                                    defaultLanguagesForAnalysis:
                                                      e.target.value || [],
                                                  },
                                                  (teamData) => {
                                                    dispatch({
                                                      type:
                                                        'UPDATE_TEAM_DETAILS',
                                                      payload: {
                                                        id: teamId,
                                                        data: {
                                                          ...teamData,
                                                        },
                                                      },
                                                    });
                                                    setCurrentTeam(teamData);
                                                  },
                                                  () => {},
                                                ),
                                              );
                                            }}
                                            value={defaultAnalysisLanguages}
                                          >
                                            {availableAnalysisLanguages?.map(
                                              (lang) => (
                                                <MenuItem
                                                  key={lang}
                                                  value={lang}
                                                >
                                                  <Typography variant="caption">
                                                    {capitalizeText(lang)}
                                                  </Typography>
                                                </MenuItem>
                                              ),
                                            )}
                                          </Select>
                                        </FormControl>
                                      </Box>
                                    </Box>
                                    {/* ) : null} */}
                                  </Box>
                                ) : null}
                                {availableRoleplayLanguages?.length > 1 ||
                                availableDefaultRoleplayLanguages?.length >
                                  1 ? (
                                  <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    position={'relative'}
                                    sx={{
                                      border: '1px solid #d3d3d3',
                                      width: 1,
                                      borderRadius: 1,
                                      padding: 2,
                                      minHeight: 20,
                                      mt: 2,
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        position: 'absolute',
                                        top: -12,
                                        left: 10,
                                        background: '#fff',
                                      }}
                                      variant="subtitle2"
                                      color={'text.secondary'}
                                    >
                                      Multi Language Roleplay
                                    </Typography>
                                    <Box
                                      display={'flex'}
                                      flexDirection={'column'}
                                      gap={1}
                                    >
                                      <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        gap={1}
                                        width={1}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          color={'text.secondary'}
                                          // sx={{ marginBottom: 1 }}
                                          sx={{ flex: 1 }}
                                        >
                                          Default Languages
                                        </Typography>
                                        <FormControl size="small">
                                          <Select
                                            size="small"
                                            multiple
                                            labelId="roleplay-default-language-select-label"
                                            id="roleplay-default-language-select"
                                            name="defaultRoleplayLanguages"
                                            MenuProps={{
                                              disableScrollLock: true,
                                            }}
                                            onChange={(e) => {
                                              setDefaultAnalysisLanguages(
                                                e.target.value,
                                              );
                                              dispatch(
                                                updateTeam(
                                                  teamId,
                                                  {
                                                    defaultLanguagesForRolePlay:
                                                      e.target.value || [],
                                                  },
                                                  (teamData) => {
                                                    dispatch({
                                                      type:
                                                        'UPDATE_TEAM_DETAILS',
                                                      payload: {
                                                        id: teamId,
                                                        data: {
                                                          ...teamData,
                                                        },
                                                      },
                                                    });
                                                    setCurrentTeam(teamData);
                                                  },
                                                  () => {},
                                                ),
                                              );
                                            }}
                                            value={defaultRoleplayLanguages}
                                          >
                                            {availableRoleplayLanguages?.map(
                                              (lang) => (
                                                <MenuItem
                                                  key={lang}
                                                  value={lang}
                                                >
                                                  <Typography variant="caption">
                                                    {capitalizeText(lang)}
                                                  </Typography>
                                                </MenuItem>
                                              ),
                                            )}
                                          </Select>
                                        </FormControl>
                                      </Box>
                                    </Box>
                                  </Box>
                                ) : null}
                              </Box>
                            </>
                          ) : null}
                          {(defaultTab === 'playbook' &&
                            onBoardingStep === 2) ||
                          page === 'teamsettings' ||
                          !defaultTab ? (
                            <Box
                              display={'flex'}
                              flexDirection={'column'}
                              gap={1}
                              alignItems={'start'}
                              border={'1px solid #d3d3d3'}
                              borderRadius={2}
                              p={2}
                              position={'relative'}
                              pt={3}
                              // mt={2}
                              width={1}
                              className="playbook-page"
                            >
                              <Box
                                // width={1}
                                display={'flex'}
                                alignItems={'center'}
                                gap={2}
                                justifyContent={'space-between'}
                                position={'absolute'}
                                sx={{
                                  top: -16,
                                  background: '#fff',
                                  padding: 1,
                                  left: 10,
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  fontWeight={'bold'}
                                  // color={'text.secondary'}
                                >
                                  About Your Target (Prospect you will be
                                  selling to)
                                </Typography>
                              </Box>
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                gap={1}
                                alignItems={'start'}
                                // ml={2}
                                width={1}
                              >
                                <Box>
                                  <Typography
                                    variant="subtitle2"
                                    color={'text.secondary'}
                                    fontWeight={'bold'}
                                  >
                                    Your Target&apos;s Vertical (The vertical
                                    your prospect belongs to)
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    color={'text.secondary'}
                                  >
                                    Select or type your own
                                  </Typography>
                                </Box>
                                <AutoCompleteMUI
                                  id="vertical"
                                  multiple
                                  value={vertical}
                                  onKeyDown={(event) => {
                                    if (event.target.value.trim()) {
                                      setVertical((prev) => [
                                        ...prev,
                                        event.target.value,
                                      ]);
                                      if (currentTeam) {
                                        dispatch(
                                          updateTeam(
                                            teamId,
                                            {
                                              selectedMethodologies:
                                                methodologies || [],
                                              botDesignations: designation,
                                              botVerticals: [
                                                ...vertical,
                                                event.target.value,
                                              ],
                                              generalAutoAnalysis: autoAnalysis,
                                              textInLogo:
                                                orgConfigs?.textInLogo,
                                            },
                                            (teamData) => {
                                              dispatch({
                                                type: 'UPDATE_TEAM_DETAILS',
                                                payload: {
                                                  id: teamId,
                                                  data: {
                                                    ...teamData,
                                                  },
                                                },
                                              });
                                              setCurrentTeam(teamData);
                                            },
                                            () => {},
                                          ),
                                        );
                                      } else {
                                        dispatch(
                                          updateGlobalOrganizationConfig(
                                            {
                                              selectedMethodologies:
                                                methodologies || [],
                                              botDesignations: designation,
                                              botVerticals: [
                                                ...vertical,
                                                event.target.value,
                                              ],
                                              generalAutoAnalysis: autoAnalysis,
                                              textInLogo:
                                                orgConfigs?.textInLogo,
                                            },
                                            () => {
                                              dispatch(
                                                getOrganizationConfigs(
                                                  () => {},
                                                ),
                                              );
                                            },
                                          ),
                                        );
                                      }
                                    }
                                  }}
                                  onChange={(event, newValue, reason) => {
                                    if (currentTeam) {
                                      dispatch(
                                        updateTeam(
                                          teamId,
                                          {
                                            selectedMethodologies:
                                              methodologies || [],
                                            botDesignations: designation,
                                            botVerticals: [...newValue],
                                            generalAutoAnalysis: autoAnalysis,
                                            textInLogo: orgConfigs?.textInLogo,
                                          },
                                          (teamData) => {
                                            setVertical([...newValue]);
                                            dispatch({
                                              type: 'UPDATE_TEAM_DETAILS',
                                              payload: {
                                                id: teamId,
                                                data: {
                                                  ...teamData,
                                                },
                                              },
                                            });
                                            setCurrentTeam(teamData);
                                          },
                                          () => {},
                                        ),
                                      );
                                    } else {
                                      dispatch(
                                        updateGlobalOrganizationConfig(
                                          {
                                            selectedMethodologies:
                                              methodologies || [],
                                            botDesignations: designation,
                                            botVerticals: [...newValue],
                                            generalAutoAnalysis: autoAnalysis,
                                            textInLogo: orgConfigs?.textInLogo,
                                          },
                                          () => {
                                            dispatch(
                                              getOrganizationConfigs(() => {}),
                                            );
                                            setVertical([...newValue]);
                                          },
                                        ),
                                      );
                                    }
                                    // console.log(reason);
                                  }}
                                  options={verticalOptions}
                                />
                              </Box>
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                gap={1}
                                alignItems={'start'}
                                // ml={2}
                                width={1}
                              >
                                <Box>
                                  <Typography
                                    variant="subtitle2"
                                    fontWeight={'bold'}
                                    color={'text.secondary'}
                                  >
                                    Your Target&apos;s Designation (Designation
                                    of your prospect)
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    color={'text.secondary'}
                                  >
                                    Select or type your own
                                  </Typography>
                                </Box>
                                <AutoCompleteMUI
                                  id="designation"
                                  multiple
                                  value={designation}
                                  onKeyDown={(event) => {
                                    if (event.target.value.trim()) {
                                      if (currentTeam) {
                                        dispatch(
                                          updateTeam(
                                            teamId,
                                            {
                                              selectedMethodologies:
                                                methodologies || [],
                                              botDesignations: [
                                                ...designation,
                                                event.target.value,
                                              ],
                                              botVerticals: vertical,
                                              generalAutoAnalysis: autoAnalysis,
                                              textInLogo:
                                                orgConfigs?.textInLogo,
                                            },
                                            (teamData) => {
                                              setDesignation((prev) => [
                                                ...prev,
                                                event.target.value,
                                              ]);
                                              setCurrentTeam(teamData);
                                              dispatch({
                                                type: 'UPDATE_TEAM_DETAILS',
                                                payload: {
                                                  id: teamId,
                                                  data: {
                                                    ...teamData,
                                                  },
                                                },
                                              });
                                            },
                                            () => {},
                                          ),
                                        );
                                      } else {
                                        dispatch(
                                          updateGlobalOrganizationConfig(
                                            {
                                              selectedMethodologies:
                                                methodologies || [],
                                              botDesignations: [
                                                ...designation,
                                                event.target.value,
                                              ],
                                              botVerticals: vertical,
                                              generalAutoAnalysis: autoAnalysis,
                                              textInLogo:
                                                orgConfigs?.textInLogo,
                                            },
                                            () => {
                                              dispatch(
                                                getOrganizationConfigs(
                                                  () => {},
                                                ),
                                              );
                                              setDesignation((prev) => [
                                                ...prev,
                                                event.target.value,
                                              ]);
                                            },
                                          ),
                                        );
                                      }
                                    }
                                  }}
                                  onChange={(event, newValue) => {
                                    // if (newValue.includes('Other')) {
                                    //   setDesignation([]);
                                    // } else {
                                    setDesignation([...newValue]);
                                    // }
                                    if (currentTeam) {
                                      dispatch(
                                        updateTeam(
                                          teamId,
                                          {
                                            selectedMethodologies:
                                              methodologies || [],
                                            botDesignations: [...newValue],
                                            botVerticals: vertical,
                                            generalAutoAnalysis: autoAnalysis,
                                            textInLogo: orgConfigs?.textInLogo,
                                          },
                                          (teamData) => {
                                            dispatch({
                                              type: 'UPDATE_TEAM_DETAILS',
                                              payload: {
                                                id: teamId,
                                                data: {
                                                  ...teamData,
                                                },
                                              },
                                            });
                                            setCurrentTeam(teamData);
                                          },
                                          () => {},
                                        ),
                                      );
                                    } else {
                                      dispatch(
                                        updateGlobalOrganizationConfig(
                                          {
                                            selectedMethodologies:
                                              methodologies || [],
                                            botDesignations: [...newValue],
                                            botVerticals: vertical,
                                            generalAutoAnalysis: autoAnalysis,
                                            textInLogo: orgConfigs?.textInLogo,
                                          },
                                          () => {
                                            dispatch(
                                              getOrganizationConfigs(() => {}),
                                            );
                                          },
                                        ),
                                      );
                                    }
                                  }}
                                  options={designationOptions}
                                />
                                {/* <SkillTagInput
                            preSkills={
                              designation && designation?.length
                                ? designation.map((kw) => {
                                    return {
                                      label: kw,
                                      value: kw,
                                    };
                                  })
                                : []
                            }
                            skillsTag={(value) => {
                              const keywords = value.map(
                                (skils) => skils.value,
                              );
                              setDesignation(keywords);
                            }}
                            options={designationOptions.map((op) => {
                              return { label: op, value: op };
                            })}
                            // placeholder={`Your Target's Vertical (The vertical your prospect belongs to)`}
                          /> */}
                              </Box>
                            </Box>
                          ) : null}
                          {(defaultTab === 'playbook' &&
                            methodologiesAvailable &&
                            onBoardingStep === 3) ||
                          page === 'teamsettings' ||
                          !defaultTab ? (
                            <Box
                              display={'flex'}
                              flexDirection={'column'}
                              gap={1}
                              alignItems={'start'}
                              border={'1px solid #d3d3d3'}
                              borderRadius={2}
                              p={2}
                              position={'relative'}
                              pt={3}
                              width={1}
                              mt={1}
                            >
                              <Box
                                // width={1}
                                display={'flex'}
                                alignItems={'center'}
                                gap={2}
                                justifyContent={'space-between'}
                                position={'absolute'}
                                sx={{
                                  top: -16,
                                  background: '#fff',
                                  padding: 1,
                                  left: 10,
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  fontWeight={'bold'}
                                >
                                  <span>Sales methodologies followed</span>
                                </Typography>
                              </Box>
                              <Typography variant="subtitle2">
                                If you follow one or more of the following sales
                                methodologies, the calls can be analyzed on
                                those as well in addition to your playbook.
                                Select all methodologies that apply.
                              </Typography>
                              <FormControl
                                component="fieldset"
                                variant="standard"
                              >
                                <FormGroup row>
                                  {orgConfigs?.methodologies?.map((mthd) => (
                                    <FormControlLabel
                                      key={mthd}
                                      checked={methodologies?.includes(mthd)}
                                      control={
                                        <Checkbox
                                          size="small"
                                          value={mthd}
                                          name="methods"
                                        />
                                      }
                                      label={
                                        mthd === 'meddpic'
                                          ? 'MEDDICC'
                                          : mthd?.toUpperCase()
                                      }
                                      onChange={(e) => {
                                        if (methodologies?.includes(mthd)) {
                                          setMethodologies((prev) =>
                                            prev.filter(
                                              (mthd) => mthd !== e.target.value,
                                            ),
                                          );
                                          if (currentTeam) {
                                            dispatch(
                                              updateTeam(
                                                teamId,
                                                {
                                                  selectedMethodologies:
                                                    methodologies?.filter(
                                                      (mthd) =>
                                                        mthd !== e.target.value,
                                                    ) || [],
                                                  botDesignations: designation,
                                                  botVerticals: vertical,
                                                  generalAutoAnalysis: autoAnalysis,
                                                  textInLogo:
                                                    orgConfigs?.textInLogo,
                                                },
                                                (teamData) => {
                                                  dispatch({
                                                    type: 'UPDATE_TEAM_DETAILS',
                                                    payload: {
                                                      id: teamId,
                                                      data: {
                                                        ...teamData,
                                                      },
                                                    },
                                                  });
                                                  setCurrentTeam(teamData);
                                                },
                                                () => {},
                                              ),
                                            );
                                          } else {
                                            dispatch(
                                              updateGlobalOrganizationConfig(
                                                {
                                                  selectedMethodologies:
                                                    methodologies?.filter(
                                                      (mthd) =>
                                                        mthd !== e.target.value,
                                                    ) || [],
                                                  botDesignations: designation,
                                                  botVerticals: vertical,
                                                  generalAutoAnalysis: autoAnalysis,
                                                  textInLogo:
                                                    orgConfigs?.textInLogo,
                                                },
                                                () => {
                                                  dispatch(
                                                    getOrganizationConfigs(
                                                      () => {},
                                                    ),
                                                  );
                                                },
                                              ),
                                            );
                                          }
                                        } else {
                                          setMethodologies((prev) => [
                                            ...prev,
                                            e.target.value,
                                          ]);
                                          if (currentTeam) {
                                            dispatch(
                                              updateTeam(
                                                teamId,
                                                {
                                                  selectedMethodologies:
                                                    [
                                                      ...methodologies,
                                                      e.target.value,
                                                    ] || [],
                                                  botDesignations: designation,
                                                  botVerticals: vertical,
                                                  generalAutoAnalysis: autoAnalysis,
                                                  textInLogo:
                                                    orgConfigs?.textInLogo,
                                                },
                                                (teamData) => {
                                                  setCurrentTeam(teamData);
                                                  dispatch({
                                                    type: 'UPDATE_TEAM_DETAILS',
                                                    payload: {
                                                      id: teamId,
                                                      data: {
                                                        ...teamData,
                                                      },
                                                    },
                                                  });
                                                },
                                                () => {},
                                              ),
                                            );
                                          } else {
                                            dispatch(
                                              updateGlobalOrganizationConfig(
                                                {
                                                  selectedMethodologies:
                                                    [
                                                      ...methodologies,
                                                      e.target.value,
                                                    ] || [],
                                                  botDesignations: designation,
                                                  botVerticals: vertical,
                                                  generalAutoAnalysis: autoAnalysis,
                                                  textInLogo:
                                                    orgConfigs?.textInLogo,
                                                },
                                                () => {
                                                  dispatch(
                                                    getOrganizationConfigs(
                                                      () => {},
                                                    ),
                                                  );
                                                },
                                              ),
                                            );
                                          }
                                        }
                                      }}
                                    />
                                  ))}
                                  {orgConfigs?.customMethodologies?.map(
                                    (mthd) => (
                                      <FormControlLabel
                                        key={mthd}
                                        checked={methodologies?.includes(mthd)}
                                        control={
                                          <Checkbox
                                            size="small"
                                            value={mthd}
                                            name="methods"
                                          />
                                        }
                                        label={mthd?.toUpperCase()}
                                        onChange={(e) => {
                                          if (methodologies?.includes(mthd)) {
                                            setMethodologies((prev) =>
                                              prev?.filter(
                                                (mthd) =>
                                                  mthd !== e.target.value,
                                              ),
                                            );
                                            if (currentTeam) {
                                              dispatch(
                                                updateTeam(
                                                  teamId,
                                                  {
                                                    selectedMethodologies:
                                                      methodologies.filter(
                                                        (mthd) =>
                                                          mthd !==
                                                          e.target.value,
                                                      ) || [],
                                                    botDesignations: designation,
                                                    botVerticals: vertical,
                                                    generalAutoAnalysis: autoAnalysis,
                                                    textInLogo:
                                                      orgConfigs?.textInLogo,
                                                  },
                                                  (teamData) => {
                                                    setCurrentTeam(teamData);
                                                    dispatch({
                                                      type:
                                                        'UPDATE_TEAM_DETAILS',
                                                      payload: {
                                                        id: teamId,
                                                        data: {
                                                          ...teamData,
                                                        },
                                                      },
                                                    });
                                                  },
                                                  () => {},
                                                ),
                                              );
                                            } else {
                                              dispatch(
                                                updateGlobalOrganizationConfig(
                                                  {
                                                    selectedMethodologies:
                                                      methodologies.filter(
                                                        (mthd) =>
                                                          mthd !==
                                                          e.target.value,
                                                      ) || [],
                                                    botDesignations: designation,
                                                    botVerticals: vertical,
                                                    generalAutoAnalysis: autoAnalysis,
                                                    textInLogo:
                                                      orgConfigs?.textInLogo,
                                                  },
                                                  () => {
                                                    dispatch(
                                                      getOrganizationConfigs(
                                                        () => {},
                                                      ),
                                                    );
                                                  },
                                                ),
                                              );
                                            }
                                          } else {
                                            setMethodologies((prev) => [
                                              ...prev,
                                              e.target.value,
                                            ]);
                                            if (currentTeam) {
                                              dispatch(
                                                updateTeam(
                                                  teamId,
                                                  {
                                                    selectedMethodologies:
                                                      [
                                                        ...methodologies,
                                                        e.target.value,
                                                      ] || [],
                                                    botDesignations: designation,
                                                    botVerticals: vertical,
                                                    generalAutoAnalysis: autoAnalysis,
                                                    textInLogo:
                                                      orgConfigs?.textInLogo,
                                                  },
                                                  (teamData) => {
                                                    setCurrentTeam(teamData);
                                                    dispatch({
                                                      type:
                                                        'UPDATE_TEAM_DETAILS',
                                                      payload: {
                                                        id: teamId,
                                                        data: {
                                                          ...teamData,
                                                        },
                                                      },
                                                    });
                                                  },
                                                  () => {},
                                                ),
                                              );
                                            } else {
                                              dispatch(
                                                updateGlobalOrganizationConfig(
                                                  {
                                                    selectedMethodologies:
                                                      [
                                                        ...methodologies,
                                                        e.target.value,
                                                      ] || [],
                                                    botDesignations: designation,
                                                    botVerticals: vertical,
                                                    generalAutoAnalysis: autoAnalysis,
                                                    textInLogo:
                                                      orgConfigs?.textInLogo,
                                                  },
                                                  () => {
                                                    dispatch(
                                                      getOrganizationConfigs(
                                                        () => {},
                                                      ),
                                                    );
                                                  },
                                                ),
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    ),
                                  )}
                                </FormGroup>
                              </FormControl>
                            </Box>
                          ) : null}
                          {(defaultTab === 'playbook' &&
                            ((!methodologiesAvailable &&
                              onBoardingStep === 3) ||
                              (methodologiesAvailable &&
                                onBoardingStep === 4))) ||
                          page === 'teamsettings' ||
                          !defaultTab ? (
                            <>
                              {userDetails?.custom?.orgType ===
                              'demo_org' ? null : (
                                <Box
                                  display={'flex'}
                                  flexDirection={'column'}
                                  gap={1}
                                  alignItems={'start'}
                                  border={'1px solid #d3d3d3'}
                                  borderRadius={2}
                                  p={2}
                                  position={'relative'}
                                  pt={3}
                                  width={1}
                                  mt={1}
                                >
                                  <Box
                                    // width={1}
                                    display={'flex'}
                                    alignItems={'center'}
                                    gap={2}
                                    justifyContent={'space-between'}
                                    position={'absolute'}
                                    sx={{
                                      top: -16,
                                      background: '#fff',
                                      padding: 1,
                                      left: 10,
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      fontWeight={'bold'}
                                    >
                                      <span>
                                        Analyze uploaded calls automatically
                                      </span>
                                    </Typography>
                                  </Box>
                                  <Typography variant="subtitle2">
                                    If turned on, each uploaded call will be
                                    analyzed automatically
                                  </Typography>
                                  <FormControl
                                    component="fieldset"
                                    variant="standard"
                                  >
                                    <FormGroup row>
                                      <FormControlLabel
                                        checked={autoAnalysis}
                                        control={
                                          <Checkbox
                                            size="small"
                                            value={autoAnalysis}
                                            name="autoAnalysis"
                                          />
                                        }
                                        label={'Auto Analysis'}
                                        onChange={(e) => {
                                          setAutoAnalysis(e.target.checked);
                                          if (currentTeam) {
                                            dispatch(
                                              updateTeam(
                                                teamId,
                                                {
                                                  selectedMethodologies:
                                                    methodologies || [],
                                                  botDesignations: designation,
                                                  botVerticals: vertical,
                                                  generalAutoAnalysis:
                                                    e.target.checked,
                                                  textInLogo:
                                                    orgConfigs?.textInLogo,
                                                },
                                                (teamData) => {
                                                  setCurrentTeam(teamData);
                                                  dispatch({
                                                    type: 'UPDATE_TEAM_DETAILS',
                                                    payload: {
                                                      id: teamId,
                                                      data: {
                                                        ...teamData,
                                                      },
                                                    },
                                                  });
                                                },
                                                () => {},
                                              ),
                                            );
                                          } else {
                                            dispatch(
                                              updateGlobalOrganizationConfig(
                                                {
                                                  selectedMethodologies:
                                                    methodologies || [],
                                                  botDesignations: designation,
                                                  botVerticals: vertical,
                                                  generalAutoAnalysis:
                                                    e.target.checked,
                                                  textInLogo:
                                                    orgConfigs?.textInLogo,
                                                },
                                                () => {
                                                  dispatch(
                                                    getOrganizationConfigs(
                                                      () => {},
                                                    ),
                                                  );
                                                },
                                              ),
                                            );
                                          }
                                        }}
                                      />
                                    </FormGroup>
                                  </FormControl>
                                </Box>
                              )}
                              {(defaultTab !== 'playbook' &&
                                userInfo?.custom?.orgType !== 'demo_org') ||
                              (defaultTab === 'playbook' &&
                                page === 'teamsettings') ? (
                                <>
                                  {orgConfigs?.rolePlayVisibilityControl ? (
                                    <Box
                                      display={'flex'}
                                      flexDirection={'column'}
                                      gap={1}
                                      alignItems={'start'}
                                      border={'1px solid #d3d3d3'}
                                      borderRadius={2}
                                      p={2}
                                      position={'relative'}
                                      pt={3}
                                      width={1}
                                      mt={1}
                                    >
                                      <Box
                                        // width={1}
                                        display={'flex'}
                                        alignItems={'center'}
                                        gap={2}
                                        justifyContent={'space-between'}
                                        position={'absolute'}
                                        sx={{
                                          top: -16,
                                          background: '#fff',
                                          padding: 1,
                                          left: 10,
                                        }}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          fontWeight={'bold'}
                                        >
                                          <span>Set roleplay visibility</span>
                                        </Typography>
                                      </Box>
                                      <Typography variant="subtitle2">
                                        You can make the roleplay visible to
                                        team admins or private.
                                      </Typography>
                                      <FormControl size="small">
                                        <Select
                                          size="small"
                                          labelId={'rolePlayVisibility'}
                                          id={'rolePlayVisibility'}
                                          name={'rolePlayVisibility'}
                                          MenuProps={{
                                            disableScrollLock: true,
                                          }}
                                          onChange={(e) => {
                                            setRolePlayVisibility(
                                              e.target.value,
                                            );
                                            if (currentTeam) {
                                              dispatch(
                                                updateTeam(
                                                  teamId,
                                                  {
                                                    selectedMethodologies:
                                                      methodologies || [],
                                                    botDesignations: designation,
                                                    botVerticals: vertical,
                                                    generalAutoAnalysis:
                                                      e.target.checked,
                                                    rolePlayVisibility:
                                                      e.target.value,
                                                    textInLogo:
                                                      orgConfigs?.textInLogo,
                                                  },
                                                  (teamData) => {
                                                    setCurrentTeam(teamData);
                                                    dispatch({
                                                      type:
                                                        'UPDATE_TEAM_DETAILS',
                                                      payload: {
                                                        id: teamId,
                                                        data: {
                                                          ...teamData,
                                                        },
                                                      },
                                                    });
                                                  },
                                                  () => {},
                                                ),
                                              );
                                            } else {
                                              dispatch(
                                                updateGlobalOrganizationConfig(
                                                  {
                                                    selectedMethodologies:
                                                      methodologies || [],
                                                    botDesignations: designation,
                                                    botVerticals: vertical,
                                                    generalAutoAnalysis:
                                                      e.target.checked,
                                                    rolePlayVisibility:
                                                      e.target.value,
                                                    textInLogo:
                                                      orgConfigs?.textInLogo,
                                                  },
                                                  () => {
                                                    dispatch(
                                                      getOrganizationConfigs(
                                                        () => {},
                                                      ),
                                                    );
                                                  },
                                                ),
                                              );
                                            }
                                          }}
                                          value={rolePlayVisibility}
                                          // multiple={true}
                                        >
                                          <MenuItem value={'self'}>
                                            <Typography variant="subtitle2">
                                              Private
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem value={'team'}>
                                            <Typography variant="subtitle2">
                                              Team Admins
                                            </Typography>
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Box>
                                  ) : null}
                                  <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    gap={1}
                                    alignItems={'start'}
                                    border={'1px solid #d3d3d3'}
                                    borderRadius={2}
                                    p={2}
                                    position={'relative'}
                                    pt={3}
                                    width={1}
                                    mt={1}
                                  >
                                    <Box
                                      // width={1}
                                      display={'flex'}
                                      alignItems={'center'}
                                      gap={2}
                                      justifyContent={'space-between'}
                                      position={'absolute'}
                                      sx={{
                                        top: -16,
                                        background: '#fff',
                                        padding: 1,
                                        left: 10,
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        fontWeight={'bold'}
                                      >
                                        <span>
                                          Manage Customer Account Labels
                                        </span>
                                      </Typography>
                                    </Box>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      color="primary"
                                      sx={{
                                        position: 'absolute',
                                        top: 5,
                                        right: 5,
                                      }}
                                      onClick={() => {
                                        setShowSyncHubspotCompanies(true);
                                      }}
                                    >
                                      Import from HubSpot
                                    </Button>
                                    <Typography variant="subtitle2">
                                      Classify sales calls by customer account.
                                      Add or update the available account
                                      labels.
                                    </Typography>
                                    {accounts.length > 0 ? (
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          gap: 1,
                                          alignItems: 'center',
                                          flexWrap: 'wrap',
                                        }}
                                      >
                                        <Chip
                                          label={
                                            <Box
                                              display={'flex'}
                                              gap={1}
                                              alignItems={'center'}
                                            >
                                              <IconButton>
                                                <Add
                                                  sx={{ fontSize: '14px' }}
                                                />
                                              </IconButton>
                                              <Typography variant="subtitle2">
                                                Add new
                                              </Typography>
                                            </Box>
                                          }
                                          onClick={() => {
                                            setShowAddUpdateAccountDialog(
                                              'create',
                                            );
                                          }}
                                        />
                                        {accounts.map((acc, i) => (
                                          <Chip
                                            key={acc?.id}
                                            variant="outlined"
                                            color="primary"
                                            label={
                                              <Box
                                                display={'flex'}
                                                gap={1}
                                                alignItems={'center'}
                                              >
                                                <Typography>
                                                  {acc?.name}
                                                </Typography>
                                                <IconButton
                                                  sx={{
                                                    borderRadius: '4px',
                                                    border: '1px solid #d3d3d3',
                                                    padding: '4px',
                                                  }}
                                                  onClick={() => {
                                                    setShowAddUpdateAccountDialog(
                                                      acc,
                                                    );
                                                  }}
                                                >
                                                  <Edit
                                                    sx={{ fontSize: '14px' }}
                                                  />
                                                </IconButton>
                                                <IconButton
                                                  sx={{
                                                    borderRadius: '4px',
                                                    border: '1px solid #d3d3d3',
                                                    padding: '4px',
                                                  }}
                                                  onClick={() => {
                                                    setShowConfirmDeleteAccountTag(
                                                      acc,
                                                    );
                                                  }}
                                                >
                                                  <Delete
                                                    sx={{ fontSize: '14px' }}
                                                  />
                                                </IconButton>
                                              </Box>
                                            }
                                          />
                                        ))}
                                      </Box>
                                    ) : (
                                      <Chip
                                        label={
                                          <Box
                                            display={'flex'}
                                            gap={1}
                                            alignItems={'center'}
                                          >
                                            <IconButton>
                                              <Add sx={{ fontSize: '14px' }} />
                                            </IconButton>
                                            <Typography variant="subtitle2">
                                              Add new
                                            </Typography>
                                          </Box>
                                        }
                                        onClick={() => {
                                          setShowAddUpdateAccountDialog(
                                            'create',
                                          );
                                        }}
                                      />
                                    )}
                                  </Box>
                                </>
                              ) : null}
                            </>
                          ) : null}
                        </Box>
                      </>
                    ) : null}
                    {!defaultTab ||
                    (defaultTab === 'playbook' && page === 'teamsettings') ? (
                      <>
                        <Box
                          width={1}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <Typography
                            variant="subtitle2"
                            color={'text.secondary'}
                          >
                            Your Playbooks
                          </Typography>
                          {userDetails?.custom?.orgType === 'demo_org' &&
                          selectedPlaybook ? null : (
                            <Box display={'flex'} alignItems={'center'} gap={1}>
                              <Button
                                size="small"
                                variant="contained"
                                onClick={() =>
                                  setShowGeneratePlaybookWithAI(true)
                                }
                                color="primary"
                              >
                                Generate Playbook
                              </Button>
                            </Box>
                          )}
                        </Box>
                        {fetchingPlaybooks ? (
                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              padding: 1,
                              textAlign: 'center',
                            }}
                          >
                            <CircularProgress size={20} />
                            <Typography color="textPrimary">
                              Fetching all playbooks ...{' '}
                            </Typography>
                          </Box>
                        ) : (playbooks && playbooks?.length > 0) ||
                          selectedPlaybook ? (
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: 750 }}
                              stickyHeader
                              aria-label="simple table"
                            >
                              <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                                <TableRow>
                                  <TableCell align="center">
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{ textTransform: 'uppercase' }}
                                    >
                                      Date
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{ textTransform: 'uppercase' }}
                                    >
                                      Title
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{ textTransform: 'uppercase' }}
                                    >
                                      Team
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{ textTransform: 'uppercase' }}
                                      align="center"
                                    >
                                      Actions
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {selectedPlaybook && (
                                  <>
                                    <TableRow>
                                      <TableCell align="center">
                                        <Typography variant={'caption'}>
                                          {moment(
                                            selectedPlaybook?.createdOn,
                                          ).format('DD MMMM YYYY')}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        align="center"
                                      >
                                        <Typography variant={'caption'}>
                                          {selectedPlaybook?.tags?.join(', ')}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        align="center"
                                      >
                                        <Typography variant={'caption'}>
                                          {teams?.find(
                                            (team) =>
                                              team?.id ===
                                              selectedPlaybook?.teamId,
                                          )?.name || '--'}
                                        </Typography>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Box
                                          display={'flex'}
                                          alignItems={'center'}
                                          justifyContent={'center'}
                                        >
                                          <Tooltip
                                            arrow
                                            placement="top"
                                            title={
                                              'default playbook for analysing conversations'
                                            }
                                          >
                                            <Chip
                                              label={'default'}
                                              color="primary"
                                              size="small"
                                            />
                                          </Tooltip>
                                          <Tooltip
                                            arrow
                                            placement="top"
                                            title={'Edit Playbook'}
                                          >
                                            <IconButton
                                              onClick={() => {
                                                setShowEditPlaybookDrawer(
                                                  selectedPlaybook?.id,
                                                );
                                              }}
                                            >
                                              <Edit
                                                sx={{
                                                  fontSize: 20,
                                                  color:
                                                    theme.palette.text
                                                      .secondary,
                                                }}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                          <Tooltip
                                            title="Delete Playbook"
                                            placement="top"
                                            arrow
                                          >
                                            <IconButton
                                              onClick={(e) => {
                                                handleTrackEvent(
                                                  e,
                                                  'show_playbook',
                                                );
                                                e.stopPropagation();
                                                setShowConfirmDeletePlaybookDialog(
                                                  selectedPlaybook,
                                                );
                                              }}
                                            >
                                              <Delete
                                                sx={{
                                                  fontSize: 20,
                                                  color:
                                                    theme.palette.text
                                                      .secondary,
                                                }}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                          <Tooltip
                                            title="View Playbook"
                                            placement="top"
                                            arrow
                                          >
                                            <IconButton
                                              onClick={(e) => {
                                                handleTrackEvent(
                                                  e,
                                                  'show_playbook',
                                                );
                                                e.stopPropagation();
                                                setShowPlaybook(
                                                  selectedPlaybook,
                                                );
                                              }}
                                            >
                                              <Article
                                                sx={{
                                                  fontSize: 20,
                                                  color:
                                                    theme.palette.text
                                                      .secondary,
                                                }}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                )}
                                {userInfo?.custom?.orgType !== 'demo_org' ? (
                                  <>
                                    {playbooks.map((pb, i) => (
                                      <>
                                        <TableRow
                                          key={i}
                                          // sx={{
                                          //   '&:last-child td, &:last-child th': {
                                          //     border: 0,
                                          //   },
                                          // }}
                                        >
                                          <TableCell align="center">
                                            <Typography variant={'caption'}>
                                              {moment(pb?.createdOn).format(
                                                'DD MMMM YYYY',
                                              )}
                                            </Typography>
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                          >
                                            <Typography variant={'caption'}>
                                              {pb?.tags?.join(', ')}
                                            </Typography>
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                          >
                                            <Typography variant={'caption'}>
                                              {teams?.find(
                                                (team) =>
                                                  team?.id === pb?.teamId,
                                              )?.name || '--'}
                                            </Typography>
                                          </TableCell>
                                          <TableCell align="center">
                                            <Box
                                              display={'flex'}
                                              alignItems={'center'}
                                              justifyContent={'center'}
                                              position={'relative'}
                                            >
                                              <Chip
                                                label="hidden"
                                                color="primary"
                                                sx={{
                                                  visibility: 'hidden',
                                                }}
                                              />
                                              {pathname !== '/settings' ? (
                                                <Tooltip
                                                  arrow
                                                  placement="top"
                                                  title={
                                                    'Make this playbook default playbook for analysing conversations'
                                                  }
                                                >
                                                  <IconButton
                                                    sx={{
                                                      position: 'absolute',
                                                      top: 0,
                                                      // left: 0,
                                                      marginRight: 8,
                                                    }}
                                                    onClick={(e) => {
                                                      handleTrackEvent(
                                                        e,
                                                        'make_playbook_default',
                                                      );
                                                      // if (!pb?.selected) {
                                                      if (currentTeam) {
                                                        dispatch(
                                                          updatePlaybook(
                                                            teamId ||
                                                              userDetails?.organizationId,
                                                            pb?.id,
                                                            {
                                                              selected: true,
                                                            },
                                                            () => {
                                                              dispatch(
                                                                getPlaybooks(
                                                                  teamId ||
                                                                    null,
                                                                  (data) => {
                                                                    // setFetchingPlaybooks(
                                                                    //   false,
                                                                    // );
                                                                    setPlaybooks(
                                                                      data?.history,
                                                                    );
                                                                    setSelectedPlaybook(
                                                                      data?.selectedPlaybook,
                                                                    );
                                                                  },
                                                                ),
                                                              );
                                                              dispatch(
                                                                showAlert({
                                                                  message:
                                                                    'Playbook updated successfully.',
                                                                  showCross: true,
                                                                  title: null,
                                                                  type:
                                                                    'success',
                                                                  autoHideDuration: 2000,
                                                                  vertical:
                                                                    'top',
                                                                  horizontal:
                                                                    'center',
                                                                }),
                                                              );
                                                            },
                                                          ),
                                                        );
                                                      } else {
                                                        dispatch(
                                                          updatePlaybookHistory(
                                                            pb?.id,
                                                            {
                                                              tags:
                                                                pb?.tags || [],
                                                              playbookStructure:
                                                                pb?.playbookStructure,
                                                              selected: true,
                                                            },
                                                            () => {
                                                              // setFetchingPlaybooks(true);
                                                              dispatch(
                                                                getPlaybooks(
                                                                  teamId ||
                                                                    null,
                                                                  (data) => {
                                                                    // setFetchingPlaybooks(
                                                                    //   false,
                                                                    // );
                                                                    setPlaybooks(
                                                                      data?.history,
                                                                    );
                                                                    setSelectedPlaybook(
                                                                      data?.selectedPlaybook,
                                                                    );
                                                                  },
                                                                ),
                                                              );
                                                              dispatch(
                                                                showAlert({
                                                                  message:
                                                                    'Playbook updated successfully.',
                                                                  showCross: true,
                                                                  title: null,
                                                                  type:
                                                                    'success',
                                                                  autoHideDuration: 2000,
                                                                  vertical:
                                                                    'top',
                                                                  horizontal:
                                                                    'center',
                                                                }),
                                                              );
                                                            },
                                                          ),
                                                        );
                                                      }
                                                      // }
                                                    }}
                                                  >
                                                    <Grading
                                                      sx={{
                                                        color:
                                                          theme.palette.text
                                                            .secondary,
                                                        fontSize: 20,
                                                        fontWeight: 'normal',
                                                      }}
                                                    />
                                                  </IconButton>
                                                </Tooltip>
                                              ) : null}
                                              <Tooltip
                                                arrow
                                                placement="top"
                                                title={'Edit Playbook'}
                                              >
                                                <IconButton
                                                  onClick={() => {
                                                    setShowEditPlaybookDrawer(
                                                      pb?.id,
                                                    );
                                                  }}
                                                >
                                                  <Edit
                                                    sx={{
                                                      fontSize: 20,
                                                      color:
                                                        theme.palette.text
                                                          .secondary,
                                                    }}
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                              <Tooltip
                                                title="Delete Playbook"
                                                placement="top"
                                                arrow
                                              >
                                                <IconButton
                                                  onClick={(e) => {
                                                    handleTrackEvent(
                                                      e,
                                                      'show_playbook',
                                                    );
                                                    e.stopPropagation();
                                                    setShowConfirmDeletePlaybookDialog(
                                                      pb,
                                                    );
                                                  }}
                                                >
                                                  <Delete
                                                    sx={{
                                                      fontSize: 20,
                                                      color:
                                                        theme.palette.text
                                                          .secondary,
                                                    }}
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                              <Tooltip
                                                title="view playbook"
                                                placement="left"
                                                arrow
                                              >
                                                <IconButton
                                                  onClick={(e) => {
                                                    handleTrackEvent(
                                                      e,
                                                      'show_playbook',
                                                    );
                                                    e.stopPropagation();
                                                    setShowPlaybook(pb);
                                                  }}
                                                >
                                                  <Assignment
                                                    sx={{
                                                      fontSize: 20,
                                                      color:
                                                        theme.palette.text
                                                          .secondary,
                                                    }}
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                            </Box>
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    ))}
                                  </>
                                ) : null}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        ) : (
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                          >
                            <Typography
                              variant="subtitle2"
                              color={'text.secondary'}
                            >
                              No playbook found
                            </Typography>
                          </Box>
                        )}
                      </>
                    ) : null}
                    {defaultTab === 'playbook' && onBoardingStep === 1 ? (
                      showManualPlaybookCreation ? (
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          gap={1}
                          alignItems={'start'}
                          border={'1px solid #d3d3d3'}
                          borderRadius={2}
                          // p={2}
                          position={'relative'}
                          width={1}
                        >
                          <Box
                            p={1}
                            sx={{
                              borderBottom: '1px solid #d3d3d3',
                              width: 1,
                            }}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            gap={1}
                          >
                            <Box display={'flex'} alignItems={'center'} gap={1}>
                              <Typography
                                variant="body1"
                                color={'text.secondary'}
                              >
                                Playbook
                              </Typography>
                            </Box>
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => {
                                if (
                                  userDetails?.plan?.trailEndsOn >
                                    new Date().getTime() ||
                                  userDetails?.plan?.currentPaymentStatus ===
                                    'paid'
                                ) {
                                  setShowManualPlaybookCreation(false);
                                } else {
                                  dispatch({
                                    type: 'UPDATE_UI_STATE',
                                    payload: {
                                      key: 'showPlanExpired',
                                      value: true,
                                    },
                                  });
                                }
                              }}
                              color="primary"
                            >
                              Regenerate with AI
                            </Button>
                          </Box>
                          <form onSubmit={formikPlaybook.handleSubmit}>
                            <Box
                              p={2}
                              sx={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                // borderBottom: '1px solid #d3d3d3',
                              }}
                              position={'relative'}
                            >
                              <Box>
                                <Typography
                                  variant="subtitle2"
                                  color={'text.secondary'}
                                  fontWeight={'bold'}
                                  sx={{
                                    marginBottom: 1,
                                  }}
                                >
                                  Playbook Title*
                                </Typography>
                              </Box>
                              <TextField
                                placeholder="Playbook Title*"
                                variant="outlined"
                                name={'tags'}
                                fullWidth
                                size="small"
                                value={tags?.join(',')}
                                onChange={(e) =>
                                  setTags(e.target.value.split(','))
                                }
                                error={playbookError}
                                helperText={playbookError}
                                type={'text'}
                                onBlur={() => {
                                  if (tags && tags.length > 0) {
                                    setPlaybookError('');
                                  } else {
                                    setPlaybookError(
                                      'Playbook title is required',
                                    );
                                  }
                                }}
                              />
                              <Grid container spacing={1} sx={{ marginTop: 1 }}>
                                {Object.keys(defaultPlaybookFields || {})?.map(
                                  (field, index) => (
                                    <Grid item xs={12} key={index}>
                                      <Box
                                        p={1}
                                        borderRadius={1}
                                        display={'flex'}
                                        flexDirection={'column'}
                                        gap={1}
                                      >
                                        <Box
                                          display={'flex'}
                                          alignItems={'center'}
                                          gap={1}
                                          justifyContent={'space-between'}
                                        >
                                          <Typography
                                            variant="subtitle2"
                                            color={'text.secondary'}
                                            fontWeight={'bold'}
                                          >
                                            {
                                              defaultPlaybookFieldsMapping?.[
                                                field
                                              ]?.text
                                            }
                                          </Typography>
                                        </Box>
                                        {field === 'challengesForCustomer' ? (
                                          <>
                                            {defaultPlaybookFields.challengesForCustomer &&
                                              Array.isArray(
                                                defaultPlaybookFields.challengesForCustomer,
                                              ) &&
                                              defaultPlaybookFields
                                                .challengesForCustomer.length >
                                                0 &&
                                              defaultPlaybookFields.challengesForCustomer?.map(
                                                (objAndAns, faqIndex) => {
                                                  return (
                                                    <Box
                                                      key={faqIndex}
                                                      sx={{
                                                        border:
                                                          '1px solid #d3d3d3',
                                                        borderRadius: 2,
                                                        padding: 2,
                                                        marginTop: 1,
                                                        position: 'relative',
                                                      }}
                                                    >
                                                      <Button
                                                        variant="text"
                                                        sx={{
                                                          position: 'absolute',
                                                          top: 0,
                                                          right: 10,
                                                          zIndex: 2,
                                                          background: '#fff',
                                                          '&:hover': {
                                                            background: '#fff',
                                                          },
                                                        }}
                                                        onClick={() => {
                                                          const updatedObjAndAns = defaultPlaybookFields.challengesForCustomer.filter(
                                                            (objAndAns, i) =>
                                                              i !== faqIndex,
                                                          );
                                                          setDefaultPlaybookFields(
                                                            (prev) => {
                                                              return {
                                                                ...prev,
                                                                challengesForCustomer: updatedObjAndAns,
                                                              };
                                                            },
                                                          );
                                                        }}
                                                      >
                                                        Remove
                                                      </Button>
                                                      <TextField
                                                        label={`Challenge ${faqIndex +
                                                          1}`}
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={
                                                          objAndAns?.question
                                                        }
                                                        sx={{ marginTop: 1 }}
                                                        onChange={(e) => {
                                                          const updatedObjAndAns = defaultPlaybookFields.challengesForCustomer.map(
                                                            (objAndAns, i) => {
                                                              if (
                                                                i === faqIndex
                                                              ) {
                                                                return {
                                                                  ...objAndAns,
                                                                  question:
                                                                    e.target
                                                                      .value,
                                                                };
                                                              } else {
                                                                return objAndAns;
                                                              }
                                                            },
                                                          );
                                                          setDefaultPlaybookFields(
                                                            (prev) => {
                                                              return {
                                                                ...prev,
                                                                challengesForCustomer: updatedObjAndAns,
                                                              };
                                                            },
                                                          );
                                                        }}
                                                      />
                                                      <TextField
                                                        size="small"
                                                        label={`Recommended Response`}
                                                        variant="outlined"
                                                        fullWidth
                                                        value={
                                                          objAndAns?.answer
                                                        }
                                                        onChange={(e) => {
                                                          const updatedObjAndAns = defaultPlaybookFields.challengesForCustomer.map(
                                                            (objAndAns, i) => {
                                                              if (
                                                                i === faqIndex
                                                              ) {
                                                                return {
                                                                  ...objAndAns,
                                                                  answer:
                                                                    e.target
                                                                      .value,
                                                                };
                                                              } else {
                                                                return objAndAns;
                                                              }
                                                            },
                                                          );
                                                          setDefaultPlaybookFields(
                                                            (prev) => {
                                                              return {
                                                                ...prev,
                                                                challengesForCustomer: updatedObjAndAns,
                                                              };
                                                            },
                                                          );
                                                        }}
                                                        sx={{ marginTop: 1 }}
                                                        // error={formik.touched.email && Boolean(formik.errors.email)}
                                                        // helperText={formik.touched.email && formik.errors.email}
                                                      />
                                                    </Box>
                                                  );
                                                },
                                              )}
                                            <Button
                                              onClick={() => {
                                                setDefaultPlaybookFields(
                                                  (prev) => {
                                                    return {
                                                      ...prev,
                                                      challengesForCustomer: [
                                                        ...prev.challengesForCustomer,
                                                        {
                                                          question: '',
                                                          answer: '',
                                                        },
                                                      ],
                                                    };
                                                  },
                                                );
                                              }}
                                              sx={{ marginTop: 1 }}
                                              variant="contained"
                                            >
                                              Add Challenge and Recommended
                                              Response
                                            </Button>
                                          </>
                                        ) : field === 'customerQuestions' ? (
                                          <>
                                            {defaultPlaybookFields.customerQuestions &&
                                              Array.isArray(
                                                defaultPlaybookFields.customerQuestions,
                                              ) &&
                                              defaultPlaybookFields
                                                .customerQuestions.length > 0 &&
                                              defaultPlaybookFields.customerQuestions?.map(
                                                (objAndAns, faqIndex) => {
                                                  return (
                                                    <Box
                                                      key={faqIndex}
                                                      sx={{
                                                        border:
                                                          '1px solid #d3d3d3',
                                                        borderRadius: 2,
                                                        padding: 2,
                                                        marginTop: 1,
                                                        position: 'relative',
                                                      }}
                                                    >
                                                      <Button
                                                        variant="text"
                                                        sx={{
                                                          position: 'absolute',
                                                          top: 0,
                                                          right: 10,
                                                          zIndex: 2,
                                                          background: '#fff',
                                                          '&:hover': {
                                                            background: '#fff',
                                                          },
                                                        }}
                                                        onClick={() => {
                                                          const updatedObjAndAns = defaultPlaybookFields.customerQuestions.filter(
                                                            (objAndAns, i) =>
                                                              i !== faqIndex,
                                                          );
                                                          setDefaultPlaybookFields(
                                                            (prev) => {
                                                              return {
                                                                ...prev,
                                                                customerQuestions: updatedObjAndAns,
                                                              };
                                                            },
                                                          );
                                                        }}
                                                      >
                                                        Remove
                                                      </Button>
                                                      <TextField
                                                        label={`Objection ${faqIndex +
                                                          1}`}
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={
                                                          objAndAns?.question
                                                        }
                                                        sx={{ marginTop: 1 }}
                                                        onChange={(e) => {
                                                          const updatedObjAndAns = defaultPlaybookFields.customerQuestions.map(
                                                            (objAndAns, i) => {
                                                              if (
                                                                i === faqIndex
                                                              ) {
                                                                return {
                                                                  ...objAndAns,
                                                                  question:
                                                                    e.target
                                                                      .value,
                                                                };
                                                              } else {
                                                                return objAndAns;
                                                              }
                                                            },
                                                          );
                                                          setDefaultPlaybookFields(
                                                            (prev) => {
                                                              return {
                                                                ...prev,
                                                                customerQuestions: updatedObjAndAns,
                                                              };
                                                            },
                                                          );
                                                        }}
                                                      />
                                                      <TextField
                                                        size="small"
                                                        label={`Answer`}
                                                        variant="outlined"
                                                        fullWidth
                                                        value={
                                                          objAndAns?.answer
                                                        }
                                                        onChange={(e) => {
                                                          const updatedObjAndAns = defaultPlaybookFields.customerQuestions.map(
                                                            (objAndAns, i) => {
                                                              if (
                                                                i === faqIndex
                                                              ) {
                                                                return {
                                                                  ...objAndAns,
                                                                  answer:
                                                                    e.target
                                                                      .value,
                                                                };
                                                              } else {
                                                                return objAndAns;
                                                              }
                                                            },
                                                          );
                                                          setDefaultPlaybookFields(
                                                            (prev) => {
                                                              return {
                                                                ...prev,
                                                                customerQuestions: updatedObjAndAns,
                                                              };
                                                            },
                                                          );
                                                        }}
                                                        sx={{ marginTop: 1 }}
                                                        // error={formik.touched.email && Boolean(formik.errors.email)}
                                                        // helperText={formik.touched.email && formik.errors.email}
                                                      />
                                                    </Box>
                                                  );
                                                },
                                              )}
                                            <Button
                                              onClick={() => {
                                                setDefaultPlaybookFields(
                                                  (prev) => {
                                                    return {
                                                      ...prev,
                                                      customerQuestions: [
                                                        ...prev.customerQuestions,
                                                        {
                                                          question: '',
                                                          answer: '',
                                                        },
                                                      ],
                                                    };
                                                  },
                                                );
                                              }}
                                              sx={{ marginTop: 1 }}
                                              variant="contained"
                                            >
                                              Add Objection and Answer
                                            </Button>
                                          </>
                                        ) : (
                                          <TextField
                                            placeholder={
                                              defaultPlaybookFieldsMapping?.[
                                                field
                                              ]?.text
                                            }
                                            variant="outlined"
                                            name={field}
                                            fullWidth
                                            size="small"
                                            multiline
                                            rows={5}
                                            value={
                                              defaultPlaybookFields?.[field]
                                            }
                                            onChange={(e) => {
                                              setDefaultPlaybookFields(
                                                (prev) => {
                                                  return {
                                                    ...prev,
                                                    [field]: e.target.value,
                                                  };
                                                },
                                              );
                                            }}
                                            type={'text'}
                                          />
                                        )}
                                      </Box>
                                    </Grid>
                                  ),
                                )}
                                {customPlaybookFields?.map((field, index) => (
                                  <Grid item xs={12} key={index}>
                                    <Box
                                      p={1}
                                      borderRadius={1}
                                      display={'flex'}
                                      flexDirection={'column'}
                                      gap={1}
                                    >
                                      <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        gap={1}
                                        justifyContent={'space-between'}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          color={'text.secondary'}
                                          fontWeight={'bold'}
                                        >
                                          {field?.title}
                                        </Typography>
                                        {/* <Tooltip arrow placement="top" title="Delete Field">
                          <IconButton
                            sx={{
                              borderRadius: '4px',
                              border: '1px solid #d3d3d3',
                              padding: '4px',
                              width: 26,
                              height: 26,
                            }}
                            onClick={() => {
                              setCustomPlaybookFields((prev) =>
                                prev.filter(
                                  (item) => item?.title !== field?.title,
                                ),
                              );
                            }}
                          >
                            <Delete sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip> */}
                                      </Box>
                                      <TextField
                                        placeholder={field?.title}
                                        variant="outlined"
                                        name={field}
                                        fullWidth
                                        size="small"
                                        multiline
                                        rows={5}
                                        value={field?.content}
                                        onChange={(e) => {
                                          setCustomPlaybookFields((prev) => {
                                            return prev.map((item) => {
                                              if (
                                                item?.title === field?.title
                                              ) {
                                                return {
                                                  ...item,
                                                  content: e.target.value,
                                                };
                                              } else {
                                                return item;
                                              }
                                            });
                                          });
                                        }}
                                        type={'text'}
                                      />
                                    </Box>
                                  </Grid>
                                ))}
                              </Grid>
                            </Box>
                            <Box
                              p={2}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                              }}
                            >
                              {error && (
                                <Grid item xs={12}>
                                  <Alert severity="error">{error}</Alert>
                                </Grid>
                              )}
                              {playbookError && (
                                <Grid item xs={12}>
                                  <Alert severity="error">
                                    {playbookError}
                                  </Alert>
                                </Grid>
                              )}
                              {info && (
                                <Grid item xs={12}>
                                  <Alert severity="success">{info}</Alert>
                                </Grid>
                              )}
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                gap={1}
                              >
                                <Button
                                  size="small"
                                  variant="outlined"
                                  onClick={() =>
                                    setShowEditPlaybookDrawer(null)
                                  }
                                >
                                  {'Cancel'}
                                </Button>
                                <Button
                                  size="small"
                                  variant="contained"
                                  disabled={false}
                                  type="submit"
                                >
                                  {showEditPlaybookDrawer === 'new' ||
                                  showEditPlaybookDrawer === 'prefill' ||
                                  defaultTab
                                    ? 'Create Playbook'
                                    : 'Update Playbook'}
                                </Button>
                              </Box>
                            </Box>
                          </form>
                        </Box>
                      ) : selectedPlaybook ? (
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 750 }}
                            stickyHeader
                            aria-label="simple table"
                          >
                            <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                              <TableRow>
                                <TableCell align="center">
                                  <Typography
                                    variant={'caption'}
                                    fontWeight={700}
                                    sx={{ textTransform: 'uppercase' }}
                                  >
                                    Date
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    variant={'caption'}
                                    fontWeight={700}
                                    sx={{ textTransform: 'uppercase' }}
                                  >
                                    Title
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    variant={'caption'}
                                    fontWeight={700}
                                    sx={{ textTransform: 'uppercase' }}
                                    align="center"
                                  >
                                    Actions
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {selectedPlaybook && (
                                <>
                                  <TableRow
                                  // sx={{
                                  //   '&:last-child td, &:last-child th': {
                                  //     border: 0,
                                  //   },
                                  // }}
                                  >
                                    <TableCell align="center">
                                      <Typography variant={'caption'}>
                                        {moment(
                                          selectedPlaybook?.createdOn,
                                        ).format('DD MMMM YYYY')}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      align="center"
                                    >
                                      <Typography variant={'caption'}>
                                        {selectedPlaybook?.tags?.join(', ')}
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                      >
                                        <Tooltip
                                          arrow
                                          placement="top"
                                          title={
                                            'default playbook for analysing conversations'
                                          }
                                        >
                                          <Chip
                                            label={'default'}
                                            color="primary"
                                            size="small"
                                          />
                                        </Tooltip>
                                        <Tooltip
                                          arrow
                                          placement="top"
                                          title={'Edit Playbook'}
                                        >
                                          <IconButton
                                            onClick={() => {
                                              setShowEditPlaybookDrawer(
                                                selectedPlaybook?.id,
                                              );
                                            }}
                                          >
                                            <Edit
                                              sx={{
                                                fontSize: 20,
                                                color:
                                                  theme.palette.text.secondary,
                                              }}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                          title="View Playbook"
                                          placement="top"
                                          arrow
                                        >
                                          <IconButton
                                            onClick={(e) => {
                                              handleTrackEvent(
                                                e,
                                                'show_playbook',
                                              );
                                              e.stopPropagation();
                                              setShowPlaybook(selectedPlaybook);
                                            }}
                                          >
                                            <Article
                                              sx={{
                                                fontSize: 20,
                                                color:
                                                  theme.palette.text.secondary,
                                              }}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                </>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          gap={1}
                          alignItems={'start'}
                          border={'1px solid #d3d3d3'}
                          borderRadius={2}
                          // p={2}
                          position={'relative'}
                          width={1}
                        >
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            sx={{
                              // background: '#f1f1f1',
                              paddingLeft: 2,
                              paddingTop: 2,
                              width: 1,
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={
                                {
                                  // fontWeight: 'bold',
                                }
                              }
                            >
                              Generate Playbook with AI
                            </Typography>
                            {/* <Button
                            variant="outlined"
                            size="small"
                            startIcon={<Close />}
                            onClick={() => setShowGeneratePlaybookWithAI(false)}
                          >
                            Close
                          </Button> */}
                          </Box>
                          <PlaybookGeneration
                            asComponent={true}
                            handlePrefillFromGeneratedPlaybook={(
                              generatedPlaybook,
                            ) =>
                              handlePrefillFromGeneratedPlaybook(
                                generatedPlaybook,
                                true,
                              )
                            }
                            setShowGeneratePlaybookWithAI={() => {}}
                            setShowEditPlaybookDrawer={() =>
                              setShowManualPlaybookCreation(true)
                            }
                          />
                        </Box>
                      )
                    ) : null}
                  </Box>
                </Box>
              ) : (
                <Box p={2}>
                  <Typography variant={'body1'} fontWeight={'bold'}>
                    Available Methodologies
                  </Typography>
                  <Typography variant={'body1'}>
                    {methodologies
                      ?.map((mthd) => capitalizeText(mthd))
                      ?.join(', ')}
                  </Typography>
                  <Box width={1} mt={2}>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      gap={2}
                      alignItems={'start'}
                      border={'1px solid #d3d3d3'}
                      borderRadius={2}
                      p={2}
                      position={'relative'}
                      pt={3}
                    >
                      <Box
                        // width={1}
                        display={'flex'}
                        alignItems={'center'}
                        gap={2}
                        justifyContent={'space-between'}
                        position={'absolute'}
                        sx={{
                          top: -16,
                          background: '#fff',
                          padding: 1,
                          left: 10,
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          color={'text.secondary'}
                        >
                          About Your Target (Prospect you will be selling to)
                        </Typography>
                      </Box>
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        gap={1}
                        alignItems={'start'}
                        // ml={2}
                        width={1}
                      >
                        <Box>
                          <Typography
                            variant="subtitle2"
                            // color={'text.secondary'}
                            fontWeight={'bold'}
                          >
                            Your Target&apos;s Vertical (The vertical your
                            prospect belongs to)
                          </Typography>
                        </Box>
                        <Typography>{vertical?.join(', ')}</Typography>
                      </Box>
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        gap={1}
                        alignItems={'start'}
                        // ml={2}
                        width={1}
                      >
                        <Box>
                          <Typography
                            variant="subtitle2"
                            fontWeight={'bold'}
                            // color={'text.secondary'}
                          >
                            Your Target&apos;s Designation (Designation of your
                            prospect)
                          </Typography>
                          <Typography>{designation?.join(', ')}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {(orgConfigs?.showPlaybookToManagersOnly &&
                    (userInfo?.orgRoles?.includes('ORG_ADMIN') ||
                      userInfo?.orgRoles?.includes('WL_ORG_ADMIN'))) ||
                  !orgConfigs?.showPlaybookToManagersOnly ? (
                    <Box
                      border={'1px solid #d3d3d3'}
                      borderRadius={2}
                      mt={3}
                      position={'relative'}
                      paddingTop={2}
                    >
                      <Typography
                        variant={'body1'}
                        // fontWeight={'bold'}
                        sx={{
                          position: 'absolute',
                          top: -20,
                          left: 10,
                          padding: 1,
                          background: '#fff',
                        }}
                      >
                        Your Playbook
                      </Typography>
                      <RichTextEditor
                        value={
                          selectedPlaybook
                            ? selectedPlaybook?.playbookText
                            : playbooks && playbooks?.length > 0
                            ? playbooks?.find((pb) => pb?.selected)
                                ?.playbookText
                            : ''
                        }
                        readOnly
                        onChange={() => {}}
                      />
                    </Box>
                  ) : null}
                </Box>
              )}
            </>
          ) : currentTab === 'requestbot' ? (
            <></>
          ) : currentTab === 'integrations' ? (
            <Box p={2} display={'flex'} flexDirection={'column'} gap={1}>
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Integrations
              </Typography>
              <Box p={2} display={'flex'} gap={2} alignItems={'start'}>
                {Object.keys(userDetails?.plan?.features || {})?.includes(
                  'call_analysis',
                ) ||
                Object.keys(userDetails?.plan?.features || {})?.includes(
                  'call_recording_bot',
                ) ||
                Object.keys(userDetails?.plan?.features || {})?.includes(
                  'call_recording_bot_with_time_pooling',
                ) ? (
                  <>
                    {Object.keys(userDetails?.plan?.features || {})?.includes(
                      'call_analysis',
                    ) &&
                    pathname !== '/integrations' &&
                    pathname !== '/profile-settings' ? (
                      <Card
                        sx={{
                          flex: 1,
                          padding: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          position: 'relative',
                          gap: 2,
                        }}
                      >
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          gap={2}
                        >
                          <img src="/hubspot.svg" width={'100px'} />
                          <Tooltip
                            arrow
                            title={
                              userInfo?.orgRoles?.includes('ORG_ADMIN')
                                ? hubspotIntergration
                                  ? 'Remove HubSpot Integration'
                                  : 'Integrate HubSpot'
                                : 'Only Admin can update this setting'
                            }
                            placement="bottom"
                          >
                            <FormControlLabel
                              sx={{ alignSelf: 'end' }}
                              control={
                                <Switch
                                  checked={hubspotIntergration ? true : false}
                                  disableRipple
                                  disabled={
                                    !userInfo?.orgRoles?.includes('ORG_ADMIN')
                                  }
                                  size="small"
                                  name="active"
                                  className="integrateHubspotSwitch"
                                  onChange={(e, newValue) => {
                                    if (
                                      userInfo?.orgRoles?.includes('ORG_ADMIN')
                                    ) {
                                      if (newValue) {
                                        if (
                                          userDetails?.plan?.trailEndsOn >
                                            new Date().getTime() ||
                                          userDetails?.plan
                                            ?.currentPaymentStatus === 'paid'
                                        ) {
                                          setShowHubspotKeyDialog(true);
                                        } else {
                                          dispatch({
                                            type: 'UPDATE_UI_STATE',
                                            payload: {
                                              key: 'showPlanExpired',
                                              value: true,
                                            },
                                          });
                                        }
                                      } else {
                                        dispatch(
                                          integrateHubspot(
                                            {
                                              hubSpotKey: null,
                                              hubspot: false,
                                            },
                                            'org',
                                            () => {
                                              setShowHubspotKey(false);
                                              setHubspotKey('');
                                              dispatch(
                                                getUserDetails(() => {}),
                                              );
                                            },
                                          ),
                                        );
                                      }
                                    }
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  variant="caption"
                                  color={'text.secondary'}
                                >
                                  {hubspotIntergration ? 'ON' : 'OFF'}
                                </Typography>
                              }
                            />
                          </Tooltip>
                        </Box>
                        {userInfo.orgRoles.includes('ORG_ADMIN') ? (
                          <>
                            {hubspotIntergration ? (
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'start'}
                                gap={1}
                              >
                                <Typography
                                  variant="body1"
                                  color={'text.secondary'}
                                >
                                  API Key
                                </Typography>
                                <Box
                                  display={'flex'}
                                  flexDirection={'row'}
                                  alignItems={'center'}
                                  gap={1}
                                >
                                  <TextField
                                    disabled
                                    variant="outlined"
                                    name={'hubspotKey'}
                                    size="small"
                                    fullWidth
                                    sx={{ minWidth: 400 }}
                                    value={
                                      showHubspotKey
                                        ? hubspotKey
                                        : '******************'
                                    }
                                    type="text"
                                  />
                                  <IconButton
                                    data-trackid={`toggle_key_visibility`}
                                    aria-label="toggle key visibility"
                                    onClick={(e) => {
                                      handleTrackEvent(
                                        e,
                                        'toggle_key_visibility',
                                      );
                                      if (!showHubspotKey) {
                                        dispatch(
                                          getIntegrationKey((key) => {
                                            setHubspotKey(key);
                                            setShowHubspotKey(true);
                                          }),
                                        );
                                      } else {
                                        setShowHubspotKey(false);
                                      }
                                    }}
                                    onMouseDown={(e) => e.preventDefault()}
                                  >
                                    {showHubspotKey ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </Box>
                                {/* {process.env.REACT_APP_TARGET_ENV === 'local' ||
                                process.env.REACT_APP_TARGET_ENV ===
                                  'development' ? ( */}
                                <Button
                                  variant="contained"
                                  size="small"
                                  onClick={() => {
                                    setShowHubspotPropertiesDialog(true);
                                  }}
                                >
                                  See HubSpot Deal Properties
                                </Button>
                                {/* ) : null} */}
                              </Box>
                            ) : (
                              <Box>
                                <Typography color={'text.secondary'}>
                                  <Link
                                    target="_blank"
                                    title="Create HubSpot app"
                                    href="https://developers.hubspot.com/docs/api/private-apps#create-a-private-app"
                                  >
                                    Create your Private HubSpot App
                                  </Link>{' '}
                                  (takes only a few minutes), if you don&apos;t
                                  already have one.
                                </Typography>
                                <Typography color={'text.secondary'} mt={1}>
                                  Then copy over your{' '}
                                  <Link
                                    target="_blank"
                                    title="copy integration key"
                                    href="https://developers.hubspot.com/docs/api/private-apps#make-api-calls-with-your-app-s-access-token"
                                  >
                                    Integration Key
                                  </Link>{' '}
                                  in the field provided.
                                </Typography>
                                <Typography color={'text.secondary'}>
                                  When asked for permissions please specify the
                                  following:
                                </Typography>
                                <ul style={{ marginTop: 10, marginLeft: -20 }}>
                                  <li>
                                    <Typography
                                      variant="body2"
                                      color={'text.secondary'}
                                    >
                                      crm.objects.contacts - Read & Write
                                    </Typography>
                                  </li>
                                  <li>
                                    <Typography
                                      variant="body2"
                                      color={'text.secondary'}
                                    >
                                      crm.objects.owners - Read
                                    </Typography>
                                  </li>
                                  <li>
                                    <Typography
                                      variant="body2"
                                      color={'text.secondary'}
                                    >
                                      crm.objects.deals - Read & Write
                                    </Typography>
                                  </li>
                                  <li>
                                    <Typography
                                      variant="body2"
                                      color={'text.secondary'}
                                    >
                                      crm.schemas.deals - Read
                                    </Typography>
                                  </li>
                                </ul>
                              </Box>
                            )}
                          </>
                        ) : (
                          <Box>
                            <Typography color={'text.secondary'}>
                              Your HubSpot account integration is controlled by
                              your admin. If enabled, you will be able to view /
                              analyze your HubSpot calls on the Analysis
                              &gt;&gt; Analysis History page
                            </Typography>
                          </Box>
                        )}
                        {hubspotIntergration ? (
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'start'}
                            gap={2}
                          >
                            {lastSyncOn ? (
                              <Typography>
                                Last Synced On:{' '}
                                {moment(lastSyncOn).format(
                                  'DD MMMM YYYY hh:mm:ss A',
                                )}
                              </Typography>
                            ) : null}
                            <Tooltip
                              title={
                                userInfo.orgRoles.includes('ORG_ADMIN') ||
                                userDetails?.custom?.hubspotUser?.hubspotEmail
                                  ? ''
                                  : 'Your hubspot email is not linked to this account. Try contacting your team admin to link your hubspot email to this account'
                              }
                              arrow
                              placement="top"
                            >
                              <Button
                                size="small"
                                variant="contained"
                                startIcon={
                                  syncStatus === 'pending' ? (
                                    <CircularProgress size={20} />
                                  ) : null
                                }
                                sx={{
                                  cursor:
                                    userInfo.orgRoles.includes('ORG_ADMIN') ||
                                    userDetails?.custom?.hubspotUser
                                      ?.hubspotEmail
                                      ? 'pointer'
                                      : 'default',
                                  backgroundColor:
                                    userInfo.orgRoles.includes('ORG_ADMIN') ||
                                    userDetails?.custom?.hubspotUser
                                      ?.hubspotEmail
                                      ? 'primary'
                                      : theme.palette.grey[500],
                                  color: '#fff',
                                  '&:hover': {
                                    background:
                                      userInfo.orgRoles.includes('ORG_ADMIN') ||
                                      userDetails?.custom?.hubspotUser
                                        ?.hubspotEmail
                                        ? 'primary'
                                        : theme.palette.grey[500],
                                    color: '#fff',
                                  },
                                }}
                                disabled={syncStatus === 'pending'}
                                onClick={() => {
                                  if (
                                    userDetails?.plan?.trailEndsOn >
                                      new Date().getTime() ||
                                    userDetails?.plan?.currentPaymentStatus ===
                                      'paid'
                                  ) {
                                    if (
                                      userInfo.orgRoles.includes('ORG_ADMIN') ||
                                      userDetails?.custom?.hubspotUser
                                        ?.hubspotEmail
                                    ) {
                                      setSyncStatus('pending');
                                      dispatch(
                                        syncHubspot(
                                          userInfo.orgRoles.includes(
                                            'ORG_ADMIN',
                                          )
                                            ? 'org'
                                            : 'user',
                                          () => {
                                            setTimeout(() => {
                                              dispatch(
                                                getSyncRequestStatus(
                                                  userInfo.orgRoles.includes(
                                                    'ORG_ADMIN',
                                                  )
                                                    ? 'org'
                                                    : 'user',
                                                  () => {},
                                                ),
                                              );
                                            }, 1000);
                                          },
                                        ),
                                      );
                                    }
                                  } else {
                                    dispatch({
                                      type: 'UPDATE_UI_STATE',
                                      payload: {
                                        key: 'showPlanExpired',
                                        value: true,
                                      },
                                    });
                                  }
                                }}
                              >
                                {syncStatus === 'pending'
                                  ? 'Syncing Recorded Calls'
                                  : 'Sync Recorded Calls'}
                              </Button>
                            </Tooltip>
                          </Box>
                        ) : null}
                      </Card>
                    ) : null}
                    {(process.env.REACT_APP_TARGET_ENV === 'local' ||
                      process.env.REACT_APP_TARGET_ENV === 'developemnt') &&
                    Object.keys(userDetails?.plan?.features || {})?.includes(
                      'call_analysis',
                    ) &&
                    pathname !== '/integrations' &&
                    pathname !== '/profile-settings' ? (
                      <Card
                        sx={{
                          flex: 1,
                          padding: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          position: 'relative',
                          gap: 2,
                        }}
                      >
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          gap={2}
                        >
                          <img src="/zoho-crm.svg" width={'100px'} />
                          <Tooltip
                            arrow
                            title={
                              userInfo?.orgRoles?.includes('ORG_ADMIN')
                                ? zohoCrmIntegration
                                  ? 'Remove Zoho CRM Integration'
                                  : 'Integrate Zoho CRM'
                                : 'Only Admin can update this setting'
                            }
                            placement="bottom"
                          >
                            <FormControlLabel
                              sx={{ alignSelf: 'end' }}
                              control={
                                <Switch
                                  checked={zohoCrmIntegration ? true : false}
                                  disableRipple
                                  disabled={
                                    !userInfo?.orgRoles?.includes('ORG_ADMIN')
                                  }
                                  size="small"
                                  name="active"
                                  className="integrateZohoCrmSwitch"
                                  onChange={(e, newValue) => {
                                    if (
                                      userInfo?.orgRoles?.includes('ORG_ADMIN')
                                    ) {
                                      if (newValue) {
                                        if (
                                          userDetails?.plan?.trailEndsOn >
                                            new Date().getTime() ||
                                          userDetails?.plan
                                            ?.currentPaymentStatus === 'paid'
                                        ) {
                                          dispatch(
                                            getZohoCrmOAuthUrl((url) => {
                                              console.log(url);
                                              window.open(url, '_self');
                                            }),
                                          );
                                        } else {
                                          dispatch({
                                            type: 'UPDATE_UI_STATE',
                                            payload: {
                                              key: 'showPlanExpired',
                                              value: true,
                                            },
                                          });
                                        }
                                      } else {
                                        dispatch(
                                          integrateHubspot(
                                            {
                                              zohoCrm: false,
                                            },
                                            'org',
                                            () => {
                                              dispatch(
                                                getUserDetails(() => {}),
                                              );
                                            },
                                          ),
                                        );
                                      }
                                    }
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  variant="caption"
                                  color={'text.secondary'}
                                >
                                  {zohoCrmIntegration ? 'ON' : 'OFF'}
                                </Typography>
                              }
                            />
                          </Tooltip>
                        </Box>
                        {userInfo.orgRoles.includes('ORG_ADMIN') ? (
                          <>
                            {zohoCrmIntegration ? (
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'start'}
                                gap={1}
                              >
                                {/* <Button
                                  variant="contained"
                                  size="small"
                                  onClick={() => {
                                    setShowHubspotPropertiesDialog(true);
                                  }}
                                >
                                  See HubSpot Deal Properties
                                </Button> */}
                                {/* ) : null} */}
                              </Box>
                            ) : (
                              <Box>
                                <Typography color={'text.secondary'}>
                                  Get your meeting recordings automatically
                                  analyzed for insights.
                                </Typography>
                                {/* <Typography color={'text.secondary'} mt={1}>
                                  Then copy over your{' '}
                                  <Link
                                    target="_blank"
                                    title="copy integration key"
                                    href="https://developers.hubspot.com/docs/api/private-apps#make-api-calls-with-your-app-s-access-token"
                                  >
                                    Integration Key
                                  </Link>{' '}
                                  in the field provided.
                                </Typography>
                                <Typography color={'text.secondary'}>
                                  When asked for permissions please specify the
                                  following:
                                </Typography>
                                <ul style={{ marginTop: 10, marginLeft: -20 }}>
                                  <li>
                                    <Typography
                                      variant="body2"
                                      color={'text.secondary'}
                                    >
                                      crm.objects.contacts - Read & Write
                                    </Typography>
                                  </li>
                                  <li>
                                    <Typography
                                      variant="body2"
                                      color={'text.secondary'}
                                    >
                                      crm.objects.owners - Read
                                    </Typography>
                                  </li>
                                  <li>
                                    <Typography
                                      variant="body2"
                                      color={'text.secondary'}
                                    >
                                      crm.objects.deals - Read & Write
                                    </Typography>
                                  </li>
                                  <li>
                                    <Typography
                                      variant="body2"
                                      color={'text.secondary'}
                                    >
                                      crm.schemas.deals - Read
                                    </Typography>
                                  </li>
                                </ul> */}
                              </Box>
                            )}
                          </>
                        ) : (
                          <Box>
                            <Typography color={'text.secondary'}>
                              Your HubSpot account integration is controlled by
                              your admin. If enabled, you will be able to view /
                              analyze your HubSpot calls on the Analysis
                              &gt;&gt; Analysis History page
                            </Typography>
                          </Box>
                        )}
                        {/* {zohoCrmIntegration ? (
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'start'}
                            gap={2}
                          >
                            {lastSyncOn ? (
                              <Typography>
                                Last Synced On:{' '}
                                {moment(lastSyncOn).format(
                                  'DD MMMM YYYY hh:mm:ss A',
                                )}
                              </Typography>
                            ) : null}
                            <Tooltip
                              title={
                                userInfo.orgRoles.includes('ORG_ADMIN') ||
                                userDetails?.custom?.hubspotUser?.hubspotEmail
                                  ? ''
                                  : 'Your hubspot email is not linked to this account. Try contacting your team admin to link your hubspot email to this account'
                              }
                              arrow
                              placement="top"
                            >
                              <Button
                                size="small"
                                variant="contained"
                                startIcon={
                                  syncStatus === 'pending' ? (
                                    <CircularProgress size={20} />
                                  ) : null
                                }
                                sx={{
                                  cursor:
                                    userInfo.orgRoles.includes('ORG_ADMIN') ||
                                    userDetails?.custom?.hubspotUser
                                      ?.hubspotEmail
                                      ? 'pointer'
                                      : 'default',
                                  backgroundColor:
                                    userInfo.orgRoles.includes('ORG_ADMIN') ||
                                    userDetails?.custom?.hubspotUser
                                      ?.hubspotEmail
                                      ? 'primary'
                                      : theme.palette.grey[500],
                                  color: '#fff',
                                  '&:hover': {
                                    background:
                                      userInfo.orgRoles.includes('ORG_ADMIN') ||
                                      userDetails?.custom?.hubspotUser
                                        ?.hubspotEmail
                                        ? 'primary'
                                        : theme.palette.grey[500],
                                    color: '#fff',
                                  },
                                }}
                                disabled={syncStatus === 'pending'}
                                onClick={() => {
                                  if (
                                    userDetails?.plan?.trailEndsOn >
                                      new Date().getTime() ||
                                    userDetails?.plan?.currentPaymentStatus ===
                                      'paid'
                                  ) {
                                    if (
                                      userInfo.orgRoles.includes('ORG_ADMIN') ||
                                      userDetails?.custom?.hubspotUser
                                        ?.hubspotEmail
                                    ) {
                                      setSyncStatus('pending');
                                      dispatch(
                                        syncHubspot(
                                          userInfo.orgRoles.includes(
                                            'ORG_ADMIN',
                                          )
                                            ? 'org'
                                            : 'user',
                                          () => {
                                            setTimeout(() => {
                                              dispatch(
                                                getSyncRequestStatus(
                                                  userInfo.orgRoles.includes(
                                                    'ORG_ADMIN',
                                                  )
                                                    ? 'org'
                                                    : 'user',
                                                  () => {},
                                                ),
                                              );
                                            }, 1000);
                                          },
                                        ),
                                      );
                                    }
                                  } else {
                                    dispatch({
                                      type: 'UPDATE_UI_STATE',
                                      payload: {
                                        key: 'showPlanExpired',
                                        value: true,
                                      },
                                    });
                                  }
                                }}
                              >
                                {syncStatus === 'pending'
                                  ? 'Syncing Recorded Calls'
                                  : 'Sync Recorded Calls'}
                              </Button>
                            </Tooltip>
                          </Box>
                        ) : null} */}
                      </Card>
                    ) : null}
                    {pathname === '/integrations' ||
                    pathname === '/profile-settings' ? (
                      <>
                        <Card
                          sx={{
                            flex: 1,
                            padding: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            position: 'relative',
                          }}
                        >
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            gap={2}
                          >
                            <img src="/google_calendar.png" width={'140px'} />
                            <FormControlLabel
                              sx={{
                                alignSelf: 'end',
                              }}
                              control={
                                <Tooltip
                                  arrow
                                  title={
                                    googleIntegration
                                      ? 'Remove Google Integration'
                                      : 'Integrate Google Calendar'
                                  }
                                  placement="bottom"
                                >
                                  <Switch
                                    checked={googleIntegration ? true : false}
                                    disableRipple
                                    size="small"
                                    name="active"
                                    className="integrateGoogleSwitch"
                                    onChange={(e, newValue) => {
                                      console.log(newValue);
                                      if (newValue) {
                                        if (
                                          userDetails?.plan?.trailEndsOn >
                                            new Date().getTime() ||
                                          userDetails?.plan
                                            ?.currentPaymentStatus === 'paid'
                                        ) {
                                          if (
                                            Object.keys(
                                              userDetails?.plan?.features || {},
                                            )?.includes('call_recording_bot') ||
                                            Object.keys(
                                              userDetails?.plan?.features || {},
                                            )?.includes(
                                              'call_recording_bot_with_time_pooling',
                                            )
                                          ) {
                                            setShowDisclosure(true);
                                          } else {
                                            dispatch({
                                              type: 'UPDATE_UI_STATE',
                                              payload: {
                                                key: 'showFeatureRestricted',
                                                value: true,
                                              },
                                            });
                                          }
                                        } else {
                                          dispatch({
                                            type: 'UPDATE_UI_STATE',
                                            payload: {
                                              key: 'showPlanExpired',
                                              value: true,
                                            },
                                          });
                                        }
                                      } else {
                                        setIntegratingGoogle(true);
                                        dispatch(
                                          integrateHubspot(
                                            {
                                              calendarIntegration: false,
                                            },
                                            'user',
                                            () => {
                                              setIntegratingGoogle(false);
                                              dispatch(
                                                getUserDetails(() => {}),
                                              );
                                              dispatch(
                                                showAlert({
                                                  message:
                                                    'Google Integration is removed successfully.',
                                                  showCross: true,
                                                  title: null,
                                                  type: 'success',
                                                  autoHideDuration: 2000,
                                                  vertical: 'top',
                                                  horizontal: 'center',
                                                }),
                                              );
                                            },
                                            () => {
                                              setIntegratingGoogle(false);
                                              dispatch(
                                                showAlert({
                                                  message:
                                                    'Failed to remove google integration. Please try again later!',
                                                  showCross: true,
                                                  title: null,
                                                  type: 'success',
                                                  autoHideDuration: 2000,
                                                  vertical: 'top',
                                                  horizontal: 'center',
                                                }),
                                              );
                                            },
                                          ),
                                        );
                                      }
                                    }}
                                  />
                                </Tooltip>
                              }
                              label={
                                <Typography
                                  variant="caption"
                                  color={'text.secondary'}
                                >
                                  {googleIntegration ? 'ON' : 'OFF'}
                                </Typography>
                              }
                            />
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography
                              variant="body1"
                              color={'text.secondary'}
                            >
                              Auto analyze synced calls
                            </Typography>
                            <FormControlLabel
                              sx={{
                                alignSelf: 'end',
                              }}
                              control={
                                <Tooltip
                                  arrow
                                  title={
                                    calendarAutoAnalysis
                                      ? 'Turn off automatically analyze synced calls'
                                      : 'Turn on automatically analyze synced calls'
                                  }
                                  placement="bottom"
                                >
                                  <Switch
                                    checked={
                                      calendarAutoAnalysis ? true : false
                                    }
                                    disableRipple
                                    disabled={googleIntegration ? false : true}
                                    size="small"
                                    name="active"
                                    className="calendarAutoAnalysisSwitch"
                                    onChange={(e, newValue) => {
                                      console.log(newValue);
                                      setIntegratingGoogle(true);
                                      dispatch(
                                        integrateHubspot(
                                          {
                                            calendarAutoAnalysis: newValue,
                                            calendarAutoSync,
                                          },
                                          'user',
                                          () => {
                                            setIntegratingGoogle(false);
                                            dispatch(getUserDetails(() => {}));
                                            dispatch(
                                              showAlert({
                                                message:
                                                  'Google autosync updated successfully.',
                                                showCross: true,
                                                title: null,
                                                type: 'success',
                                                autoHideDuration: 2000,
                                                vertical: 'top',
                                                horizontal: 'center',
                                              }),
                                            );
                                          },
                                          () => {
                                            setIntegratingGoogle(false);
                                            dispatch(
                                              showAlert({
                                                message:
                                                  'Failed to update autosync. Please try again later!',
                                                showCross: true,
                                                title: null,
                                                type: 'success',
                                                autoHideDuration: 2000,
                                                vertical: 'top',
                                                horizontal: 'center',
                                              }),
                                            );
                                          },
                                        ),
                                      );
                                    }}
                                  />
                                </Tooltip>
                              }
                              label={
                                <Typography
                                  variant="caption"
                                  color={'text.secondary'}
                                >
                                  {calendarAutoAnalysis ? 'ON' : 'OFF'}
                                </Typography>
                              }
                            />
                          </Box>
                          <Box>
                            <Typography color={'text.secondary'}>
                              Get your meeting recordings automatically analyzed
                              for insights.
                            </Typography>
                            {/* <Typography
                      color={'text.secondary'}
                      mt={1}
                      sx={{ visibility: 'hidden' }}
                    >
                      Then copy over your{' '}
                      <Link
                        target="_blank"
                        title="copy integration key"
                        href="https://developers.hubspot.com/docs/api/private-apps#make-api-calls-with-your-app-s-access-token"
                      >
                        Integration Key
                      </Link>{' '}
                      in the field provided.
                    </Typography> */}
                            {/* <Typography>Bot didn&apos;t join?</Typography> */}
                            {googleMeetIntegration ? (
                              <Button
                                sx={{
                                  mt: 2,
                                }}
                                variant="contained"
                                size="small"
                                onClick={() => {
                                  setShowManuallyBotJoinTrigger(true);
                                }}
                              >
                                Send Bot
                              </Button>
                            ) : null}
                            {showBotJoinStatus ? (
                              <>
                                <br />
                                <br />
                                <Typography
                                  variant="caption"
                                  color={theme.palette.success.main}
                                >
                                  {showBotJoinStatus}
                                </Typography>
                              </>
                            ) : null}
                          </Box>
                        </Card>
                        {process.env.REACT_APP_TARGET_ENV === 'development' ||
                          (process.env.REACT_APP_TARGET_ENV === 'local' && (
                            <Card
                              sx={{
                                flex: 1,
                                padding: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                position: 'relative',
                              }}
                            >
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                gap={2}
                              >
                                <img src="/zoom_logo.svg" width={'80px'} />
                                <FormControlLabel
                                  sx={{
                                    alignSelf: 'end',
                                  }}
                                  control={
                                    <Tooltip
                                      arrow
                                      title={
                                        zoomIntegration
                                          ? 'Remove Zoom Integration'
                                          : 'Integrate Zoom Calendar'
                                      }
                                      placement="bottom"
                                    >
                                      <Switch
                                        checked={zoomIntegration ? true : false}
                                        disableRipple
                                        size="small"
                                        name="active"
                                        className="integrateZoomSwitch"
                                        onChange={(e, newValue) => {
                                          console.log(newValue);
                                          if (newValue) {
                                            if (
                                              userDetails?.plan?.trailEndsOn >
                                                new Date().getTime() ||
                                              userDetails?.plan
                                                ?.currentPaymentStatus ===
                                                'paid'
                                            ) {
                                              if (
                                                Object.keys(
                                                  userDetails?.plan?.features ||
                                                    {},
                                                )?.includes(
                                                  'call_recording_bot',
                                                ) ||
                                                Object.keys(
                                                  userDetails?.plan?.features ||
                                                    {},
                                                )?.includes(
                                                  'call_recording_bot_with_time_pooling',
                                                )
                                              ) {
                                                setIntegratingZoom(true);
                                                dispatch(
                                                  integrateHubspot(
                                                    {
                                                      googleMeetingIntegration: false,
                                                      zoomMeetingIntegration: true,
                                                    },
                                                    'user',
                                                    () => {
                                                      setIntegratingZoom(false);
                                                      dispatch(
                                                        getUserDetails(
                                                          () => {},
                                                        ),
                                                      );
                                                      dispatch(
                                                        showAlert({
                                                          message:
                                                            'Zoom Integration is successfully done.',
                                                          showCross: true,
                                                          title: null,
                                                          type: 'success',
                                                          autoHideDuration: 2000,
                                                          vertical: 'top',
                                                          horizontal: 'center',
                                                        }),
                                                      );
                                                    },
                                                    () => {
                                                      setIntegratingZoom(false);
                                                      dispatch(
                                                        showAlert({
                                                          message:
                                                            'Failed to add zoom integration. Please try again later!',
                                                          showCross: true,
                                                          title: null,
                                                          type: 'success',
                                                          autoHideDuration: 2000,
                                                          vertical: 'top',
                                                          horizontal: 'center',
                                                        }),
                                                      );
                                                    },
                                                  ),
                                                );
                                              } else {
                                                dispatch({
                                                  type: 'UPDATE_UI_STATE',
                                                  payload: {
                                                    key:
                                                      'showFeatureRestricted',
                                                    value: true,
                                                  },
                                                });
                                              }
                                            } else {
                                              dispatch({
                                                type: 'UPDATE_UI_STATE',
                                                payload: {
                                                  key: 'showPlanExpired',
                                                  value: true,
                                                },
                                              });
                                            }
                                          } else {
                                            setIntegratingZoom(true);
                                            dispatch(
                                              integrateHubspot(
                                                {
                                                  googleMeetingIntegration: true,
                                                  zoomMeetingIntegration: false,
                                                },
                                                'user',
                                                () => {
                                                  setIntegratingZoom(false);
                                                  dispatch(
                                                    getUserDetails(() => {}),
                                                  );
                                                  dispatch(
                                                    showAlert({
                                                      message:
                                                        'Zoom Integration is removed successfully.',
                                                      showCross: true,
                                                      title: null,
                                                      type: 'success',
                                                      autoHideDuration: 2000,
                                                      vertical: 'top',
                                                      horizontal: 'center',
                                                    }),
                                                  );
                                                },
                                                () => {
                                                  setIntegratingZoom(false);
                                                  dispatch(
                                                    showAlert({
                                                      message:
                                                        'Failed to remove zoom integration. Please try again later!',
                                                      showCross: true,
                                                      title: null,
                                                      type: 'success',
                                                      autoHideDuration: 2000,
                                                      vertical: 'top',
                                                      horizontal: 'center',
                                                    }),
                                                  );
                                                },
                                              ),
                                            );
                                          }
                                        }}
                                      />
                                    </Tooltip>
                                  }
                                  label={
                                    <Typography
                                      variant="caption"
                                      color={'text.secondary'}
                                    >
                                      {zoomIntegration ? 'ON' : 'OFF'}
                                    </Typography>
                                  }
                                />
                              </Box>
                              <Box>
                                <Typography color={'text.secondary'}>
                                  Get your zoom meeting recordings automatically
                                  analyzed for insights.
                                  <br />
                                  <br /> Note: Integrate google calendar to get
                                  your meetings automatically analysed.
                                </Typography>
                                {zoomIntegration ? (
                                  <Button
                                    sx={{
                                      mt: 2,
                                    }}
                                    variant="contained"
                                    size="small"
                                    onClick={() => {
                                      setShowManuallyBotJoinTrigger(true);
                                    }}
                                  >
                                    Send Bot
                                  </Button>
                                ) : null}
                                {showBotJoinStatus ? (
                                  <>
                                    <br />
                                    <br />
                                    <Typography
                                      variant="caption"
                                      color={theme.palette.success.main}
                                    >
                                      {showBotJoinStatus}
                                    </Typography>
                                  </>
                                ) : null}
                              </Box>
                            </Card>
                          ))}
                      </>
                    ) : null}
                  </>
                ) : (
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Typography color={'text.secondary'}>
                      No Integration Available
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          ) : currentTab === 'donna-presets' ? (
            <Box p={2} display={'flex'} flexDirection={'column'} gap={1}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={1}
                position={'relative'}
              >
                <Typography
                  variant="h6"
                  color={'text.secondary'}
                  fontWeight={'bold'}
                >
                  {orgConfigs?.navBarTitles?.['Clients Dashboard']?.subTitles?.[
                    'Ask Donna'
                  ]?.title || 'Ask Donna'}
                </Typography>
                <Typography
                  variant="body1"
                  color={'text.secondary'}
                  // fontWeight={'bold'}
                >
                  {orgConfigs?.navBarTitles?.['Clients Dashboard']?.subTitles?.[
                    'Ask Donna'
                  ]?.title || 'Ask Donna'}{' '}
                  is your AI assistant. You can ask any question related to the
                  call that has been analyzed.
                </Typography>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Box>
                    <Typography
                      variant="body1"
                      color={'text.secondary'}
                      fontWeight={'bold'}
                    >
                      Preset Questions
                    </Typography>
                    <Typography
                      variant="body2"
                      color={'text.secondary'}
                      // fontWeight={'bold'}
                    >
                      Preset questions that will be answered for each call
                    </Typography>
                  </Box>
                  <Button
                    startIcon={<Add />}
                    variant="contained"
                    size="small"
                    sx={{
                      padding: '4px 8px',
                    }}
                    onClick={() => setShowQuestionDialog('create')}
                  >
                    Add new question
                  </Button>
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  gap={1}
                  sx={{
                    border:
                      orgConfigs?.orgPresetQuestions &&
                      orgConfigs?.orgPresetQuestions?.length === 0
                        ? '1px solid #d3d3d3'
                        : 'none',
                    borderRadius: 1,
                    padding:
                      orgConfigs?.orgPresetQuestions &&
                      orgConfigs?.orgPresetQuestions?.length === 0
                        ? 2
                        : 0,
                  }}
                >
                  {orgConfigs?.orgPresetQuestions &&
                  orgConfigs?.orgPresetQuestions?.length > 0 ? (
                    orgConfigs?.orgPresetQuestions?.map((q, index) => (
                      <Box
                        sx={{
                          border: '1px solid #d3d3d3',
                          borderRadius: 1,
                          padding: 1,
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                        key={index}
                      >
                        <Typography>
                          {index + 1}). {q}
                        </Typography>
                        <IconButton
                          onClick={(e) => {
                            setAnchorOptionsEl(e.currentTarget);
                            setCurrentMenuIndex(index);
                          }}
                          sx={{
                            padding: '4px',
                          }}
                        >
                          <MoreVert
                            sx={{
                              fontSize: 20,
                            }}
                          />
                        </IconButton>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorOptionsEl}
                          keepMounted
                          open={
                            Boolean(anchorOptionsEl) &&
                            index === currentMenuIndex
                          }
                          onClose={() => setAnchorOptionsEl(null)}
                          sx={{
                            '& .MuiMenu-paper': {
                              boxShadow:
                                '0 2px 2px 2px rgba(140, 152, 164, 0.2)',
                            },
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              setShowQuestionDialog(q);
                              setAnchorOptionsEl(null);
                            }}
                          >
                            Update Question
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setShowConfirmDeleteQuestionDialog(q);
                              setAnchorOptionsEl(null);
                            }}
                          >
                            Delete Question
                          </MenuItem>
                        </Menu>
                      </Box>
                    ))
                  ) : (
                    <Box>
                      <Typography
                        variant="body2"
                        color={'text.secondary'}
                        // fontWeight={'bold'}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          minHeight: 150,
                        }}
                      >
                        You can add your own preset questions.
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          ) : null}
        </Box>
      </Container>
      <Drawer
        open={Boolean(showRequestBotForm)}
        anchor="right"
        onClose={(e) => {
          handleTrackEvent(e, 'hide_request_bot_form');
          setShowRequestBotForm(null);
        }}
      >
        <Box
          p={1}
          sx={{
            borderBottom: '1px solid #d3d3d3',
          }}
          display={'flex'}
          alignItems={'center'}
        >
          <IconButton
            onClick={(e) => {
              handleTrackEvent(e, 'hide_request_bot_form');
              setShowRequestBotForm(false);
            }}
          >
            <ChevronRight sx={{}} />
          </IconButton>
          <Typography variant="body1" color={'text.secondary'}>
            Request bot
          </Typography>
        </Box>
        <Box
          p={2}
          sx={{
            minWidth: 500,
            maxWidth: 500,
            minHeight: 'calc(100vh - 60px)',
            maxHeight: 'calc(100vh - 60px)',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: 2,
              // outline: '1px solid slategrey',
            },
            flex: 1,
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={1}
            alignItems={'start'}
            width={1}
          >
            <Typography variant="subtitle2" color={'text.secondary'}>
              Buyer (Bot) Name*
            </Typography>
            <TextField
              placeholder="Name the bot [eg. Mark, Sara] *"
              variant="outlined"
              name={'botTitle'}
              // fullWidth
              // multiline
              // rows={3}
              size="small"
              sx={{
                width: '100%',
              }}
              value={botTitle}
              onChange={(e) => setBotTitle(e.target.value)}
              type="text"
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={1}
            alignItems={'start'}
            width={1}
          >
            <Typography variant="subtitle2" color={'text.secondary'}>
              Do you sell to a specific Vertical?
            </Typography>
            <TextField
              placeholder="Name the verticals.[eg. HealthTech, EdTech, etc]"
              variant="outlined"
              name={'botVertical'}
              multiline
              rows={3}
              // fullWidth
              size="small"
              sx={{
                width: '100%',
              }}
              value={botVertical}
              onChange={(e) => setBotVertical(e.target.value)}
              type="text"
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={1}
            alignItems={'start'}
            width={1}
          >
            <Typography variant="subtitle2" color={'text.secondary'}>
              Do you sell to a specific designation?
            </Typography>
            <TextField
              placeholder="Name the designation [eg. VP sales, HR manager, CEO]"
              variant="outlined"
              name={'botDesignation'}
              // fullWidth
              multiline
              rows={3}
              size="small"
              sx={{
                width: '100%',
              }}
              value={botDesignation}
              onChange={(e) => setBotDesignation(e.target.value)}
              type="text"
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={1}
            alignItems={'start'}
            width={1}
          >
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                <Typography variant="subtitle2" color={'text.secondary'}>
                  Buyer(Bot) Voice
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={botVoice}
                onChange={(e) => {
                  // handleTrackEvent(e, `selected_bot_change`);
                  setBotVoice(e.target.value);
                }}
              >
                <FormControlLabel
                  value={'MALE'}
                  control={<Radio />}
                  label={'Male'}
                />
                <FormControlLabel
                  value={'FEMALE'}
                  control={<Radio />}
                  label={'Female'}
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={1}
            alignItems={'start'}
            width={1}
          >
            <Typography variant="subtitle2" color={'text.secondary'}>
              Buyer&apos;s (Bot) Role
            </Typography>
            <TextField
              placeholder="Buyer's (Bot) Role"
              variant="outlined"
              name={'botRole'}
              // fullWidth
              // multiline
              // rows={3}
              size="small"
              sx={{
                width: '100%',
              }}
              value={botRole}
              onChange={(e) => setBotRole(e.target.value)}
              type="text"
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={1}
            alignItems={'start'}
            width={1}
          >
            <Typography variant="subtitle2" color={'text.secondary'}>
              Buyer&apos;s (Bot) Company Name
            </Typography>
            <TextField
              placeholder="Buyer's (Bot) Company Name"
              variant="outlined"
              name={'botCompany'}
              // fullWidth
              // multiline
              // rows={3}
              size="small"
              sx={{
                width: '100%',
              }}
              value={botCompany}
              onChange={(e) => setBotCompany(e.target.value)}
              type="text"
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={1}
            alignItems={'start'}
            width={1}
          >
            <Typography variant="subtitle2" color={'text.secondary'}>
              Buyer&apos;s (Bot) company description
            </Typography>
            <TextField
              placeholder="Buyer's (Bot) company description"
              variant="outlined"
              name={'botAboutCompany'}
              // fullWidth
              multiline
              rows={3}
              size="small"
              sx={{
                width: '100%',
              }}
              value={botAboutCompany}
              onChange={(e) => setBotAboutCompany(e.target.value)}
              type="text"
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={1}
            alignItems={'start'}
            width={1}
          >
            <Typography variant="subtitle2" color={'text.secondary'}>
              Number of years of experience of buyer
            </Typography>
            <TextField
              placeholder="Number of years of experience of buyer"
              variant="outlined"
              name={'botExperience'}
              // fullWidth
              // multiline
              // rows={3}
              size="small"
              sx={{
                width: '100%',
              }}
              value={botExperience}
              onChange={(e) => setBotExperience(e.target.value)}
              type="number"
            />
          </Box>

          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={1}
            alignItems={'start'}
            width={1}
          >
            <Typography variant="subtitle2" color={'text.secondary'}>
              Number of employees in the Buyer&apos;s company
            </Typography>
            <TextField
              placeholder="Number of employees in the Buyer's company"
              variant="outlined"
              name={'employeesCount'}
              // fullWidth
              // multiline
              // rows={3}
              size="small"
              sx={{
                width: '100%',
              }}
              value={employeesCount}
              onChange={(e) => setEmployeesCount(e.target.value)}
              type="number"
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={1}
            alignItems={'start'}
            width={1}
          >
            <Typography variant="subtitle2" color={'text.secondary'}>
              What might be the top challenge of the buyer?
            </Typography>
            <TextField
              placeholder="What might be the top challenge of the buyer?"
              variant="outlined"
              name={'topChallenge'}
              // fullWidth
              multiline
              rows={3}
              size="small"
              sx={{
                width: '100%',
              }}
              value={topChallenge}
              onChange={(e) => setTopChallenge(e.target.value)}
              type="text"
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={1}
            alignItems={'start'}
            width={1}
          >
            <Typography variant="subtitle2" color={'text.secondary'}>
              List products that compete with yours (separated by comma) for a
              more realistic bot interaction.
            </Typography>
            <TextField
              placeholder="Name the competitors(comma seperated)"
              variant="outlined"
              name={'topCompetitors'}
              // fullWidth
              multiline
              rows={3}
              size="small"
              sx={{
                width: '100%',
              }}
              value={topCompetitors}
              onChange={(e) => setTopCompetitors(e.target.value)}
              type="text"
            />
          </Box>

          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={1}
            alignItems={'start'}
            width={1}
          >
            <Typography variant="subtitle2" color={'text.secondary'}>
              Any other piece of information
            </Typography>
            <TextField
              placeholder="Any other piece of information"
              variant="outlined"
              name={'otherInfo'}
              // fullWidth
              multiline
              rows={3}
              size="small"
              sx={{
                width: '100%',
              }}
              value={otherInfo}
              onChange={(e) => setOtherInfo(e.target.value)}
              type="text"
            />
          </Box>
          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={!botTitle?.trim()}
            onClick={() => {
              dispatch(
                requestBot(
                  {
                    requirement: '',
                    aboutCompany: botAboutCompany,
                    botName: botTitle,
                    challenges: topChallenge,
                    companyName: botCompany,
                    companySize: employeesCount,
                    competitors: topCompetitors?.split(',').map((cmpt) => {
                      return {
                        name: cmpt,
                        description: '',
                        url: '',
                      };
                    }),
                    designation: botDesignation,
                    domain: botVertical,
                    experience: botExperience,
                    gender: botVoice,
                    newInfo: otherInfo,
                    role: botRole,
                  },
                  () => {
                    setBotDescription('');
                    setCurrentTab('bots');
                    resetFields();
                    dispatch(
                      showAlert({
                        message: 'Bot request is received successfully. ',
                        showCross: true,
                        title: null,
                        type: 'success',
                        autoHideDuration: 2000,
                        vertical: 'top',
                        horizontal: 'center',
                      }),
                    );
                  },
                ),
              );
            }}
          >
            Request New Bot
          </Button>
        </Box>
      </Drawer>
      <Drawer
        open={Boolean(showBotRequest)}
        anchor="right"
        onClose={(e) => {
          handleTrackEvent(e, 'hide_bot_drawer');
          setShowBotRequest(null);
        }}
      >
        <Box
          p={1}
          sx={{
            borderBottom: '1px solid #d3d3d3',
          }}
        >
          <IconButton
            onClick={(e) => {
              handleTrackEvent(e, 'hide_bot_drawer');
              setShowBotRequest(null);
            }}
          >
            <ChevronRight sx={{}} />
          </IconButton>
        </Box>
        <Box
          p={2}
          sx={{
            minWidth: 500,
            maxWidth: 500,
            minHeight: 'calc(100vh - 60px)',
            maxHeight: 'calc(100vh - 60px)',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: 2,
              // outline: '1px solid slategrey',
            },
            flex: 1,
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="body2" color={'text.secondary'}>
            Bot Details
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Do you sell to a specific Vertical?
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.domain}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Do you sell to a specific Designation?
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.designation}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Buyer&apos;s (Bot) Name
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.botName}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Buyer&apos;s (Bot) Voice
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.gender}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Buyer&apos;s (Bot) Role
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.role}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Buyer&apos;s (Bot) Experience (in Years)
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.experience}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Buyer&apos;s (Bot) Company Name
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.companyName}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            About Company
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.aboutCompany}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Company Size
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.companySize}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            What might be the top challenge for buyer?
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.challenges}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Products the compete with yours
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.competitors?.map((cmpt) => cmpt?.name)?.join(',')}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Other information
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.newInfo}
          </Typography>
        </Box>
      </Drawer>
      <Drawer
        open={Boolean(showPlaybook)}
        anchor="right"
        onClose={(e) => {
          handleTrackEvent(e, 'hide_playbook_drawer');
          setShowPlaybook(null);
        }}
      >
        <Box
          p={1}
          sx={{
            borderBottom: '1px solid #d3d3d3',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box display={'flex'} alignItems={'center'}>
            <IconButton
              onClick={(e) => {
                handleTrackEvent(e, 'hide_playbook_drawer');
                setShowPlaybook(null);
              }}
            >
              <ChevronRight sx={{}} />
            </IconButton>
            <Typography variant="body1">
              {showPlaybook?.tags?.join(',')}
            </Typography>
          </Box>
          <Tooltip arrow placement="top" title="Edit Playbook">
            <IconButton
              onClick={() => {
                setShowEditPlaybookDrawer(showPlaybook?.id);
              }}
            >
              <Edit
                sx={{
                  fontSize: 20,
                  color: theme.palette.text.secondary,
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
        <Box
          p={2}
          sx={{
            minWidth: '40vw',
            maxWidth: '40vw',
            minHeight: 'calc(100vh - 60px)',
            maxHeight: 'calc(100vh - 60px)',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: 2,
              // outline: '1px solid slategrey',
            },
            flex: 1,
            gap: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="body2" color={'text.secondary'}>
            Playbook
          </Typography>
          {showPlaybook?.playbook ? (
            <RichTextEditor
              value={showPlaybook?.playbook || ''}
              onChange={() => {}}
              readOnly
            />
          ) : showPlaybook?.playbookStructure ? (
            <>
              {' '}
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Company Name
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                value={showPlaybook?.playbookStructure?.['companyName'] || ''}
                onChange={() => {}}
                // readOnly
              />
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Product Description
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                value={
                  showPlaybook?.playbookStructure?.['productDescription'] || ''
                }
                onChange={() => {}}
                // readOnly
              />
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Product Key Features
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                value={
                  showPlaybook?.playbookStructure?.['productKeyFeatures'] || ''
                }
                onChange={() => {}}
                // readOnly
              />
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Product Secondary Features
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                value={
                  showPlaybook?.playbookStructure?.['secondaryFeatures'] || ''
                }
                onChange={() => {}}
                // readOnly
              />
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Customer Qualification Questions
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                value={
                  showPlaybook?.playbookStructure?.[
                    'customerQualificationQuestions'
                  ] || ''
                }
                onChange={() => {}}
                // readOnly
              />
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Any follow up questions
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                value={
                  showPlaybook?.playbookStructure?.['followUpQuestions'] || ''
                }
                onChange={() => {}}
                style={{
                  whiteSpace: 'pre-wrap',
                }}
                // readOnly
              />
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Customer Objections and Answers
              </Typography>
              {showPlaybook?.playbookStructure?.customerQuestions?.map((qa) => (
                <>
                  <Typography>Question: {qa.question}</Typography>
                  <Typography>Answer: {qa.answer}</Typography>
                </>
              ))}
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Expected Call To Action
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                value={
                  showPlaybook?.playbookStructure?.['expectedCallToAction'] ||
                  ''
                }
                onChange={() => {}}
                // readOnly
              />
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Competing Products and Companies
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                value={
                  showPlaybook?.playbookStructure?.['competingProducts'] || ''
                }
                onChange={() => {}}
                // readOnly
              />
            </>
          ) : null}
        </Box>
      </Drawer>
      <Drawer
        open={Boolean(showEditPlaybookDrawer)}
        anchor="right"
        onClose={(e) => {
          handleTrackEvent(e, 'hide_playbook_edit_drawer');
          setShowEditPlaybookDrawer(null);
        }}
      >
        <Box
          p={1}
          sx={{
            borderBottom: '1px solid #d3d3d3',
          }}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          gap={1}
        >
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <IconButton
              onClick={(e) => {
                handleTrackEvent(e, 'hide_playbook_edit_drawer');
                setShowEditPlaybookDrawer(null);
              }}
            >
              <ChevronRight sx={{}} />
            </IconButton>
            <Typography variant="body1" color={'text.secondary'}>
              Your playbook
            </Typography>
          </Box>
        </Box>
        <form onSubmit={formikPlaybook.handleSubmit}>
          <Box
            p={2}
            sx={{
              minWidth: '50vw',
              maxWidth: '50vw',
              minHeight:
                error || playbookError || info
                  ? 'calc(100vh - 190px)'
                  : 'calc(100vh - 126px)',
              maxHeight:
                error || playbookError || info
                  ? 'calc(100vh - 190px)'
                  : 'calc(100vh - 126px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
                // outline: '1px solid slategrey',
              },
              flex: 1,
              // gap: 2,
              display: 'flex',
              flexDirection: 'column',
              borderBottom: '1px solid #d3d3d3',
            }}
            position={'relative'}
          >
            <Box>
              <Typography
                variant="subtitle2"
                color={'text.secondary'}
                fontWeight={'bold'}
                sx={{
                  marginBottom: 1,
                }}
              >
                Playbook Title*
              </Typography>
            </Box>
            <TextField
              placeholder="Playbook Title*"
              variant="outlined"
              name={'tags'}
              fullWidth
              size="small"
              value={tags?.join(',')}
              onChange={(e) => setTags(e.target.value.split(','))}
              error={playbookError}
              helperText={playbookError}
              type={'text'}
              onBlur={() => {
                if (tags && tags.length > 0) {
                  setPlaybookError('');
                } else {
                  setPlaybookError('Playbook title is required');
                }
              }}
            />
            <Grid container spacing={1} sx={{ marginTop: 1 }}>
              {Object.keys(defaultPlaybookFields || {})?.map((field, index) => (
                <Grid item xs={12} key={index}>
                  <Box
                    p={1}
                    borderRadius={1}
                    display={'flex'}
                    flexDirection={'column'}
                    gap={1}
                  >
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      gap={1}
                      justifyContent={'space-between'}
                    >
                      <Typography
                        variant="subtitle2"
                        color={'text.secondary'}
                        fontWeight={'bold'}
                      >
                        {defaultPlaybookFieldsMapping?.[field]?.text}
                      </Typography>
                    </Box>
                    {field === 'challengesForCustomer' ? (
                      <>
                        {defaultPlaybookFields.challengesForCustomer &&
                          Array.isArray(
                            defaultPlaybookFields.challengesForCustomer,
                          ) &&
                          defaultPlaybookFields.challengesForCustomer.length >
                            0 &&
                          defaultPlaybookFields.challengesForCustomer?.map(
                            (objAndAns, faqIndex) => {
                              return (
                                <Box
                                  key={faqIndex}
                                  sx={{
                                    border: '1px solid #d3d3d3',
                                    borderRadius: 2,
                                    padding: 2,
                                    marginTop: 1,
                                    position: 'relative',
                                  }}
                                >
                                  <Button
                                    variant="text"
                                    sx={{
                                      position: 'absolute',
                                      top: 0,
                                      right: 10,
                                      zIndex: 2,
                                      background: '#fff',
                                      '&:hover': {
                                        background: '#fff',
                                      },
                                    }}
                                    onClick={() => {
                                      const updatedObjAndAns = defaultPlaybookFields.challengesForCustomer.filter(
                                        (objAndAns, i) => i !== faqIndex,
                                      );
                                      setDefaultPlaybookFields((prev) => {
                                        return {
                                          ...prev,
                                          challengesForCustomer: updatedObjAndAns,
                                        };
                                      });
                                    }}
                                  >
                                    Remove
                                  </Button>
                                  <TextField
                                    label={`Challenge ${faqIndex + 1}`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={objAndAns?.question}
                                    sx={{ marginTop: 1 }}
                                    onChange={(e) => {
                                      const updatedObjAndAns = defaultPlaybookFields.challengesForCustomer.map(
                                        (objAndAns, i) => {
                                          if (i === faqIndex) {
                                            return {
                                              ...objAndAns,
                                              question: e.target.value,
                                            };
                                          } else {
                                            return objAndAns;
                                          }
                                        },
                                      );
                                      setDefaultPlaybookFields((prev) => {
                                        return {
                                          ...prev,
                                          challengesForCustomer: updatedObjAndAns,
                                        };
                                      });
                                    }}
                                  />
                                  <TextField
                                    size="small"
                                    label={`Recommended Response`}
                                    variant="outlined"
                                    fullWidth
                                    value={objAndAns?.answer}
                                    onChange={(e) => {
                                      const updatedObjAndAns = defaultPlaybookFields.challengesForCustomer.map(
                                        (objAndAns, i) => {
                                          if (i === faqIndex) {
                                            return {
                                              ...objAndAns,
                                              answer: e.target.value,
                                            };
                                          } else {
                                            return objAndAns;
                                          }
                                        },
                                      );
                                      setDefaultPlaybookFields((prev) => {
                                        return {
                                          ...prev,
                                          challengesForCustomer: updatedObjAndAns,
                                        };
                                      });
                                    }}
                                    sx={{ marginTop: 1 }}
                                    // error={formik.touched.email && Boolean(formik.errors.email)}
                                    // helperText={formik.touched.email && formik.errors.email}
                                  />
                                </Box>
                              );
                            },
                          )}
                        <Button
                          onClick={() => {
                            setDefaultPlaybookFields((prev) => {
                              return {
                                ...prev,
                                challengesForCustomer: [
                                  ...prev.challengesForCustomer,
                                  { question: '', answer: '' },
                                ],
                              };
                            });
                          }}
                          sx={{ marginTop: 1 }}
                          variant="contained"
                        >
                          Add Challenge and Recommended Response
                        </Button>
                      </>
                    ) : field === 'customerQuestions' ? (
                      <>
                        {defaultPlaybookFields.customerQuestions &&
                          Array.isArray(
                            defaultPlaybookFields.customerQuestions,
                          ) &&
                          defaultPlaybookFields.customerQuestions.length > 0 &&
                          defaultPlaybookFields.customerQuestions?.map(
                            (objAndAns, faqIndex) => {
                              return (
                                <Box
                                  key={faqIndex}
                                  sx={{
                                    border: '1px solid #d3d3d3',
                                    borderRadius: 2,
                                    padding: 2,
                                    marginTop: 1,
                                    position: 'relative',
                                  }}
                                >
                                  <Button
                                    variant="text"
                                    sx={{
                                      position: 'absolute',
                                      top: 0,
                                      right: 10,
                                      zIndex: 2,
                                      background: '#fff',
                                      '&:hover': {
                                        background: '#fff',
                                      },
                                    }}
                                    onClick={() => {
                                      const updatedObjAndAns = defaultPlaybookFields.customerQuestions.filter(
                                        (objAndAns, i) => i !== faqIndex,
                                      );
                                      setDefaultPlaybookFields((prev) => {
                                        return {
                                          ...prev,
                                          customerQuestions: updatedObjAndAns,
                                        };
                                      });
                                    }}
                                  >
                                    Remove
                                  </Button>
                                  <TextField
                                    label={`Objection ${faqIndex + 1}`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={objAndAns?.question}
                                    sx={{ marginTop: 1 }}
                                    onChange={(e) => {
                                      const updatedObjAndAns = defaultPlaybookFields.customerQuestions.map(
                                        (objAndAns, i) => {
                                          if (i === faqIndex) {
                                            return {
                                              ...objAndAns,
                                              question: e.target.value,
                                            };
                                          } else {
                                            return objAndAns;
                                          }
                                        },
                                      );
                                      setDefaultPlaybookFields((prev) => {
                                        return {
                                          ...prev,
                                          customerQuestions: updatedObjAndAns,
                                        };
                                      });
                                    }}
                                  />
                                  <TextField
                                    size="small"
                                    label={`Answer`}
                                    variant="outlined"
                                    fullWidth
                                    value={objAndAns?.answer}
                                    onChange={(e) => {
                                      const updatedObjAndAns = defaultPlaybookFields.customerQuestions.map(
                                        (objAndAns, i) => {
                                          if (i === faqIndex) {
                                            return {
                                              ...objAndAns,
                                              answer: e.target.value,
                                            };
                                          } else {
                                            return objAndAns;
                                          }
                                        },
                                      );
                                      setDefaultPlaybookFields((prev) => {
                                        return {
                                          ...prev,
                                          customerQuestions: updatedObjAndAns,
                                        };
                                      });
                                    }}
                                    sx={{ marginTop: 1 }}
                                    // error={formik.touched.email && Boolean(formik.errors.email)}
                                    // helperText={formik.touched.email && formik.errors.email}
                                  />
                                </Box>
                              );
                            },
                          )}
                        <Button
                          onClick={() => {
                            setDefaultPlaybookFields((prev) => {
                              return {
                                ...prev,
                                customerQuestions: [
                                  ...prev.customerQuestions,
                                  { question: '', answer: '' },
                                ],
                              };
                            });
                          }}
                          sx={{ marginTop: 1 }}
                          variant="contained"
                        >
                          Add Objection and Answer
                        </Button>
                      </>
                    ) : (
                      <TextField
                        placeholder={
                          defaultPlaybookFieldsMapping?.[field]?.text
                        }
                        variant="outlined"
                        name={field}
                        fullWidth
                        size="small"
                        multiline
                        rows={5}
                        value={defaultPlaybookFields?.[field]}
                        onChange={(e) => {
                          setDefaultPlaybookFields((prev) => {
                            return {
                              ...prev,
                              [field]: e.target.value,
                            };
                          });
                        }}
                        type={'text'}
                      />
                    )}
                  </Box>
                </Grid>
              ))}
              {customPlaybookFields?.map((field, index) => (
                <Grid item xs={12} key={index}>
                  <Box
                    p={1}
                    borderRadius={1}
                    display={'flex'}
                    flexDirection={'column'}
                    gap={1}
                  >
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      gap={1}
                      justifyContent={'space-between'}
                    >
                      <Typography
                        variant="subtitle2"
                        color={'text.secondary'}
                        fontWeight={'bold'}
                      >
                        {field?.title}
                      </Typography>
                      {/* <Tooltip arrow placement="top" title="Delete Field">
                          <IconButton
                            sx={{
                              borderRadius: '4px',
                              border: '1px solid #d3d3d3',
                              padding: '4px',
                              width: 26,
                              height: 26,
                            }}
                            onClick={() => {
                              setCustomPlaybookFields((prev) =>
                                prev.filter(
                                  (item) => item?.title !== field?.title,
                                ),
                              );
                            }}
                          >
                            <Delete sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip> */}
                    </Box>
                    <TextField
                      placeholder={field?.title}
                      variant="outlined"
                      name={field}
                      fullWidth
                      size="small"
                      multiline
                      rows={5}
                      value={field?.content}
                      onChange={(e) => {
                        setCustomPlaybookFields((prev) => {
                          return prev.map((item) => {
                            if (item?.title === field?.title) {
                              return {
                                ...item,
                                content: e.target.value,
                              };
                            } else {
                              return item;
                            }
                          });
                        });
                      }}
                      type={'text'}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box
            p={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            {error && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {playbookError && (
              <Grid item xs={12}>
                <Alert severity="error">{playbookError}</Alert>
              </Grid>
            )}
            {info && (
              <Grid item xs={12}>
                <Alert severity="success">{info}</Alert>
              </Grid>
            )}
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              gap={1}
            >
              <Button
                size="small"
                variant="outlined"
                onClick={() => setShowEditPlaybookDrawer(null)}
              >
                {'Cancel'}
              </Button>
              <Button
                size="small"
                variant="contained"
                disabled={false}
                type="submit"
              >
                {showEditPlaybookDrawer === 'new' ||
                showEditPlaybookDrawer === 'prefill'
                  ? 'Create Playbook'
                  : 'Update Playbook'}
              </Button>
            </Box>
          </Box>
        </form>
      </Drawer>
      <Modal
        open={Boolean(showHubspotPropertiesDialog)}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown')
            setShowHubspotPropertiesDialog(null);
          else return;
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            // maxHeight: '30vh',
            maxWidth: '70vw',
            minWidth: '50vw',
            maxHeight: '90vh',
            overflowY: 'scroll',
            outline: 'none',
            borderRadius: 1,
            // padding: 3,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              padding: 2,
            }}
          >
            <Typography variant="body1">All HubSpot Properties</Typography>
            <Typography variant="subtitle2" color={'text.secondary'}>
              Select Hubspot deal properties that you want tracked and updated
              based on call analysis.
            </Typography>
            {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="subtitle2" color={'text.secondary'}>
                If you are not able to see some of the properties which are
                recently added on hubspot, please click on sync to pull the
                latest properties here.
              </Typography>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  dispatch(
                    getAllHubspotProperties(true, (data) => {
                      setHubspotDealProperties(data);
                    }),
                  );
                }}
              >
                Sync
              </Button>
            </Box> */}
            {fetchingHubspotProperties ? (
              <Box
                width={1}
                height={1}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
              >
                <CircularProgress size={20} />
                <Typography variant="body1" color={'text.primary'} mt={4}>
                  Loading ...
                </Typography>
              </Box>
            ) : hubspotDealProperties?.length > 0 ? (
              <TableContainer
                component={Paper}
                sx={{
                  minHeight: '60vh',
                  maxHeight: '60vh',
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    width: '5px',
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 2,
                  },
                }}
              >
                <Table
                  sx={{ minWidth: 750 }}
                  stickyHeader
                  aria-label="simple table"
                >
                  <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          borderLeft: '1px solid #f1f1f1',
                        }}
                      >
                        <Checkbox
                          size="small"
                          checked={
                            selectedHubspotProperties.length ===
                            hubspotDealProperties.length
                          }
                          onChange={(e) => {
                            setSelectedHubspotProperties(
                              e.target.checked ? hubspotDealProperties : [],
                            );
                          }}
                          name="selectHubspotProperty"
                        />
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant={'caption'}
                          fontWeight={700}
                          sx={{ textTransform: 'uppercase' }}
                        >
                          Property
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant={'caption'}
                          fontWeight={700}
                          sx={{ textTransform: 'uppercase' }}
                        >
                          Type
                        </Typography>
                      </TableCell>
                      {/* <TableCell align="center">
                        <Typography
                          variant={'caption'}
                          fontWeight={700}
                          sx={{ textTransform: 'uppercase' }}
                          textAlign={'center'}
                          // align="center"
                        >
                          Data Sensitivity
                        </Typography>
                      </TableCell> */}
                      <TableCell>
                        <Typography
                          variant={'caption'}
                          fontWeight={700}
                          sx={{ textTransform: 'uppercase' }}
                        >
                          Description
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedHubspotProperties.map((property, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell
                            align="center"
                            sx={{
                              borderLeft: '1px solid #f1f1f1',
                            }}
                          >
                            <Checkbox
                              size="small"
                              checked={
                                selectedHubspotProperties?.find(
                                  (p) => p?.name === property?.name,
                                ) !== undefined
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedHubspotProperties([
                                    ...selectedHubspotProperties,
                                    property,
                                  ]);
                                } else {
                                  setSelectedHubspotProperties(
                                    selectedHubspotProperties?.filter(
                                      (p) => p?.name !== property?.name,
                                    ),
                                  );
                                }
                              }}
                              name="selectHubspotProperty"
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ minWidth: 150 }}
                          >
                            <Typography variant={'subtitle2'} fontWeight={700}>
                              {property?.label}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant={'caption'}>
                              {property.type}
                            </Typography>
                          </TableCell>
                          {/* <TableCell align="center">
                            <Typography variant={'subtitle2'}>
                              {property?.dataSensitivity}
                            </Typography>
                          </TableCell> */}
                          <TableCell>
                            <Typography variant={'subtitle2'}>
                              {property?.description}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {hubspotDealProperties
                      ?.filter(
                        (property) =>
                          selectedHubspotProperties?.find(
                            (p) => p?.name === property?.name,
                          ) === undefined,
                      )
                      .map((property, i) => {
                        return (
                          <TableRow key={i}>
                            <TableCell
                              align="center"
                              sx={{
                                borderLeft: '1px solid #f1f1f1',
                              }}
                            >
                              <Checkbox
                                size="small"
                                checked={
                                  selectedHubspotProperties?.find(
                                    (p) => p?.name === property?.name,
                                  ) !== undefined
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedHubspotProperties([
                                      ...selectedHubspotProperties,
                                      property,
                                    ]);
                                  } else {
                                    setSelectedHubspotProperties(
                                      selectedHubspotProperties?.filter(
                                        (p) => p?.name !== property?.name,
                                      ),
                                    );
                                  }
                                }}
                                name="selectHubspotProperty"
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ minWidth: 150 }}
                            >
                              <Typography
                                variant={'subtitle2'}
                                fontWeight={700}
                              >
                                {property?.label}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant={'caption'}>
                                {property.type}
                              </Typography>
                            </TableCell>
                            {/* <TableCell align="center">
                            <Typography variant={'subtitle2'}>
                              {property?.dataSensitivity}
                            </Typography>
                          </TableCell> */}
                            <TableCell>
                              <Typography variant={'subtitle2'}>
                                {property?.description}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : missingRequiredScopes && missingRequiredScopes?.length > 0 ? (
              <Box
                width={1}
                height={1}
                display={'flex'}
                justifyContent={'center'}
                // alignItems={'center'}
                flexDirection={'column'}
                gap={2}
              >
                <Typography variant="body1" color={'error'} mt={4}>
                  Failed to fetch hubspot properties. Missing required scopes
                </Typography>
                <ul
                  style={{ marginTop: 10, marginLeft: -20, listStyle: 'none' }}
                >
                  {missingRequiredScopes.map((scope) => (
                    <li key={scope}>
                      <Typography variant="body2" color={'error'}>
                        {scope}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </Box>
            ) : (
              <Box
                width={1}
                height={1}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
                gap={2}
              >
                <Typography variant="body1" color={'text.secondary'} mt={4}>
                  No properties found.
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            p={'8px 16px'}
          >
            <Button
              size="small"
              variant="outlined"
              onClick={(e) => {
                setShowHubspotPropertiesDialog(null);
                setSelectAllProperties(false);
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              disabled={
                updatingHubspotProperties ||
                (missingRequiredScopes && missingRequiredScopes?.length > 0)
              }
              onClick={(e) => {
                setUpdatingHubspotProperties(true);
                dispatch(
                  updateGlobalOrganizationConfig(
                    {
                      hubspotDealProperties: selectedHubspotProperties,
                    },
                    () => {
                      setUpdatingHubspotProperties(false);
                      setShowHubspotPropertiesDialog(null);
                      setSelectAllProperties(false);
                      dispatch(getOrganizationConfigs(() => {}));
                    },
                  ),
                );
              }}
              color="primary"
              // autoFocus
            >
              Update
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={Boolean(showQuestionDialog)}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown')
            setShowQuestionDialog(null);
          else return;
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            // maxHeight: '30vh',
            maxWidth: '50vw',
            minWidth: '40vw',
            outline: 'none',
            borderRadius: 1,
            // padding: 3,
            position: 'relative',
          }}
        >
          {showQuestionDialog === 'create' ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                // padding: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  background: '#f1f1f1',
                  padding: 2,
                }}
              >
                <Typography variant="body1">
                  {showQuestionDialog === 'create' ? 'Add new' : 'Update'}{' '}
                  Question
                  {showQuestionDialog === 'create' ? 's' : ''}
                </Typography>
                <FormControlLabel
                  checked={showSuggestions}
                  control={
                    <Checkbox
                      size="small"
                      value={showSuggestions}
                      name="showSuggestions"
                    />
                  }
                  label={'Show Suggested Questions'}
                  onChange={(e) => {
                    setShowSuggestions(e.target.checked);
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  padding: '8px 16px',
                }}
              >
                {newQuestions && newQuestions.length > 0
                  ? newQuestions.map((q, i) => (
                      <Box
                        key={`question-${i}`}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          // gap: 1,
                          width: '100%',
                        }}
                      >
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <Typography
                            variant="caption"
                            color={'text.secondary'}
                            fontWeight={'bold'}
                          >
                            Question {i + 1}
                          </Typography>
                          <Button
                            variant="text"
                            size="small"
                            sx={{
                              padding: '4px 8px',
                            }}
                            color="primary"
                            onClick={() =>
                              setNewQuestions((prev) => [
                                ...prev.slice(0, i),
                                ...prev.slice(i + 1),
                              ])
                            }
                          >
                            Remove
                          </Button>
                        </Box>
                        {showSuggestions ? (
                          <AutoCompleteMUI
                            id="question"
                            value={q}
                            onChange={(event, newValue) => {
                              setNewQuestions([
                                ...newQuestions.slice(0, i),
                                newValue,
                                ...newQuestions.slice(i + 1),
                              ]);
                            }}
                            options={
                              orgConfigs?.defaultPresetQuestions?.questions?.filter(
                                (q) =>
                                  !orgConfigs?.orgPresetQuestions?.includes(q),
                              ) || []
                            }
                            onTextChange={(e) =>
                              setNewQuestions([
                                ...newQuestions.slice(0, i),
                                e.target.value,
                                ...newQuestions.slice(i + 1),
                              ])
                            }
                            placeholder="Question"
                            onKeyDown={() => {}}
                          />
                        ) : (
                          <TextField
                            placeholder="Question"
                            variant="outlined"
                            name={'question'}
                            size="small"
                            value={q}
                            onChange={(e) => {
                              setNewQuestions([
                                ...newQuestions.slice(0, i),
                                e.target.value,
                                ...newQuestions.slice(i + 1),
                              ]);
                            }}
                            type={'text'}
                          />
                        )}
                      </Box>
                    ))
                  : null}
                <Box display={'flex'} justifyContent={'flex-end'}>
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<Add />}
                    sx={{
                      padding: '4px 8px',
                    }}
                    onClick={() => setNewQuestions((prev) => [...prev, ''])}
                  >
                    Add Question
                  </Button>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                padding: 2,
              }}
            >
              <Typography variant="body1">Update Question</Typography>
              <TextField
                placeholder="Question"
                variant="outlined"
                name={'question'}
                size="small"
                fullWidth
                multiline
                rows={3}
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                type={'text'}
              />
            </Box>
          )}
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            p={'8px 16px'}
          >
            <Button
              size="small"
              variant="outlined"
              onClick={(e) => {
                setShowQuestionDialog(null);
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              disabled={
                (showQuestionDialog === 'create' &&
                  (!newQuestions ||
                    !(
                      newQuestions &&
                      newQuestions.length > 0 &&
                      newQuestions?.filter((nq) => nq?.trim())?.length > 0
                    ))) ||
                (!question?.trim() && showQuestionDialog !== 'create')
              }
              onClick={(e) => {
                if (showQuestionDialog === 'create') {
                  dispatch(
                    updateGlobalOrganizationConfig(
                      {
                        presetQuestions: [
                          ...(orgConfigs?.orgPresetQuestions || []),
                          ...(newQuestions?.filter((nq) => nq?.trim()) || []),
                        ],
                        textInLogo: orgConfigs?.textInLogo,
                        generalAutoAnalysis: orgConfigs?.isGeneralAutoAnalysis,
                      },
                      () => {
                        setShowQuestionDialog(null);
                        setQuestion('');
                        setNewQuestions(['']);
                        dispatch(getOrganizationConfigs(() => {}));
                      },
                    ),
                  );
                } else {
                  dispatch(
                    updateGlobalOrganizationConfig(
                      {
                        presetQuestions: orgConfigs?.orgPresetQuestions.map(
                          (qs) =>
                            qs === showQuestionDialog ? question?.trim() : qs,
                        ),
                        textInLogo: orgConfigs?.textInLogo,
                        generalAutoAnalysis: orgConfigs?.isGeneralAutoAnalysis,
                      },
                      () => {
                        setShowQuestionDialog(null);
                        setQuestion('');
                        dispatch(getOrganizationConfigs(() => {}));
                      },
                    ),
                  );
                }
              }}
              color="primary"
              // autoFocus
            >
              {showQuestionDialog === 'create' ? 'Save' : 'Update'}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Dialog
        open={Boolean(showAddUpdateAccountDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          minWidth: 400,
          // '& .MuiPaper-root-MuiDialog-paper': {
          //   maxWidth: '70vw',
          // },
        }}
      >
        <DialogContent>
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <Typography variant="body1">
              {typeof showAddUpdateAccountDialog === 'string'
                ? 'Add Account Label'
                : 'Update Account Label'}
            </Typography>
            <TextField
              placeholder="Account Label Name"
              variant="outlined"
              name={'accountName'}
              size="small"
              sx={{
                width: '100%',
              }}
              value={accountName}
              onChange={(e) => setAccountName(e.target.value)}
              type={'text'}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Button
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                setShowAddUpdateAccountDialog(null);
              }}
              color="primary"
              // autoFocus
            >
              Cancel
            </Button>
            <Button
              disabled={!accountName?.trim()}
              onClick={(e) => {
                if (typeof showAddUpdateAccountDialog === 'string') {
                  const newID = uuidv4();
                  if (currentTeam) {
                    dispatch(
                      updateTeam(
                        teamId,
                        {
                          accountTagMap: [
                            ...accounts,
                            {
                              id: newID,
                              name: accountName,
                            },
                          ]?.reduce((acc, curr) => {
                            acc[curr?.id] = curr?.name;
                            return acc;
                          }, {}),
                        },
                        (teamData) => {
                          setShowAddUpdateAccountDialog(null);
                          setAccountName('');
                          setCurrentTeam(teamData);
                          dispatch({
                            type: 'UPDATE_TEAM_DETAILS',
                            payload: {
                              id: teamId,
                              data: {
                                ...teamData,
                              },
                            },
                          });
                        },
                        () => {},
                      ),
                    );
                  } else {
                    dispatch(
                      updateGlobalOrganizationConfig(
                        {
                          accountTagMap: [
                            ...accounts,
                            {
                              id: newID,
                              name: accountName,
                            },
                          ]?.reduce((acc, curr) => {
                            acc[curr?.id] = curr?.name;
                            return acc;
                          }, {}),
                        },
                        () => {
                          dispatch(getOrganizationConfigs(() => {}));
                          setShowAddUpdateAccountDialog(null);
                          setAccountName('');
                        },
                      ),
                    );
                  }
                } else {
                  if (currentTeam) {
                    dispatch(
                      updateTeam(
                        teamId,
                        {
                          accountTagMap: accounts
                            ?.map((acc) =>
                              acc?.id === showAddUpdateAccountDialog?.id
                                ? { ...acc, name: accountName }
                                : acc,
                            )
                            ?.reduce((acc, curr) => {
                              acc[curr?.id] = curr?.name;
                              return acc;
                            }, {}),
                        },
                        (teamData) => {
                          setShowAddUpdateAccountDialog(null);
                          setAccountName('');
                          setCurrentTeam(teamData);
                          dispatch({
                            type: 'UPDATE_TEAM_DETAILS',
                            payload: {
                              id: teamId,
                              data: {
                                ...teamData,
                              },
                            },
                          });
                        },
                        () => {},
                      ),
                    );
                  } else {
                    dispatch(
                      updateGlobalOrganizationConfig(
                        {
                          accountTagMap: accounts
                            ?.map((acc) =>
                              acc?.id === showAddUpdateAccountDialog?.id
                                ? { ...acc, name: accountName }
                                : acc,
                            )
                            ?.reduce((acc, curr) => {
                              acc[curr?.id] = curr?.name;
                              return acc;
                            }, {}),
                        },
                        () => {
                          dispatch(getOrganizationConfigs(() => {}));
                          setAccountName('');
                          setShowAddUpdateAccountDialog(null);
                        },
                      ),
                    );
                  }
                }
              }}
              color="primary"
              // autoFocus
            >
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showManuallyBotJoinTrigger)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          minWidth: 400,
          // '& .MuiPaper-root-MuiDialog-paper': {
          //   maxWidth: '70vw',
          // },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="body1">
            Send Recording Bot to a meeting
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={
              {
                // minWidth: 400,
              }
            }
          >
            <Typography variant="body1">
              Enter Meeting URL that you want to be recorded
            </Typography>
            <TextField
              placeholder="Meeting URL"
              variant="outlined"
              name={'meetingURL'}
              size="small"
              fullWidth
              value={meetingLink}
              onChange={(e) => setMeetingLink(e.target.value)}
              type={'text'}
              sx={{ mt: 2 }}
            />
            {showBotJoinStatus === 'failed' ? (
              <Typography variant="caption" color={theme.palette.error.main}>
                Failed to make the bot join your meeting. Please check the
                meeting link you provide or try again.
              </Typography>
            ) : null}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Button
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                setShowManuallyBotJoinTrigger(null);
                setShowBotJoinStatus('');
              }}
              color="primary"
              // autoFocus
            >
              Cancel
            </Button>
            <Button
              disabled={sendingBot || !meetingLink}
              variant="contained"
              onClick={(e) => {
                setSendingBot(true);
                dispatch(
                  manuallyTriggerBotToJoin(
                    {
                      meetingLink,
                    },
                    (rs) => {
                      if (rs) {
                        setSendingBot(false);
                        setShowManuallyBotJoinTrigger(null);
                        setMeetingLink('');
                        setShowBotJoinStatus(
                          'Triggered the bot to join the meeting link you have provided. You will see a bot requesting to join. Please admin it to the meeting to make your recording happen',
                        );
                      } else {
                        setSendingBot(false);
                        // setMeetingLink('');
                        setShowBotJoinStatus('failed');
                      }
                    },
                  ),
                );
              }}
              color="primary"
              // autoFocus
            >
              Send Bot
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showConfirmDeletePlaybookDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          minWidth: 400,
          // '& .MuiPaper-root-MuiDialog-paper': {
          //   maxWidth: '70vw',
          // },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              minWidth: 400,
            }}
          >
            <Typography variant="body1">
              Are you sure you want to delete this playbook?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-end'}
          >
            <Button
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                setShowConfirmDeletePlaybookDialog(null);
              }}
              color="primary"
              // autoFocus
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={(e) => {
                dispatch(
                  deletePlaybook(
                    showConfirmDeletePlaybookDialog?.id,
                    teamId ||
                      teamId ||
                      showConfirmDeletePlaybookDialog?.teamId ||
                      null,
                    () => {
                      setShowConfirmDeletePlaybookDialog(null);
                      setFetchingPlaybooks(true);
                      dispatch(
                        getPlaybooks(teamId || teamId || null, (data) => {
                          // console.log(data);
                          setFetchingPlaybooks(false);
                          setPlaybooks(data?.history);
                          setSelectedPlaybook(data?.selectedPlaybook);
                        }),
                      );
                    },
                  ),
                );
              }}
              color="primary"
              // autoFocus
            >
              Yes
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showConfirmDeleteAccountTag)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          minWidth: 400,
          // '& .MuiPaper-root-MuiDialog-paper': {
          //   maxWidth: '70vw',
          // },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              minWidth: 400,
            }}
          >
            <Typography variant="body1">
              Do you want to delete this account label? This will remove the
              label from existing calls. You can still add a new account label
              to the calls later.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-end'}
          >
            <Button
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                setShowConfirmDeleteAccountTag(null);
              }}
              color="primary"
              // autoFocus
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={(e) => {
                if (currentTeam) {
                  dispatch(
                    updateTeam(
                      teamId,
                      {
                        accountTagMap: accounts
                          ?.filter(
                            (acc) =>
                              acc?.id !== showConfirmDeleteAccountTag?.id,
                          )
                          ?.reduce((acc, curr) => {
                            acc[curr?.id] = curr?.name;
                            return acc;
                          }, {}),
                      },
                      (teamData) => {
                        setCurrentTeam(teamData);
                        setShowConfirmDeleteAccountTag(null);
                        dispatch({
                          type: 'UPDATE_TEAM_DETAILS',
                          payload: {
                            id: teamId,
                            data: {
                              ...teamData,
                            },
                          },
                        });
                      },
                      () => {},
                    ),
                  );
                } else {
                  dispatch(
                    updateGlobalOrganizationConfig(
                      {
                        accountTagMap: accounts
                          ?.filter(
                            (acc) =>
                              acc?.id !== showConfirmDeleteAccountTag?.id,
                          )
                          ?.reduce((acc, curr) => {
                            acc[curr?.id] = curr?.name;
                            return acc;
                          }, {}),
                      },
                      () => {
                        dispatch(getOrganizationConfigs(() => {}));
                        setShowConfirmDeleteAccountTag(null);
                      },
                    ),
                  );
                }
              }}
              color="primary"
              // autoFocus
            >
              Yes
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showConfirmDeleteQuestionDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          minWidth: 400,
          // '& .MuiPaper-root-MuiDialog-paper': {
          //   maxWidth: '70vw',
          // },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="body1">Confirm</Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              minWidth: 400,
            }}
          >
            <Typography variant="body1">
              Are you sure you want to delete this question?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Button
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                setShowConfirmDeleteQuestionDialog(null);
              }}
              color="primary"
              // autoFocus
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={(e) => {
                dispatch(
                  updateGlobalOrganizationConfig(
                    {
                      presetQuestions: [
                        ...(orgConfigs?.orgPresetQuestions?.filter(
                          (q) => q !== showConfirmDeleteQuestionDialog,
                        ) || []),
                      ],
                      textInLogo: orgConfigs?.textInLogo,
                      generalAutoAnalysis: orgConfigs?.isGeneralAutoAnalysis,
                    },
                    () => {
                      setShowQuestionDialog(null);
                      dispatch(getOrganizationConfigs(() => {}));
                      setShowConfirmDeleteQuestionDialog(null);
                    },
                  ),
                );
              }}
              color="primary"
              // autoFocus
            >
              Confirm Delete
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showDisclosure)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          minWidth: 400,
          // '& .MuiPaper-root-MuiDialog-paper': {
          //   maxWidth: '70vw',
          // },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="body1" fontWeight={'bold'}>
            Disclosure
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              minWidth: 400,
            }}
          >
            <Typography variant="body1">
              {userInfo?.custom?.orgType === 'n_org'
                ? ''
                : parse(
                    'Google Calendar integration is provided by our partner <strong>Qualification AI</strong>. ',
                  )}{' '}
              By turning on Google Calendar integration you will be able to have
              our meeting bot join your meetings to record your calls. To
              provide you this capability we share your meeting URL, date and
              time of your upcoming external meetings to third-party tools.
            </Typography>
            <Typography variant="body1" mt={1}>
              Qualification AI&apos;s use and transfer to any other app of
              information received from Google APIs will adhere to{' '}
              <Link
                // underline="none"
                component="a"
                target="_blank"
                href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                // color="text.primary"
                variant={'body1'}
              >
                Google API Services User Data Policy
              </Link>
              , including the Limited Use requirements.
            </Typography>
            <FormControlLabel
              sx={{ mt: 1 }}
              control={
                <Checkbox
                  size="small"
                  data-trackid={`toggle_terms_accepted`}
                  checked={termsAccepted}
                  onChange={(e) => {
                    handleTrackEvent(e, `toggle_terms_accepted`);
                    setTermsAccepted(e.target.checked);
                  }}
                />
              }
              label={
                <Typography variant="body2">
                  I consent to share my meeting URL, date and time to
                  third-party tools.
                </Typography>
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Button
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                setShowDisclosure(null);
              }}
              color="primary"
              // autoFocus
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={!termsAccepted}
              onClick={(e) => {
                dispatch(
                  getGoogleOAuthUrl((url) => {
                    console.log(url);
                    window.open(url, '_self');
                  }),
                );
              }}
              color="primary"
              // autoFocus
            >
              Proceed
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showHubspotKeyDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          minWidth: 400,
          // '& .MuiPaper-root-MuiDialog-paper': {
          //   maxWidth: '70vw',
          // },
        }}
      >
        <DialogTitle id="alert-dialog-title">HubSpot Integration</DialogTitle>
        <DialogContent>
          <Box>
            <Typography color={'text.secondary'}>
              <Link
                target="_blank"
                title="Create HubSpot app"
                href="https://developers.hubspot.com/docs/api/private-apps#create-a-private-app"
              >
                Create your Private HubSpot App
              </Link>{' '}
              (takes only a few minutes), if you don&apos;t already have one.
            </Typography>
            <Typography color={'text.secondary'} mt={1}>
              Then copy over your{' '}
              <Link
                target="_blank"
                title="copy integration key"
                href="https://developers.hubspot.com/docs/api/private-apps#make-api-calls-with-your-app-s-access-token"
              >
                Integration Key
              </Link>{' '}
              in the field provided.
            </Typography>
          </Box>
          <Box>
            <TextField
              placeholder="HubSpot key"
              variant="outlined"
              name={'hubspotKey'}
              size="small"
              sx={{
                width: '100%',
              }}
              value={hubspotKey}
              onChange={(e) => setHubspotKey(e.target.value)}
              type={keyVisible ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      data-trackid={`toggle_password_visibility`}
                      aria-label="toggle password visibility"
                      onClick={(e) => {
                        handleTrackEvent(e, 'toggle_password_visibility');
                        setKeyVisible(!keyVisible);
                      }}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {keyVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(hubspotIntegrationError)}
              helperText={hubspotIntegrationError}
            />
            {hubspotIntegrationError && requiredScopes?.length > 0 ? (
              <ul style={{ marginTop: 10, marginLeft: -20, listStyle: 'none' }}>
                {requiredScopes.map((scope) => (
                  <li key={scope}>
                    <Typography variant="body2" color={'error'}>
                      {scope}
                    </Typography>
                  </li>
                ))}
              </ul>
            ) : null}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Button
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                setShowHubspotKeyDialog(false);
                setHubspotIntegrationError('');
                setRequiredScopes([]);
                setHubspotKey('');
              }}
              color="primary"
              // autoFocus
            >
              Cancel
            </Button>
            <Button
              disabled={integratingHubspot || !hubspotKey?.trim()}
              onClick={(e) => {
                setIntegratingHubspot(true);
                dispatch(
                  integrateHubspot(
                    {
                      hubSpotKey: hubspotKey?.trim(),
                      hubspot: true,
                    },
                    'org',
                    () => {
                      setHubspotKey('');
                      setHubspotIntegrationError('');
                      setRequiredScopes([]);
                      setIntegratingHubspot(false);
                      setShowHubspotKeyDialog(false);
                      dispatch(getUserDetails(() => {}));
                      dispatch(
                        getOrganizationConfigs(() => {
                          setShowHubspotPropertiesDialog(true);
                          setSelectAllProperties(true);
                        }),
                      );
                      dispatch(
                        showAlert({
                          message: 'HubSpot Integrated successfully',
                          showCross: true,
                          title: null,
                          type: 'success',
                          autoHideDuration: 2000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                    },
                    (error) => {
                      // setShowHubspotKeyDialog(false);
                      console.log('error', error);
                      if (
                        error?.message?.startsWith(
                          'api key is missing required scopes',
                        )
                      ) {
                        setHubspotIntegrationError(
                          'Please add required scopes in your hubspot app.',
                        );
                        setRequiredScopes(error?.data || []);
                      }
                      setIntegratingHubspot(false);
                      dispatch(
                        showAlert({
                          message:
                            'Failed to integrate hubspot. Please try again later!',
                          showCross: true,
                          title: null,
                          type: 'success',
                          autoHideDuration: 2000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                    },
                  ),
                );
              }}
              color="primary"
              // autoFocus
            >
              Integrate HubSpot
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showGeneratePlaybookWithAI)}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            width: '50vw',
            height: 'auto',
            borderRadius: 1,
            // padding: 2,
            position: 'relative',
          }}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            p={2}
            sx={{
              background: '#f1f1f1',
            }}
          >
            <Typography
              variant="body1"
              sx={
                {
                  // fontWeight: 'bold',
                }
              }
            >
              Generate Playbook with AI
            </Typography>
            <Button
              variant="outlined"
              size="small"
              startIcon={<Close />}
              onClick={() => setShowGeneratePlaybookWithAI(false)}
            >
              Close
            </Button>
          </Box>
          <PlaybookGeneration
            asComponent={true}
            handlePrefillFromGeneratedPlaybook={(generatedPlaybook) =>
              handlePrefillFromGeneratedPlaybook(generatedPlaybook)
            }
            setShowGeneratePlaybookWithAI={setShowGeneratePlaybookWithAI}
            setShowEditPlaybookDrawer={setShowEditPlaybookDrawer}
          />
        </Box>
      </Modal>
      <Modal
        open={Boolean(showSyncHubspotCompanies)}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown')
            setShowSyncHubspotCompanies(null);
          else return;
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            // maxHeight: '30vh',
            maxWidth: '70vw',
            minWidth: '50vw',
            maxHeight: '90vh',
            overflowY: 'scroll',
            outline: 'none',
            borderRadius: 1,
            // padding: 3,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              padding: 2,
            }}
          >
            <Typography variant="body1">
              Sync HubSpot Customer Companies (Account Labels)
            </Typography>
            <Typography variant="subtitle2" color={'text.secondary'}>
              Select which of the customer accounts you want to add here to
              track.
            </Typography>
            {fetchingHubspotCompanies ? (
              <Box
                width={1}
                height={1}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
              >
                <CircularProgress size={20} />
                <Typography variant="body1" color={'text.primary'} mt={4}>
                  Loading ...
                </Typography>
              </Box>
            ) : hubspotCompanies?.length > 0 ? (
              <TableContainer
                component={Paper}
                sx={{
                  minHeight: '60vh',
                  maxHeight: '60vh',
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    width: '5px',
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 2,
                  },
                }}
              >
                <Table
                  sx={{ minWidth: 750 }}
                  stickyHeader
                  aria-label="simple table"
                >
                  <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          borderLeft: '1px solid #f1f1f1',
                        }}
                      >
                        <Checkbox
                          size="small"
                          checked={
                            selectedHubspotCompanies.length ===
                            hubspotCompanies.length
                          }
                          onChange={(e) => {
                            setSelectedHubspotCompanies(
                              e.target.checked ? hubspotCompanies : [],
                            );
                          }}
                          name="selectHubspotProperty"
                        />
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant={'caption'}
                          fontWeight={700}
                          sx={{ textTransform: 'uppercase' }}
                        >
                          Company
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant={'caption'}
                          fontWeight={700}
                          sx={{ textTransform: 'uppercase' }}
                        >
                          Created On
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant={'caption'}
                          fontWeight={700}
                          sx={{ textTransform: 'uppercase' }}
                        >
                          Modified On
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant={'caption'}
                          fontWeight={700}
                          sx={{ textTransform: 'uppercase' }}
                        >
                          Domain
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {hubspotCompanies.map((company, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell
                            align="center"
                            sx={{
                              borderLeft: '1px solid #f1f1f1',
                            }}
                          >
                            <Checkbox
                              size="small"
                              checked={
                                selectedHubspotCompanies?.find(
                                  (c) =>
                                    c?.properties?.name ===
                                    company?.properties?.name,
                                ) !== undefined
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedHubspotCompanies([
                                    ...selectedHubspotCompanies,
                                    company,
                                  ]);
                                } else {
                                  setSelectedHubspotCompanies(
                                    selectedHubspotCompanies?.filter(
                                      (c) =>
                                        c?.properties?.name !==
                                        company?.properties?.name,
                                    ),
                                  );
                                }
                              }}
                              name="selectHubspotCompany"
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ minWidth: 150 }}
                          >
                            <Typography variant={'subtitle2'} fontWeight={700}>
                              {company?.properties?.name}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant={'caption'}>
                              {moment(company?.properties?.createdate).format(
                                'DD MMMM YYYY hh:mm:ss A',
                              )}
                            </Typography>
                          </TableCell>
                          {/* <TableCell align="center">
                            <Typography variant={'subtitle2'}>
                              {property?.dataSensitivity}
                            </Typography>
                          </TableCell> */}
                          <TableCell>
                            <Typography variant={'caption'}>
                              {moment(
                                company?.properties?.['hs_lastmodifieddate'],
                              ).format('DD MMMM YYYY hh:mm:ss A')}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant={'subtitle2'}>
                              {company?.properties?.domain || '--'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {alreadyImportedCompanies.map((company, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell
                            align="center"
                            sx={{
                              borderLeft: '1px solid #f1f1f1',
                            }}
                          >
                            <Checkbox
                              size="small"
                              disabled
                              defaultChecked={true}
                              name="selectHubspotCompany"
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ minWidth: 150 }}
                          >
                            <Typography variant={'subtitle2'} fontWeight={700}>
                              {company?.properties?.name}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant={'caption'}>
                              {moment(company?.properties?.createdate).format(
                                'DD MMMM YYYY hh:mm:ss A',
                              )}
                            </Typography>
                          </TableCell>
                          {/* <TableCell align="center">
                            <Typography variant={'subtitle2'}>
                              {property?.dataSensitivity}
                            </Typography>
                          </TableCell> */}
                          <TableCell>
                            <Typography variant={'caption'}>
                              {moment(
                                company?.properties?.['hs_lastmodifieddate'],
                              ).format('DD MMMM YYYY hh:mm:ss A')}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant={'subtitle2'}>
                              {company?.properties?.domain || '--'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Box
                width={1}
                height={1}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
                gap={2}
              >
                <Typography variant="body1" color={'text.secondary'} mt={4}>
                  No companies found.
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            p={'8px 16px'}
          >
            <Button
              size="small"
              variant="outlined"
              onClick={(e) => {
                setShowSyncHubspotCompanies(null);
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              disabled={updatingHubspotProperties}
              onClick={(e) => {
                if (currentTeam) {
                  dispatch(
                    updateTeam(
                      teamId,
                      {
                        accountTagMap: {
                          ...(accounts?.reduce((acc, curr) => {
                            acc[curr?.id] = curr?.name;
                            return acc;
                          }, {}) || {}),
                          ...(selectedHubspotCompanies?.reduce((acc, curr) => {
                            acc[curr?.id] = curr?.properties?.name;
                            return acc;
                          }, {}) || {}),
                        },
                      },
                      (teamData) => {
                        setShowSyncHubspotCompanies(null);
                        setCurrentTeam(teamData);
                        dispatch({
                          type: 'UPDATE_TEAM_DETAILS',
                          payload: {
                            id: teamId,
                            data: {
                              ...teamData,
                            },
                          },
                        });
                      },
                      () => {},
                    ),
                  );
                } else {
                  dispatch(
                    updateGlobalOrganizationConfig(
                      {
                        accountTagMap: {
                          ...(accounts?.reduce((acc, curr) => {
                            acc[curr?.id] = curr?.name;
                            return acc;
                          }, {}) || {}),
                          ...(selectedHubspotCompanies?.reduce((acc, curr) => {
                            acc[curr?.id] = curr?.properties?.name;
                            return acc;
                          }, {}) || {}),
                        },
                      },
                      () => {
                        dispatch(getOrganizationConfigs(() => {}));
                        setShowSyncHubspotCompanies(null);
                      },
                    ),
                  );
                }
              }}
              color="primary"
            >
              Import
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default AccountSettings;

AccountSettings.propTypes = {
  defaultTab: PropTypes.string,
  onBoardingStep: PropTypes.number,
  setCompletedStep: PropTypes.func,
  page: PropTypes.string,
  currentTeam: PropTypes.object,
  setCurrentTeam: PropTypes.func,
};
